import { IRefreshTokenRes } from "./../../Interfaces/auth";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IAuth, IInfo, ILogin } from "@interfaces";
import { authApi } from "@api";
import { RootState } from ".";

export const login = createAsyncThunk<IAuth, ILogin>(
    "auth/login",
    async (values: ILogin, { rejectWithValue }) => {
        try {
            const res = await authApi.login(values);
            return res.data as IAuth;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);

export const getInfo = createAsyncThunk("auth/userInfo", async () => {
    const res = await authApi.getInfo();

    return res.data as IInfo;
});

interface IState {
    auth: IAuth | null;
    isRemember: boolean;
    isLoading: boolean;
    error: string;
    tokenInfoAuth: string;
    userInfo: IInfo | null;
    permissions: string[];
}

const initialState: IState = {
    auth: null,
    isRemember: false,
    isLoading: false,
    error: "",
    tokenInfoAuth: "",
    userInfo: null,
    permissions: [],
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        logout: () => {
            return initialState;
        },
        setAuth: (state, action: PayloadAction<IAuth>) => {
            state.auth = action.payload;
        },
        setRemember: (state, action: PayloadAction<boolean>) => {
            state.isRemember = action.payload;
        },
        refreshToken: (state, action: PayloadAction<IRefreshTokenRes>) => {
            state.auth = {
                ...action.payload,
                refreshToken: state.auth?.refreshToken || null,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.fulfilled, (state, action: { payload: IAuth }) => {
            if (action.payload.accessToken) {
                state.auth = action.payload;
                state.permissions = action.payload.userPermissions || [];
            }
            if (!state.isRemember) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                state.auth!.refreshToken = null;
            }
            state.isLoading = false;
        });

        builder.addCase(login.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(login.rejected, (state) => {
            state.auth = null;
            state.isLoading = false;
        });
        builder.addCase(getInfo.fulfilled, (state, action: { payload: IInfo }) => {
            state.userInfo = action.payload;
            state.permissions = action.payload.role?.permissions || [];
        });
    },
});

export const selectAuth = (state: RootState) => state.auth;

export const { logout, setAuth, setRemember, refreshToken } = authSlice.actions;

export default authSlice.reducer;
