import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const ComponentEmpty = () => {
    const { t } = useTranslation();

    return <StyledEmptyDiv>{t("page.data_not_found")}</StyledEmptyDiv>;
};

const StyledEmptyDiv = styled.div`
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    margin-top: 80px;
`;
