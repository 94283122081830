/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Avatar, Drawer, Form, Input, List, Switch, Button, Icon, Spin } from "antd";
import { Skeleton } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { ICon, IDropDownOption, IHomeNavigation } from "@interfaces";
import { homeNavigationApi } from "@api";
import { openToast, selectTheme, setMainColor } from "@redux";
import {
    ComponentTheme,
    IconCamera,
    ComponentIcon,
    StyledError,
    IconLoadingDrawer,
} from "@components";
import { validations } from "@utils";

interface IProps {
    title: string;
    placement: "top" | "right" | "bottom" | "left" | undefined;
    width: number | string;
    drawer: boolean;
    setDrawer: Dispatch<SetStateAction<boolean>>;
    idData: string;
    setDataDad: Dispatch<SetStateAction<IHomeNavigation[] | null | undefined>>;
    dataDad: IHomeNavigation[] | null | undefined;
}

export const ModuleDrawerNavigation = (props: IProps) => {
    //page Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const mainColor = useSelector(selectTheme).mainColor;
    //props
    const { title, placement, width, setDrawer, drawer, idData, setDataDad, dataDad } = props;
    //page variable
    const initialData = {
        title: "",
        text: "",
        title_th: "",
        text_th: "",
        iconUrl: "",
        colorTheme: "#000000",
        link: "",
        dropdownStatus: false,
        dropdownOptions: [
            {
                link: "",
                name: "",
                iconUrl: "",
                error: [
                    {
                        key: "",
                        value: t("validation.required", {
                            returnObjects: true,
                            name: t("page.text"),
                        }),
                    },
                    {
                        key: "",
                        value: t("validation.required", {
                            returnObjects: true,
                            name: t("page.link"),
                        }),
                    },
                ],
            },
        ],
    };
    //page states
    const [dropDown, setDropDown] = useState<boolean>(false);
    const [data, setData] = useState<IHomeNavigation>(initialData);
    const [disableBtnSave, setDisableBtnSave] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
    const [toggle, setToggle] = useState<boolean>(false);
    const [toggleIcon, setToggleIcon] = useState<boolean>(false);
    const [errors, setErrors] = useState({
        text: "",
        title: "",
        text_th: "",
        title_th: "",
        link: "",
    });

    useEffect(() => {
        handleValidation();
    }, [errors, data, dropDown]);

    useEffect(() => {
        if (title === t("page.add_navigation_box")) {
            setData(initialData);
            setDisableBtnSave(true);
        }
        return () => {
            dispatch(setMainColor("#fff"));
        };
    }, []);
    useEffect(() => {
        if (title === t("page.add_navigation_box")) {
            setData(initialData);
            setDisableBtnSave(true);
            dispatch(setMainColor("#7a7a7a"));
        } else if (title === t("page.edit_navigation_box") && idData) {
            (async () => {
                setShowSkeleton(true);
                const res = await homeNavigationApi.getItem(idData);
                const dropDown =
                    res.data.dropdownOptions &&
                    res.data.dropdownOptions.map((data: any) => {
                        return {
                            ...data,
                            error: [
                                {
                                    key: "",
                                    value: "",
                                },
                                {
                                    key: "",
                                    value: "",
                                },
                            ],
                        };
                    });
                setData({ ...res.data, dropdownOptions: [...(dropDown || [])] });
                dispatch(setMainColor(res.data.colorTheme));
                setDropDown(res.data.dropdownStatus);
                setShowSkeleton(false);
            })();
        }
        setErrors({
            text: "",
            title: "",
            text_th: "",
            title_th: "",
            link: "",
        });
    }, [title, idData]);

    const handleValidation = () => {
        const checkErrorsDropDown = data.dropdownOptions.some((item) => {
            if (item.error) {
                return item.error[0].value || item.error[1].value;
            } else {
                return false;
            }
        });
        if (errors.link || errors.title || errors.text || (dropDown && checkErrorsDropDown)) {
            setDisableBtnSave(true);
            return true;
        } else {
            setDisableBtnSave(false);
            return false;
        }
    };

    const ErrorMessage = (errors: Array<string>, errorDefault: string) => {
        if (errors?.length !== 0) {
            dispatch(
                openToast({
                    message: errors[0],
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        } else {
            dispatch(
                openToast({
                    message: errorDefault,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        }
    };

    const addData = async () => {
        setIsLoading(true);
        const fetchData = dropDown ? data : { ...data, dropdownOptions: [] };
        try {
            const res = await homeNavigationApi.addData({
                ...fetchData,
                colorTheme: mainColor,
                dropdownStatus: dropDown,
            });
            if (dataDad && res.data) {
                setDataDad([...dataDad, res.data]);
            }
            dispatch(
                openToast({
                    message: t("page.add_successfully"),
                    type: "success",
                    autoHideDuration: 2000,
                })
            );

            setData(initialData);
            closeDrawer();
        } catch (error: any) {
            const errors = error.response?.data.errors;
            ErrorMessage(errors, t("page.add_failed"));
        } finally {
            setIsLoading(false);
        }
    };

    const editData = async () => {
        setIsLoading(true);

        try {
            const res = await homeNavigationApi.editData(
                { ...data, colorTheme: mainColor, dropdownStatus: dropDown },
                idData
            );
            const index = dataDad?.findIndex((item) => item._id === idData) || 0;
            if (index >= 0 && dataDad) {
                dataDad[index] = res.data;
                setDataDad([...dataDad]);
            }

            dispatch(
                openToast({
                    message: t("page.edit_successfully"),
                    type: "success",
                    autoHideDuration: 2000,
                })
            );
            closeDrawer();
        } catch (error: any) {
            const errors = error.response.data.errors;
            ErrorMessage(errors, t("page.edit_failed"));
        } finally {
            setIsLoading(false);
        }
    };

    const handleSave = () => {
        let checkValidation = false;
        const errorsValidate = {
            title: "",
            text: "",
            title_th: "",
            text_th: "",
            link: "",
        };
        if (!data.title) {
            errorsValidate.title = t("validation.required", {
                returnObjects: true,
                name: t("page.title"),
            });

            checkValidation = true;
        }
        if (!data.text) {
            errorsValidate.text = t("validation.required", {
                returnObjects: true,
                name: t("page.text"),
            });
            checkValidation = true;
        }
        if (!data.title_th) {
            errorsValidate.title_th = t("validation.required", {
                returnObjects: true,
                name: t("page.title_th"),
            });

            checkValidation = true;
        }
        if (!data.text_th) {
            errorsValidate.text_th = t("validation.required", {
                returnObjects: true,
                name: t("page.text_th"),
            });
            checkValidation = true;
        }
        if (!data.link) {
            errorsValidate.link = t("validation.required", {
                returnObjects: true,
                name: t("page.link"),
            });
            checkValidation = true;
        }
        let checkError = false;
        data.dropdownOptions.forEach((item, index) => {
            //
            if (dropDown && !item.name) {
                data.dropdownOptions[index].error[0].key = "page.drop_name";
                data.dropdownOptions[index].error[0].key = t("validation.required", {
                    returnObjects: true,
                    name: t("page.drop_name"),
                });
                checkError = true;
            }
            if (dropDown && !item.link) {
                data.dropdownOptions[index].error[1].key = "page.drop_link";
                data.dropdownOptions[index].error[1].key = t("validation.required", {
                    returnObjects: true,
                    name: t("page.drop_link"),
                });
                checkError = true;
            }
        });
        if (checkValidation) {
            setErrors({ ...errorsValidate });
            return;
        }
        if (handleValidation()) return;
        if (disableBtnSave && checkError) return;
        switch (title) {
            case t("page.add_navigation_box"):
                addData();
                break;
            case t("page.edit_navigation_box"):
                editData();
                break;
            default:
                break;
        }
    };

    const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (validations("max-length", value)) {
            setErrors({
                ...errors,
                text: t("validation.max_length", {
                    returnObjects: true,
                    name: t("page.text"),
                }),
            });
            setDisableBtnSave(true);
            return;
        }
        if (validations("min-length", value)) {
            setErrors({
                ...errors,
                text: t("validation.required", {
                    returnObjects: true,
                    name: t("page.text"),
                }),
            });
            setData({ ...data, text: e.target.value });
            setDisableBtnSave(true);
            return;
        }
        setErrors({
            ...errors,
            text: "",
        });
        setDisableBtnSave(false);
        setData({ ...data, text: e.target.value });
    };
    const handleChangeTextTh = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (validations("max-length", value)) {
            setErrors({
                ...errors,
                text_th: t("validation.max_length", {
                    returnObjects: true,
                    name: t("page.text_th"),
                }),
            });
            setDisableBtnSave(true);
            return;
        }
        if (validations("min-length", value)) {
            setErrors({
                ...errors,
                text_th: t("validation.required", {
                    returnObjects: true,
                    name: t("page.text_th"),
                }),
            });
            setData({ ...data, text_th: e.target.value });
            setDisableBtnSave(true);
            return;
        }
        setErrors({
            ...errors,
            text_th: "",
        });
        setDisableBtnSave(false);
        setData({ ...data, text_th: e.target.value });
    };

    const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (validations("max-length", value)) {
            setErrors({
                ...errors,
                title: t("validation.max_length", {
                    returnObjects: true,
                    name: t("page.title"),
                }),
            });
            setDisableBtnSave(true);
            return;
        }
        if (validations("min-length", value)) {
            setErrors({
                ...errors,
                title: t("validation.required", {
                    returnObjects: true,
                    name: t("page.title"),
                }),
            });
            setData({ ...data, title: value });
            setDisableBtnSave(true);
            return;
        }
        setErrors({
            ...errors,
            title: "",
        });
        setDisableBtnSave(false);
        setData({ ...data, title: value });
    };
    const handleChangeTitleTh = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (validations("max-length", value)) {
            setErrors({
                ...errors,
                title_th: t("validation.max_length", {
                    returnObjects: true,
                    name: t("page.title_th"),
                }),
            });
            setDisableBtnSave(true);
            return;
        }
        if (validations("min-length", value)) {
            setErrors({
                ...errors,
                title_th: t("validation.required", {
                    returnObjects: true,
                    name: t("page.title_th"),
                }),
            });
            setData({ ...data, title_th: value });
            setDisableBtnSave(true);
            return;
        }
        setErrors({
            ...errors,
            title_th: "",
        });
        setDisableBtnSave(false);
        setData({ ...data, title_th: value });
    };

    const handleChangeLink = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (validations("max-length", value)) {
            setErrors({
                ...errors,
                link: t("validation.max_length", {
                    returnObjects: true,
                    name: t("page.link"),
                }),
            });
            setDisableBtnSave(true);
            return;
        }
        if (validations("min-length", value)) {
            setErrors({
                ...errors,
                link: t("validation.required", {
                    returnObjects: true,
                    name: t("page.link"),
                }),
            });
            setData({ ...data, link: value });
            setDisableBtnSave(true);
            return;
        }
        const regexLink =
            // eslint-disable-next-line no-useless-escape
            /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        if (!regexLink.test(value)) {
            setErrors({
                ...errors,
                link: t("validation.link_must_url"),
            });
            setData({ ...data, link: value });
            setDisableBtnSave(true);
            return;
        }
        setErrors({
            ...errors,
            link: "",
        });
        setDisableBtnSave(false);
        setData({ ...data, link: value });
    };
    const handleChangeDropName = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;
        const dropDown = {
            ...data.dropdownOptions[index],
            name: value,
        };
        if (validations("max-length", value)) {
            if (dropDown.error) {
                dropDown.error[0].key = "page.drop_name";
                dropDown.error[0].value = t("validation.max_length", {
                    returnObjects: true,
                    name: t("page.drop_name"),
                });
                setDisableBtnSave(true);
            }
        } else if (validations("min-length", value)) {
            if (dropDown.error) {
                dropDown.error[0].key = "page.drop_name";
                dropDown.error[0].value = t("validation.required", {
                    returnObjects: true,
                    name: t("page.drop_name"),
                });
                setDisableBtnSave(true);
            }
        } else {
            if (dropDown.error) {
                dropDown.error[0].key = "";
                dropDown.error[0].value = "";
                setDisableBtnSave(false);
            }
        }
        data.dropdownOptions[index] = dropDown;
        setData({
            ...data,
            dropdownOptions: [...data.dropdownOptions],
        });
    };

    const handleChangeDropLink = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;
        const dropDown = {
            ...data.dropdownOptions[index],
            link: value,
        };
        if (validations("max-length", value)) {
            if (dropDown.error) {
                dropDown.error[1].key = "page.drop_link";
                dropDown.error[1].value = t("validation.max_length", {
                    returnObjects: true,
                    name: t("page.drop_link"),
                });
                setDisableBtnSave(true);
            }
        } else if (validations("min-length", value)) {
            if (dropDown.error) {
                dropDown.error[1].key = "page.drop_link";
                dropDown.error[1].value = t("validation.required", {
                    returnObjects: true,
                    name: t("page.drop_link"),
                });
                setDisableBtnSave(true);
            }
        } else {
            if (dropDown.error) {
                dropDown.error[1].key = "";
                dropDown.error[1].value = "";
            }
            setDisableBtnSave(false);
        }
        data.dropdownOptions[index] = { ...dropDown };
        setData({
            ...data,
            dropdownOptions: [...data.dropdownOptions],
        });
    };

    const handleAddDrop = () => {
        if (!dropDown) return;
        const dropEntry: IDropDownOption = {
            link: "",
            name: "",
            iconUrl: "",
            error: [
                {
                    key: "",
                    value: t("validation.required", {
                        returnObjects: true,
                        name: t("page.text"),
                    }),
                },
                {
                    key: "",
                    value: t("validation.required", {
                        returnObjects: true,
                        name: t("page.link"),
                    }),
                },
            ],
        };
        setData(() => {
            return {
                ...data,
                dropdownOptions: [...data.dropdownOptions, dropEntry],
            };
        });
    };

    const handleRemoveDropIndex = (index: number) => {
        data.dropdownOptions.splice(index, 1);
        setData({
            ...data,
            dropdownOptions: data.dropdownOptions,
        });
        if (data.dropdownOptions.length === 0) {
            setDropDown(false);
        }
    };

    const handleChangeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        //@ts-ignore
        const values = e.target.files[0];
        if (!values.type.includes("image/svg")) {
            dispatch(
                openToast({
                    message: t("page.image_svg"),
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
            return;
        }
        const formData = new FormData();
        formData.append("file", values);
        try {
            const res = await homeNavigationApi.uploadImage(formData);

            setData({
                ...data,
                iconUrl: res.data.publicUrl,
            });
        } catch (error) {
            // console.log(error);
        }
    };

    const handleChangeIconUrlDropIndex = (value: ICon, index: number) => {
        const dropDown = {
            ...data.dropdownOptions[index],
            iconUrl: value.name || "",
        };
        data.dropdownOptions[index] = dropDown;

        setData({
            ...data,
            dropdownOptions: [...data.dropdownOptions],
        });
    };

    const closeDrawer = () => {
        setDrawer(!drawer);
        setTimeout(() => {
            setToggleIcon(false);
            setToggle(false);
        }, 300);
    };

    const loadingComponent = (
        <div className="loading-component">
            <Skeleton variant="text" height={20} width={"30%"} />
            <Skeleton variant="text" height={60} />
            <Skeleton variant="text" height={20} width={"30%"} />
            <Skeleton variant="text" height={60} />
            <Skeleton variant="text" height={20} width={"30%"} />
            <Skeleton variant="text" height={60} />
            <Skeleton variant="text" height={20} width={"20%"} />
            <Skeleton variant="rectangular" width={180} height={180} style={{ borderRadius: 6 }} />
            <Skeleton variant="text" height={60} />
            <Skeleton variant="text" height={60} />
            <Skeleton variant="text" height={60} />
        </div>
    );

    return (
        <StyledDrawer>
            <Drawer
                title={title}
                placement={placement}
                closable={true}
                width={width}
                onClose={closeDrawer}
                visible={drawer}
            >
                <Spin
                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    spinning={isLoading}
                    indicator={<IconLoadingDrawer />}
                >
                    {showSkeleton ? (
                        loadingComponent
                    ) : (
                        <Form
                            labelCol={{
                                span: 3,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            layout="horizontal"
                        >
                            <Form.Item colon={false} label={t("page.title")}>
                                <Input
                                    style={{ width: "100%" }}
                                    value={data?.title}
                                    onChange={handleChangeTitle}
                                    className={errors.title ? "invalid-input" : ""}
                                />
                                {errors.title && <StyledError>{errors.title}</StyledError>}
                            </Form.Item>
                            <Form.Item colon={false} label={t("page.title_th")}>
                                <Input
                                    style={{ width: "100%" }}
                                    value={data?.title_th}
                                    onChange={handleChangeTitleTh}
                                    className={errors.title ? "invalid-input" : ""}
                                />
                                {errors.title && <StyledError>{errors.title}</StyledError>}
                            </Form.Item>
                            <Form.Item colon={false} label={t("page.text")}>
                                <Input
                                    style={{ width: "100%" }}
                                    onChange={handleChangeText}
                                    value={data.text}
                                    className={errors.text ? "invalid-input" : ""}
                                />
                                {errors.text && <StyledError>{errors.text}</StyledError>}
                            </Form.Item>
                            <Form.Item colon={false} label={t("page.text_th")}>
                                <Input
                                    style={{ width: "100%" }}
                                    onChange={handleChangeTextTh}
                                    value={data.text_th}
                                    className={errors.text ? "invalid-input" : ""}
                                />
                                {errors.text && <StyledError>{errors.text}</StyledError>}
                            </Form.Item>
                            <Form.Item colon={false} label={t("page.link")}>
                                <Input
                                    style={{ width: "100%" }}
                                    onChange={handleChangeLink}
                                    value={data.link}
                                    className={errors.link ? "invalid-input" : ""}
                                />
                                {errors.link && <StyledError>{errors.link}</StyledError>}
                            </Form.Item>
                            <div className="avatar">
                                <div className="box_title">
                                    {t("page.box_icon")}
                                    <span>{t("page.svg_50_50")}</span>
                                </div>
                                <Avatar
                                    size={110}
                                    src={data.iconUrl}
                                    shape="square"
                                    style={{ backgroundColor: mainColor }}
                                />
                                <input
                                    id="file_update_img"
                                    type="file"
                                    hidden
                                    onChange={handleChangeImage}
                                />
                                <label className="camera" htmlFor="file_update_img">
                                    <IconCamera />
                                </label>
                            </div>
                            <ComponentTheme
                                toggle={toggle}
                                handleToggle={() => {
                                    setToggle(!toggle);
                                }}
                            />
                            <List.Item
                                actions={[
                                    <Switch
                                        size="small"
                                        checked={dropDown}
                                        onChange={(checked) => {
                                            setDropDown(checked);
                                            if (
                                                !data.dropdownOptions ||
                                                data.dropdownOptions.length === 0
                                            ) {
                                                setData({
                                                    ...data,
                                                    dropdownOptions: initialData.dropdownOptions,
                                                });
                                            }
                                        }}
                                    />,
                                ]}
                            >
                                <span
                                    style={{
                                        flex: "1 0",
                                    }}
                                >
                                    {t("page.drop_down_option")}
                                </span>
                            </List.Item>
                            {dropDown &&
                                data.dropdownOptions.map((data, index) => {
                                    return (
                                        <DropDown
                                            toggleIcon={toggleIcon}
                                            handleToggle={() => setToggleIcon(!toggleIcon)}
                                            data={data}
                                            index={index}
                                            handleChangeDropLink={handleChangeDropLink}
                                            handleChangeDropName={handleChangeDropName}
                                            handleRemoveDropIndex={handleRemoveDropIndex}
                                            handleChangeIconUrlDropIndex={
                                                handleChangeIconUrlDropIndex
                                            }
                                        />
                                    );
                                })}

                            {dropDown && (
                                <div className="add_drop_down">
                                    <img src="/images/add.png" onClick={handleAddDrop} />
                                </div>
                            )}
                            <div className="group_button_navigation">
                                <Button
                                    type="primary"
                                    size="large"
                                    className="btn--save"
                                    onClick={handleSave}
                                    // disabled={disableBtnSave}
                                >
                                    {t("page.save")}
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={closeDrawer}
                                    size="large"
                                    className="btn--cancel"
                                >
                                    {t("page.cancel")}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Spin>
            </Drawer>
        </StyledDrawer>
    );
};

interface IPropsDropDown {
    index: number;
    handleChangeDropName: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
    handleChangeDropLink: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
    handleChangeIconUrlDropIndex: (value: ICon, index: number) => void;
    handleRemoveDropIndex: (index: number) => void;
    data: IDropDownOption;
    toggleIcon: boolean;
    handleToggle: () => void;
}

const DropDown = (props: IPropsDropDown) => {
    //page Hooks
    const { t } = useTranslation();
    //page props
    const {
        index,
        data,
        toggleIcon,
        handleChangeDropLink,
        handleChangeDropName,
        handleChangeIconUrlDropIndex,
        handleRemoveDropIndex,
        handleToggle,
    } = props;
    //page state
    return (
        <>
            <div className="box__dropDown" key={index}>
                <Input
                    style={{ width: "100%", marginBottom: "14px" }}
                    placeholder={t("page.drop_down_name")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChangeDropName(e, index);
                    }}
                    value={data.name}
                    className={
                        data.error && data.error[0].key && data.error[0].value
                            ? "invalid-input"
                            : ""
                    }
                />
                {data.error && data.error[0].key && data.error[0].value && (
                    <StyledError className="error_drop_down">{data.error[0].value}</StyledError>
                )}
                <Input
                    style={{ width: "100%", marginBottom: "16px" }}
                    placeholder={t("page.drop_down_link")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChangeDropLink(e, index);
                    }}
                    value={data.link}
                    className={
                        data.error && data.error[1].key && data.error[1].value
                            ? "invalid-input"
                            : ""
                    }
                />
                {data.error && data.error[1].key && data.error[1].value && (
                    <StyledError className="error_drop_down">{data.error[1].value}</StyledError>
                )}
                <div className="group__icons">
                    <div className="share" onClick={handleToggle}>
                        <Icon
                            type={data.iconUrl}
                            theme="outlined"
                            style={{ marginRight: "8px", color: "#08c", fontSize: "22px" }}
                        />
                        <span>{data.iconUrl ? data.iconUrl : t("page.set_icon")}</span>
                    </div>
                    <div className="edit_menu">
                        <img
                            className="delete"
                            src="/images/delete.png"
                            onClick={() => {
                                handleRemoveDropIndex(index);
                            }}
                        />
                    </div>
                </div>
                <ComponentIcon
                    handleToggle={handleToggle}
                    toggle={toggleIcon}
                    index={index}
                    onClick={handleChangeIconUrlDropIndex}
                />
            </div>
        </>
    );
};

const StyledDrawer = styled.div`
    .avatar {
        .box_title {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
        }
    }
`;
