import axiosClient from "./axiosClient";
import { IMerchantList, IMerchantNote } from "@interfaces";
import { PATH_MERCHANTS } from "@configs";
import axiosPlainClient from "./axiosPlainClient";

export const merchantAPI = {
    getList: (params: IMerchantList) => {
        const url = PATH_MERCHANTS;
        return axiosClient.get(url, { params });
    },
    delete: (id: string) => {
        const url = PATH_MERCHANTS + "/" + id;
        return axiosClient.delete(url);
    },
    detail: (id: string) => {
        const url = PATH_MERCHANTS + "/" + id;
        return axiosClient.get(url);
    },
    updatePlan: (bodyData: { planId: string }, id: string) => {
        const url = `${PATH_MERCHANTS}/${id}/update-plan`;
        return axiosClient.put(url, bodyData);
    },
    getAdminOfMerchant: (params: IMerchantList) => {
        const url = PATH_MERCHANTS + "/getAdminOfMerchant";
        return axiosClient.get(url, { params });
    },
    superAdminPostNote: (params: IMerchantNote) => {
        const url = PATH_MERCHANTS + "/superAdminPostNote";
        return axiosClient.post(url, params);
    },
    superAdminTierRule: (param: any) => {
        const url = PATH_MERCHANTS + "/superAdminTierRule";
        return axiosClient.post(url, param);
    },
    superAdminFeature: (param: any) => {
        const url = PATH_MERCHANTS + "/superAdminFeature";
        return axiosClient.post(url, param);
    },
    geConfigUploadReciept: (id: any) => {
        const url = `${PATH_MERCHANTS}/${id}/upload-receipt`;
        return axiosClient.get(url);
    },
    saveConfigUploadReciept: (id: any, params: any) => {
        const url = `${PATH_MERCHANTS}/upload-receipt`;
        return axiosClient.post(url, { merchantId: id, ...params });
    },
    getBookingFieldList: (params: any) => {
        const url = "organization/getBookingTopicNoteList";
        return axiosPlainClient.get(url, { params });
    },
    updateBookingFieldList: (payload: any) => {
        const url = "organization/updateBookingTopicNoteList";
        return axiosPlainClient.patch(url, payload);
    },
    getCrossMerchantByID: (id: any) => {
        const url = `/merchants/${id}/cross-merchant`;
        return axiosClient.get(url);
    },
    
    getChanel: (id: any) => {
        const url = "/channel/merchant" + "/" + id;;
        return axiosClient.get(url);
    },

    saveCrossMerchant: (id: any, params: any) => {
        const url = `${PATH_MERCHANTS}/cross-merchant`;
        return axiosClient.post(url, { merchantId: id, ...params });
    },

    getChanelAnotherMerchant: (id: any) => {
        const url = "/channel/merchant/" + id;
        return axiosClient.get(url);
    },
};
