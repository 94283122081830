import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import styled from "styled-components";
import { useEffect, useState } from "react";

import { authApi } from "@api";
import { openToast, useAppDispatch } from "@redux";

export const PageVerifyEmail = () => {
    //page Hooks
    const { t } = useTranslation();
    const { email } = useParams<{ email: string }>();
    const dispatch = useAppDispatch();
    // page state
    const [timer, setTimer] = useState(60);
    const [flag, setFlag] = useState(false);

    const reSendEmailRecover = async () => {
        coundownTimer();
        try {
            const res = await authApi.reSendEmail(email);
            if (res.data.success === true) {
                dispatch(
                    openToast({
                        message: t("page.resend") + " " + t("page.success"),
                        type: "success",
                        autoHideDuration: 2000,
                    })
                );
            }
        } catch (error: any) {
            const data = error.response.data;
            if (data.message) {
                dispatch(
                    openToast({
                        message: data.message,
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            } else {
                dispatch(
                    openToast({
                        message: t("User not found!"),
                        type: "warning",
                        autoHideDuration: 2000,
                    })
                );
            }
        }
    };
    const coundownTimer = async () => {
        await setFlag(true);
        await setTimeout(() => setTimer(timer - 1), 1000);
    };
    useEffect(() => {
        if (flag === true) {
            if (timer > 0) {
                setTimeout(() => setTimer(timer - 1), 1000);
            } else {
                setFlag(false);
                setTimer(60);
            }
        }
    }, [timer]);
    return (
        <StyledContainer>
            <div className="title">{t("page.text_verify_email")}</div>
            <div className="desc">
                {t("page.desc_verify_email")}
                {!flag ? (
                    <span onClick={reSendEmailRecover}> {t("page.resend_email")}</span>
                ) : (
                    <span>{timer}</span>
                )}
            </div>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    background: #f7f7f8;
    text-align: center;
    max-width: 80vw;
    .title {
        font-style: normal;
        font-weight: normal;
        font-size: 55px;
        line-height: 73px;
        color: #000000;
        margin-bottom: 44px;
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            font-size: 50px;
            line-height: 60px;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobilePhone}) {
            font-size: 45px;
            line-height: 50px;
        }
    }
    .desc {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 27px;
        span {
            color: #f22f46;
            cursor: pointer;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            font-size: 18px;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobilePhone}) {
            font-size: 16px;
        }
    }
`;
