import { Row, Col, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { StyledHomeNavigation, StyledPageDetail, StyledTableButtonTop } from "@components";
import { COLUM_HOME_NAVIGATION } from "@configs";
import { ModuleCart, ModuleDrawerNavigation, ModuleCartPoster, ModuleDrawerPoster } from "@modules";
import { openToast, selectApp, setLoading, setMobile, useAppSelector } from "@redux";
import { IHomeNavigation, IHomePoster } from "@interfaces";
import { homeNavigationApi, homePosterApi } from "@api";

export const PageHomePageNavigation = () => {
    //page Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    //redux state
    const app = useAppSelector(selectApp);
    const [titleDrawer, setTitleDrawer] = useState<string>("");
    const [drawer, setDrawer] = useState(false);
    const [idData, setIdData] = useState<string>("");
    const [data, setData] = useState<IHomeNavigation[] | null>();

    const [titleDrawerPoster, setTitleDrawerPoster] = useState<string>("");
    const [drawerPoster, setDrawerPoster] = useState(false);
    const [idDataPoster, setIdDataPoster] = useState<string>("");
    const [dataPoster, setDataPoster] = useState<IHomePoster[] | null>();

    //page variable
    const colCount = COLUM_HOME_NAVIGATION;
    const mql = window.matchMedia(`(min-width: 992px)`);

    useEffect(() => {
        mql.addListener(mediaQueryChanged);
        return () => mql.removeListener(mediaQueryChanged);
    }, []);

    useEffect(() => {
        (async () => {
            dispatch(setLoading(true));
            try {
                const res = await homeNavigationApi.getList();
                setData(res.data);
                const resPoster = await homePosterApi.getList();
                setDataPoster(resPoster.data);
            } catch {
                setData([]);
                setDataPoster([]);
            } finally {
                dispatch(setLoading(false));
            }
        })();
    }, []);

    const mediaQueryChanged = () => {
        dispatch(setMobile(!mql.matches));
    };

    const addBoxNavigation = () => {
        setTitleDrawer(t("page.add_navigation_box"));
        setDrawer(true);
    };
    const addPoster = () => {
        setTitleDrawerPoster(t("page.add_poster"));
        setDrawerPoster(true);
    };

    const handleCartItem = (title: string, idItem?: string) => {
        setDrawer(!drawer);
        setTitleDrawer(title);
        if (title === t("page.edit_navigation_box") && idItem) {
            setIdData(idItem);
        }
    };
    const handleCartItemPoster = (title: string, idItemPoster?: string) => {
        setDrawerPoster(!drawer);
        setTitleDrawerPoster(title);
        if (title === t("page.edit_poster") && idItemPoster) {
            setIdDataPoster(idItemPoster);
        }
    };


    const handleDele = async (idData?: string) => {
        try {
            if (idData) {
                const res = await homeNavigationApi.deleteItem(idData);
                if (res.data.success === true) {
                    const index = data?.findIndex((item) => item._id === idData) || 0;
                    if (index >= 0) {
                        data?.splice(index, 1);
                        if (data) {
                            setData([...data]);
                        }
                    }
                    dispatch(
                        openToast({
                            message: t("page.action.delete") + " " + t("page.success"),
                            type: "success",
                            autoHideDuration: 2000,
                        })
                    );
                }
            } else {
                dispatch(
                    openToast({
                        message: t("page.action.delete") + " " + t("page.failed"),
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            }
        } catch {
            dispatch(
                openToast({
                    message: t("page.action.delete") + " " + t("page.failed"),
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        }
    };
    const handleDelePoster = async (idDataPoster?: string) => {
        try {
            if (idDataPoster) {
                const res = await homePosterApi.deleteItem(idDataPoster);
                if (res.data.success === true) {
                    const index = data?.findIndex((item) => item._id === idDataPoster) || 0;
                    if (index >= 0) {
                        dataPoster?.splice(index, 1);
                        if (dataPoster) {
                            setDataPoster([...dataPoster]);
                        }
                    }
                    dispatch(
                        openToast({
                            message: t("page.action.delete") + " " + t("page.success"),
                            type: "success",
                            autoHideDuration: 2000,
                        })
                    );
                }
            } else {
                dispatch(
                    openToast({
                        message: t("page.action.delete") + " " + t("page.failed"),
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            }
        } catch {
            dispatch(
                openToast({
                    message: t("page.action.delete") + " " + t("page.failed"),
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        }
    };

    const showModal = (idItem?: string) => {
        Modal.confirm({
            title: t("message.delete_title"),
            icon: <ExclamationCircleOutlined />,
            content: t("message.delete_confirm"),
            okText: t("page.action.delete"),
            cancelText: t("page.action.cancel"),
            centered: true,
            onOk: () => {
                handleDele(idItem);
            },
        });
    };
    const showModalPoster = (idItemPoster?: string) => {
        Modal.confirm({
            title: t("message.delete_title"),
            icon: <ExclamationCircleOutlined />,
            content: t("message.delete_confirm"),
            okText: t("page.action.delete"),
            cancelText: t("page.action.cancel"),
            centered: true,
            onOk: () => {
                handleDelePoster(idItemPoster);
            },
        });
    };

    return (
        <StyledPageDetail>
            <StyledHomeNavigation>
                <ModuleDrawerNavigation
                    title={titleDrawer}
                    placement="right"
                    width={!app.mobile ? 538 : "100vw"}
                    setDrawer={setDrawer}
                    drawer={drawer}
                    idData={idData}
                    setDataDad={setData}
                    dataDad={data}
                />
                <div className="form-wrap">
                    <div className="header">
                        <div className="header__title">{t("page.edit_merchant_home")}</div>
                        <StyledTableButtonTop
                            type="danger"
                            size="default"
                            onClick={addBoxNavigation}
                            className="add-btn add-box "
                        >
                            {t("page.add_box")}
                        </StyledTableButtonTop>
                    </div>

                    <Row justify="space-between">
                        {data &&
                            data.map((dataCart, index) => {
                                return (
                                    <Col
                                        key={index}
                                        span={24 / colCount}
                                        xs={24}
                                        sm={8}
                                        className="ant-col-5"
                                    >
                                        <ModuleCart
                                            onclick={handleCartItem}
                                            data={dataCart}
                                            onTogglePopup={showModal}
                                        />
                                    </Col>
                                );
                            })}
                    </Row>
                </div>
            </StyledHomeNavigation>

            <StyledHomeNavigation>
                <ModuleDrawerPoster
                    title={titleDrawerPoster}
                    placement="right"
                    width={!app.mobile ? 538 : "100vw"}
                    setDrawer={setDrawerPoster}
                    drawer={drawerPoster}
                    idData={idDataPoster}
                    setDataDad={setDataPoster}
                    dataDad={dataPoster}
                />
                <div className="form-wrap">
                    <div className="header">
                        <div className="header__title"></div>
                        <StyledTableButtonTop
                            type="danger"
                            size="default"
                            onClick={addPoster}
                            className="add-btn add-box "
                        >
                            {t("page.add_poster")}
                        </StyledTableButtonTop>
                    </div>

                    <Row justify="space-between">
                        {dataPoster &&
                            dataPoster.map((dataCartPoster, index) => {
                                return (
                                    <Col
                                        key={index}
                                        span={24 / colCount}
                                        xs={24}
                                        sm={8}
                                        className="ant-col-5"
                                    >
                                        <ModuleCartPoster
                                            onclick={handleCartItemPoster}
                                            dataPoster={dataCartPoster}
                                            onTogglePopup={showModalPoster}
                                        />
                                    </Col>
                                );
                            })}
                    </Row>
                </div>
            </StyledHomeNavigation>
        </StyledPageDetail>
    );
};
