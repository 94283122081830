import { useTranslation } from "react-i18next";
import { Switch, Input } from "antd";
import styled from "styled-components";
import { useHistory, useParams } from "react-router";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import i18n from "i18next";
import { useState } from "react";

import { StyledCard, ShareSwitchField } from "@components";
import { IPlanDetail } from "@interfaces";
import { openToast } from "@redux";

import {
    PLAN_TYPE_CREATE,
    PLAN_TYPE_DETAIL,
    STATUS_ACTIVE,
    PATH_PLANS,
    MAX_LENGTH_INPUT,
    MIN_NUMBER,
    MAX_NUMBER,
    enumValidation,
} from "@configs";
import { ModuleButtonAction } from "@modules";
import { planAPI } from "@api";
import { number } from "prop-types";
interface IProps {
    typePage: number;
    data: IPlanDetail;
}

export const ComponentCardPlan = (props: IProps) => {
    //page hook
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    // page state
    const [isFocus, setIsFocus] = useState(false);
    const [isDisableBtn, setIsDisableBtn] = useState(false);
    //page variable
    const transText = "validation";
    const { data, typePage } = props;
    // validation
    const planSchema = () => {
        if (!data.planMasterId) {
            const schema = Yup.object().shape({
                name: Yup.string()
                    .trim()
                    .required(
                        i18n.t(`${transText}.required`, {
                            returnObjects: true,
                            name: t("page.plan.plan_name"),
                        })
                    )
                    .max(
                        enumValidation.MAX_LENGTH,
                        i18n.t(`${transText}.max_number`, {
                            returnObjects: true,
                            name: t("page.plan.plan_name"),
                            number: enumValidation.MAX_LENGTH,
                        })
                    ),
                price: Yup.number()
                    .required(
                        i18n.t(`${transText}.required`, {
                            returnObjects: true,
                            name: t("object.plan_pricing"),
                        })
                    )
                    .min(
                        enumValidation.MIN_NUMBER,
                        i18n.t(`${transText}.min_number`, {
                            returnObjects: true,
                            name: t("object.plan_pricing"),
                            number: enumValidation.MIN_NUMBER,
                        })
                    )
                    .max(
                        enumValidation.MAX_NUMBER,
                        i18n.t(`${transText}.max_number`, {
                            returnObjects: true,
                            name: t("object.plan_pricing"),
                            number: enumValidation.MAX_NUMBER,
                        })
                    ),
                trial: Yup.bool().nullable(),
                reward: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.must_number`, {
                                        returnObjects: true,
                                        name: t("object.reward_limit"),
                                    })
                                )
                                .min(
                                    enumValidation.MIN_NUMBER,
                                    i18n.t(`${transText}.min_number`, {
                                        returnObjects: true,
                                        name: t("object.reward_limit"),
                                        number: enumValidation.MIN_NUMBER,
                                    })
                                )
                                .max(
                                    enumValidation.MAX_NUMBER,
                                    i18n.t(`${transText}.max_number`, {
                                        returnObjects: true,
                                        name: t("object.reward_limit"),
                                        number: enumValidation.MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("object.reward_limit"),
                                    })
                                ),
                        }),
                }),
                coupon: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.must_number`, {
                                        returnObjects: true,
                                        name: t("object.coupons_limit"),
                                    })
                                )
                                .min(
                                    enumValidation.MIN_NUMBER,
                                    i18n.t(`${transText}.min_number`, {
                                        returnObjects: true,
                                        name: t("object.coupons_limit"),
                                        number: enumValidation.MIN_NUMBER,
                                    })
                                )
                                .max(
                                    enumValidation.MAX_NUMBER,
                                    i18n.t(`${transText}.max_number`, {
                                        returnObjects: true,
                                        name: t("object.coupons_limit"),
                                        number: enumValidation.MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("object.coupons_limit"),
                                    })
                                ),
                        }),
                }),
                benefit: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.must_number`, {
                                        returnObjects: true,
                                        name: t("object.benefits_limit"),
                                    })
                                )
                                .min(
                                    enumValidation.MIN_NUMBER,
                                    i18n.t(`${transText}.min_number`, {
                                        returnObjects: true,
                                        name: t("object.benefits_limit"),
                                        number: enumValidation.MIN_NUMBER,
                                    })
                                )
                                .max(
                                    enumValidation.MAX_NUMBER,
                                    i18n.t(`${transText}.max_number`, {
                                        returnObjects: true,
                                        name: t("object.benefits_limit"),
                                        number: enumValidation.MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("object.benefits_limit"),
                                    })
                                ),
                        }),
                }),
                contact: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.must_number`, {
                                        returnObjects: true,
                                        name: t("object.contacts_limit"),
                                    })
                                )
                                .min(
                                    enumValidation.MIN_NUMBER,
                                    i18n.t(`${transText}.min_number`, {
                                        returnObjects: true,
                                        name: t("object.contacts_limit"),
                                        number: enumValidation.MIN_NUMBER,
                                    })
                                )
                                .max(
                                    enumValidation.MAX_NUMBER,
                                    i18n.t(`${transText}.max_number`, {
                                        returnObjects: true,
                                        name: t("object.contacts_limit"),
                                        number: enumValidation.MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("object.contacts_limit"),
                                    })
                                ),
                        }),
                }),
                membership: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.must_number`, {
                                        returnObjects: true,
                                        name: t("object.membership_limit"),
                                    })
                                )
                                .min(
                                    enumValidation.MIN_NUMBER,
                                    i18n.t(`${transText}.min_number`, {
                                        returnObjects: true,
                                        name: t("object.membership_limit"),
                                        number: enumValidation.MIN_NUMBER,
                                    })
                                )
                                .max(
                                    enumValidation.MAX_NUMBER,
                                    i18n.t(`${transText}.max_number`, {
                                        returnObjects: true,
                                        name: t("object.membership_limit"),
                                        number: enumValidation.MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("object.membership_limit"),
                                    })
                                ),
                        }),
                }),
                multiRole: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.must_number`, {
                                        returnObjects: true,
                                        name: t("object.admin_limit"),
                                    })
                                )
                                .min(
                                    enumValidation.MIN_NUMBER,
                                    i18n.t(`${transText}.min_number`, {
                                        returnObjects: true,
                                        name: t("object.admin_limit"),
                                        number: enumValidation.MIN_NUMBER,
                                    })
                                )
                                .max(
                                    enumValidation.MAX_NUMBER,
                                    i18n.t(`${transText}.max_number`, {
                                        returnObjects: true,
                                        name: t("object.admin_limit"),
                                        number: enumValidation.MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("object.admin_limit"),
                                    })
                                ),
                        }),
                }),
                limitTransaction: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.must_number`, {
                                        returnObjects: true,
                                        name: t("object.admin_limit"),
                                    })
                                )
                                .min(
                                    enumValidation.MIN_NUMBER,
                                    i18n.t(`${transText}.min_number`, {
                                        returnObjects: true,
                                        name: t("object.admin_limit"),
                                        number: enumValidation.MIN_NUMBER,
                                    })
                                )
                                .max(
                                    enumValidation.MAX_NUMBER,
                                    i18n.t(`${transText}.max_number`, {
                                        returnObjects: true,
                                        name: t("object.admin_limit"),
                                        number: enumValidation.MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("object.admin_limit"),
                                    })
                                ),
                        }),
                }),
                multiLine: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.must_number`, {
                                        returnObjects: true,
                                        name: t("object.LINEOA_account_limit"),
                                    })
                                )
                                .min(
                                    enumValidation.MIN_NUMBER,
                                    i18n.t(`${transText}.min_number`, {
                                        returnObjects: true,
                                        name: t("object.LINEOA_account_limit"),
                                        number: enumValidation.MIN_NUMBER,
                                    })
                                )
                                .max(
                                    enumValidation.MAX_NUMBER,
                                    i18n.t(`${transText}.max_number`, {
                                        returnObjects: true,
                                        name: t("object.LINEOA_account_limit"),
                                        number: enumValidation.MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("object.LINEOA_account_limit"),
                                    })
                                ),
                        }),
                }),
                posFeature: Yup.bool(),
                marketingAutomationFeature: Yup.bool(),
                inbox: Yup.bool(),
                lineOaMessaging: Yup.bool(),
                reportFeature: Yup.bool(),
                overviewLoyalty: Yup.bool(),
                summaryDashboard: Yup.bool(),
                analyticsPOS: Yup.bool(),
                customerAnalytics: Yup.bool(),
                numberOfStore: Yup.number()
                    .min(
                        enumValidation.MIN_NUMBER,
                        i18n.t(`${transText}.min_number`, {
                            returnObjects: true,
                            name: t("page.numberOfStore"),
                            number: enumValidation.MIN_NUMBER,
                        })
                    )
                    .max(
                        enumValidation.MAX_NUMBER,
                        i18n.t(`${transText}.max_number`, {
                            returnObjects: true,
                            name: t("page.numberOfStore"),
                            number: enumValidation.MAX_NUMBER,
                        })
                    ),
                createService: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.must_number`, {
                                        returnObjects: true,
                                        name: t("page.createService"),
                                    })
                                )
                                .min(
                                    enumValidation.MIN_NUMBER,
                                    i18n.t(`${transText}.min_number`, {
                                        returnObjects: true,
                                        name: t("page.createService"),
                                        number: enumValidation.MIN_NUMBER,
                                    })
                                )
                                .max(
                                    enumValidation.MAX_NUMBER,
                                    i18n.t(`${transText}.max_number`, {
                                        returnObjects: true,
                                        name: t("page.createService"),
                                        number: enumValidation.MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("page.createService"),
                                    })
                                ),
                        }),
                }),
                createStaff: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.must_number`, {
                                        returnObjects: true,
                                        name: t("page.createStaff?"),
                                    })
                                )
                                .min(
                                    enumValidation.MIN_NUMBER,
                                    i18n.t(`${transText}.min_number`, {
                                        returnObjects: true,
                                        name: t("page.createStaff?"),
                                        number: enumValidation.MIN_NUMBER,
                                    })
                                )
                                .max(
                                    enumValidation.MAX_NUMBER,
                                    i18n.t(`${transText}.max_number`, {
                                        returnObjects: true,
                                        name: t("page.createStaff?"),
                                        number: enumValidation.MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("page.createStaff?"),
                                    })
                                ),
                        }),
                }),
                createCard: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.must_number`, {
                                        returnObjects: true,
                                        name: t("page.createCard"),
                                    })
                                )
                                .min(
                                    enumValidation.MIN_NUMBER,
                                    i18n.t(`${transText}.min_number`, {
                                        returnObjects: true,
                                        name: t("page.createCard"),
                                        number: enumValidation.MIN_NUMBER,
                                    })
                                )
                                .max(
                                    enumValidation.MAX_NUMBER,
                                    i18n.t(`${transText}.max_number`, {
                                        returnObjects: true,
                                        name: t("page.createCard"),
                                        number: enumValidation.MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("page.createCard"),
                                    })
                                ),
                        }),
                }),
                appointment: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.must_number`, {
                                        returnObjects: true,
                                        name: t("page.appointment"),
                                    })
                                )
                                .min(
                                    enumValidation.MIN_NUMBER,
                                    i18n.t(`${transText}.min_number`, {
                                        returnObjects: true,
                                        name: t("page.appointment"),
                                        number: enumValidation.MIN_NUMBER,
                                    })
                                )
                                .max(
                                    enumValidation.MAX_NUMBER,
                                    i18n.t(`${transText}.max_number`, {
                                        returnObjects: true,
                                        name: t("page.appointment"),
                                        number: enumValidation.MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("page.appointment"),
                                    })
                                ),
                        }),
                }),
                journeyBuilder: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.must_number`, {
                                        returnObjects: true,
                                        name: t("page.journeyBuilder"),
                                    })
                                )
                                .min(
                                    enumValidation.MIN_NUMBER,
                                    i18n.t(`${transText}.min_number`, {
                                        returnObjects: true,
                                        name: t("page.journeyBuilder"),
                                        number: enumValidation.MIN_NUMBER,
                                    })
                                )
                                .max(
                                    enumValidation.MAX_NUMBER,
                                    i18n.t(`${transText}.max_number`, {
                                        returnObjects: true,
                                        name: t("page.journeyBuilder"),
                                        number: enumValidation.MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("page.journeyBuilder"),
                                    })
                                ),
                        }),
                }),
            });
            return schema;
        } else {
            const schema = Yup.object().shape({
                name: Yup.string()
                    .required(
                        i18n.t(`${transText}.required`, {
                            returnObjects: true,
                            name: t("page.plan.plan_name"),
                        })
                    )
                    .max(
                        enumValidation.MAX_LENGTH,
                        i18n.t(`${transText}.max`, {
                            returnObjects: true,
                            name: t("page.plan.plan_name"),
                            number: enumValidation.MAX_LENGTH,
                        })
                    ),
                reward: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.invalid_number`, {
                                        returnObjects: true,
                                        name: t("page.plan.reward_limit"),
                                    })
                                )
                                .min(
                                    MIN_NUMBER,
                                    i18n.t(`${transText}.min`, {
                                        returnObjects: true,
                                        name: t("page.plan.reward_limit"),
                                        number: MIN_NUMBER,
                                    })
                                )
                                .max(
                                    MAX_NUMBER,
                                    i18n.t(`${transText}.min`, {
                                        returnObjects: true,
                                        name: t("page.plan.reward_limit"),
                                        number: MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("page.plan.reward_limit"),
                                    })
                                ),
                        }),
                }),
                coupon: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.invalid_number`, {
                                        returnObjects: true,
                                        name: t("page.plan.coupons_limit"),
                                    })
                                )
                                .min(
                                    MIN_NUMBER,
                                    i18n.t(`${transText}.min`, {
                                        returnObjects: true,
                                        name: t("page.plan.coupons_limit"),
                                        number: MIN_NUMBER,
                                    })
                                )
                                .max(
                                    MAX_NUMBER,
                                    i18n.t(`${transText}.min`, {
                                        returnObjects: true,
                                        name: t("page.plan.coupons_limit"),
                                        number: MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("page.plan.coupons_limit"),
                                    })
                                ),
                        }),
                }),
                benefit: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.invalid_number`, {
                                        returnObjects: true,
                                        name: t("page.plan.benefits_limit"),
                                    })
                                )
                                .min(
                                    MIN_NUMBER,
                                    i18n.t(`${transText}.min`, {
                                        returnObjects: true,
                                        name: t("page.plan.benefits_limit"),
                                        number: MIN_NUMBER,
                                    })
                                )
                                .max(
                                    MAX_NUMBER,
                                    i18n.t(`${transText}.max`, {
                                        returnObjects: true,
                                        name: t("page.plan.benefits_limit"),
                                        number: MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("page.plan.benefits_limit"),
                                    })
                                ),
                        }),
                }),
                contact: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.invalid_number`, {
                                        returnObjects: true,
                                        name: t("page.plan.contacts_limit"),
                                    })
                                )
                                .min(
                                    MIN_NUMBER,
                                    i18n.t(`${transText}.min`, {
                                        returnObjects: true,
                                        name: t("page.plan.contacts_limit"),
                                        number: MIN_NUMBER,
                                    })
                                )
                                .max(
                                    MAX_NUMBER,
                                    i18n.t(`${transText}.min`, {
                                        returnObjects: true,
                                        name: t("page.plan.contacts_limit"),
                                        number: MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("page.plan.contacts_limit"),
                                    })
                                ),
                        }),
                }),
                membership: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.invalid_number`, {
                                        returnObjects: true,
                                        name: t("page.plan.membership_limit"),
                                    })
                                )
                                .min(
                                    MIN_NUMBER,
                                    i18n.t(`${transText}.min`, {
                                        returnObjects: true,
                                        name: t("page.plan.membership_limit"),
                                        number: MIN_NUMBER,
                                    })
                                )
                                .max(
                                    MAX_NUMBER,
                                    i18n.t(`${transText}.min`, {
                                        returnObjects: true,
                                        name: t("page.plan.membership_limit"),
                                        number: MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("page.plan.membership_limit"),
                                    })
                                ),
                        }),
                }),
                multiRole: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.invalid_number`, {
                                        returnObjects: true,
                                        name: t("page.plan.admin_limit"),
                                    })
                                )
                                .min(
                                    MIN_NUMBER,
                                    i18n.t(`${transText}.min`, {
                                        returnObjects: true,
                                        name: t("page.plan.admin_limit"),
                                        number: MIN_NUMBER,
                                    })
                                )
                                .max(
                                    MAX_NUMBER,
                                    i18n.t(`${transText}.min`, {
                                        returnObjects: true,
                                        name: t("page.plan.admin_limit"),
                                        number: MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("page.plan.admin_limit"),
                                    })
                                ),
                        }),
                }),
                multiLine: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.invalid_number`, {
                                        returnObjects: true,
                                        name: t("page.plan.LINEOA_account_limit"),
                                    })
                                )
                                .min(
                                    MIN_NUMBER,
                                    i18n.t(`${transText}.min`, {
                                        returnObjects: true,
                                        name: t("page.plan.LINEOA_account_limit"),
                                        number: MIN_NUMBER,
                                    })
                                )
                                .max(
                                    MAX_NUMBER,
                                    i18n.t(`${transText}.min`, {
                                        returnObjects: true,
                                        name: t("page.plan.LINEOA_account_limit"),
                                        number: MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("page.plan.LINEOA_account_limit"),
                                    })
                                ),
                        }),
                }),
                posFeature: Yup.bool(),
                marketingAutomationFeature: Yup.bool(),
                inbox: Yup.bool(),
                lineOaMessaging: Yup.bool(),
                reportFeature: Yup.bool(),
                overviewLoyalty: Yup.bool(),
                summaryDashboard: Yup.bool(),
                analyticsPOS: Yup.bool(),
                customerAnalytics: Yup.bool(),
                numberOfStore: Yup.number()
                    .min(
                        enumValidation.MIN_NUMBER,
                        i18n.t(`${transText}.min_number`, {
                            returnObjects: true,
                            name: t("page.numberOfStore"),
                            number: enumValidation.MIN_NUMBER,
                        })
                    )
                    .max(
                        enumValidation.MAX_NUMBER,
                        i18n.t(`${transText}.max_number`, {
                            returnObjects: true,
                            name: t("page.numberOfStore"),
                            number: enumValidation.MAX_NUMBER,
                        })
                    ),
                createService: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.must_number`, {
                                        returnObjects: true,
                                        name: t("page.createService"),
                                    })
                                )
                                .min(
                                    enumValidation.MIN_NUMBER,
                                    i18n.t(`${transText}.min_number`, {
                                        returnObjects: true,
                                        name: t("page.createService"),
                                        number: enumValidation.MIN_NUMBER,
                                    })
                                )
                                .max(
                                    enumValidation.MAX_NUMBER,
                                    i18n.t(`${transText}.max_number`, {
                                        returnObjects: true,
                                        name: t("page.createService"),
                                        number: enumValidation.MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("page.createService"),
                                    })
                                ),
                        }),
                }),
                createStaff: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.must_number`, {
                                        returnObjects: true,
                                        name: t("page.createStaff?"),
                                    })
                                )
                                .min(
                                    enumValidation.MIN_NUMBER,
                                    i18n.t(`${transText}.min_number`, {
                                        returnObjects: true,
                                        name: t("page.createStaff?"),
                                        number: enumValidation.MIN_NUMBER,
                                    })
                                )
                                .max(
                                    enumValidation.MAX_NUMBER,
                                    i18n.t(`${transText}.max_number`, {
                                        returnObjects: true,
                                        name: t("page.createStaff?"),
                                        number: enumValidation.MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("page.createStaff?"),
                                    })
                                ),
                        }),
                }),
                createCard: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.must_number`, {
                                        returnObjects: true,
                                        name: t("page.createCard"),
                                    })
                                )
                                .min(
                                    enumValidation.MIN_NUMBER,
                                    i18n.t(`${transText}.min_number`, {
                                        returnObjects: true,
                                        name: t("page.createCard"),
                                        number: enumValidation.MIN_NUMBER,
                                    })
                                )
                                .max(
                                    enumValidation.MAX_NUMBER,
                                    i18n.t(`${transText}.max_number`, {
                                        returnObjects: true,
                                        name: t("page.createCard"),
                                        number: enumValidation.MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("page.createCard"),
                                    })
                                ),
                        }),
                }),
                appointment: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.must_number`, {
                                        returnObjects: true,
                                        name: t("page.appointment"),
                                    })
                                )
                                .min(
                                    enumValidation.MIN_NUMBER,
                                    i18n.t(`${transText}.min_number`, {
                                        returnObjects: true,
                                        name: t("page.appointment"),
                                        number: enumValidation.MIN_NUMBER,
                                    })
                                )
                                .max(
                                    enumValidation.MAX_NUMBER,
                                    i18n.t(`${transText}.max_number`, {
                                        returnObjects: true,
                                        name: t("page.appointment"),
                                        number: enumValidation.MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("page.appointment"),
                                    })
                                ),
                        }),
                }),
                journeyBuilder: Yup.object().shape({
                    _id: Yup.string(),
                    status: Yup.bool(),
                    limit: Yup.number()
                        .nullable(true)
                        .when("status", {
                            is: true,
                            then: Yup.number()
                                .typeError(
                                    i18n.t(`${transText}.must_number`, {
                                        returnObjects: true,
                                        name: t("page.journeyBuilder"),
                                    })
                                )
                                .min(
                                    enumValidation.MIN_NUMBER,
                                    i18n.t(`${transText}.min_number`, {
                                        returnObjects: true,
                                        name: t("page.journeyBuilder"),
                                        number: enumValidation.MIN_NUMBER,
                                    })
                                )
                                .max(
                                    enumValidation.MAX_NUMBER,
                                    i18n.t(`${transText}.max_number`, {
                                        returnObjects: true,
                                        name: t("page.journeyBuilder"),
                                        number: enumValidation.MAX_NUMBER,
                                    })
                                )
                                .required(
                                    i18n.t(`${transText}.required`, {
                                        returnObjects: true,
                                        name: t("page.journeyBuilder"),
                                    })
                                ),
                        }),
                }),
            });
            return schema;
        }
    };
    const formik = useFormik({
        initialValues: data,
        validationSchema: planSchema(),
        onSubmit: async (values) => {
            setIsDisableBtn(true);
            //@ts-ignore            
            try {
                if (typePage === PLAN_TYPE_CREATE) {
                    await planAPI.create({...values ,createservice: {status: false , limit: undefined}});
                    dispatch(
                        openToast({
                            message: t("message.create.success", {
                                returnObjects: true,
                                name: "plan",
                            }),
                            type: "success",
                            autoHideDuration: 2000,
                        })
                    );
                    history.goBack();
                } else {
                    await planAPI.update({...values ,createservice: {status: false , limit: undefined}}, id);
                    dispatch(
                        openToast({
                            message: t("message.update.success", {
                                returnObjects: true,
                                name: "plan",
                            }),
                            type: "success",
                            autoHideDuration: 2000,
                        })
                    );
                        history.goBack();
                }
            
            } catch (err: any) {
                if (err.response) {
                    const mess = (Array.isArray(err.response.data.errors)
                        ? err.response.data.errors[0]
                        : err.response.data.errors)  || err.response.data.message;
                    dispatch(
                        openToast({
                            message: mess,
                            type: "error",
                            autoHideDuration: 2000,
                        })
                    );
                } else
                    dispatch(
                        openToast({
                            message: t("message.create.fail"),
                            type: "error",
                            autoHideDuration: 2000,
                        })
                    );
            }
        },
    });
    let textSubmit = "";
    switch (typePage) {
        case PLAN_TYPE_CREATE:
            textSubmit = t("page.action.create");
            break;
        case PLAN_TYPE_DETAIL:
            textSubmit = t("page.action.save");
            break;
        default:
            textSubmit = t("page.action.save");
    }
    const routeBack = () => {
        history.goBack();
    };
    const onlyNumber = (event: React.KeyboardEvent<HTMLInputElement>, dot = false) => {
        if (!dot) {
            if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
            }
        } else {
            if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
                event.preventDefault();
            }
        }
    };
    return (
        <Container>
            <form className="form">
                {/* <fieldset
                    disabled={disable}
                    style={
                        disable
                            ? { opacity: 0.7, position: "relative", height: "100%" }
                            : { height: "100%" }
                    }
                >
                    {disable ? (
                        <div
                            style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                zIndex: 1,
                            }}
                        ></div>
                    ) : (
                        <></>
                    )} */}
                <StyledCard>
                    <h4>{t("page.plan.basic_setting")}</h4>
                    <InputField>
                        <h6>{t("page.plan.plan_name")}</h6>
                        <div>
                            <Input
                                placeholder={t("page.plan.enter_plan_name")}
                                size="large"
                                maxLength={256}
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                value={formik.values?.name}
                                onBlur={formik.handleBlur}
                                className={
                                    formik.touched.name
                                        ? formik.errors.name
                                            ? "invalid-input"
                                            : "valid-input"
                                        : ""
                                }
                            />
                        </div>
                        {formik.errors.name && formik.touched.name ? (
                            <small className="err-text">{formik.errors.name}</small>
                        ) : (
                            <small style={{ visibility: "hidden" }}>errField</small>
                        )}
                    </InputField>
                    {!formik.values?.planMasterId && (
                        <InputField>
                            <h6>{t("page.plan.plan_pricing")}</h6>
                            <div className="hide-arrow">
                                <Input
                                    placeholder={t("page.plan.enter_plan_pricing")}
                                    size="large"
                                    maxLength={MAX_LENGTH_INPUT}
                                    onKeyPress={(event) => onlyNumber(event, true)}
                                    id="price"
                                    name="price"
                                    type="number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={
                                        typePage === PLAN_TYPE_CREATE
                                            ? isFocus
                                                ? formik.values?.price
                                                : ""
                                            : formik.values?.price
                                    }
                                    onFocus={() => setIsFocus(true)}
                                    className={
                                        formik.touched.price
                                            ? formik.errors.price
                                                ? "invalid-input"
                                                : "valid-input"
                                            : ""
                                    }
                                />
                                {formik.errors.price && formik.touched.price ? (
                                    <small className="err-text">{formik.errors.price}</small>
                                ) : (
                                    <small style={{ visibility: "hidden" }}>errField</small>
                                )}
                            </div>
                        </InputField>
                    )}
                    {typePage === PLAN_TYPE_DETAIL && !formik.values?.planMasterId ? (
                        <InputField>
                            <div className="switch">
                                <Switch
                                    defaultChecked={formik.values?.trial}
                                    onChange={(e) => formik.setFieldValue("trial", e)}
                                ></Switch>
                                <div className="label">{t("page.plan.activate_trial")}</div>
                            </div>
                        </InputField>
                    ) : (
                        ""
                    )}
                </StyledCard>
                <StyledCard>
                    <h4>{t("page.plan.plan_feature")}</h4>
                    <div>
                        <h6 className="feature-title">{t("page.plan.loyalty")}</h6>
                        <InputField>
                            <ShareSwitchField
                                defaultChecked={formik.values?.reward.status}
                                label={t("page.plan.create_rewards")}
                                defaultValue={formik.values?.reward.limit}
                                placeHolder={t("page.plan.reward_limit")}
                                onPress={(event) => onlyNumber(event)}
                                name="reward"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                limit={formik.values?.reward.limit}
                                setFieldValue={(bool: boolean) =>
                                    formik.setFieldValue("reward.status", bool)
                                }
                                className={
                                    formik.touched.reward?.limit
                                        ? formik.errors.reward?.limit
                                            ? "invalid-input"
                                            : "valid-input"
                                        : ""
                                }
                            />
                            {formik.errors.reward?.limit && formik.touched.reward?.limit ? (
                                <small className="err-text">{formik.errors.reward.limit}</small>
                            ) : (
                                <small style={{ visibility: "hidden" }}>errField</small>
                            )}
                        </InputField>
                        <InputField>
                            <ShareSwitchField
                                defaultChecked={formik.values?.coupon.status}
                                label={t("page.plan.create_coupon")}
                                defaultValue={formik.values?.coupon.limit}
                                placeHolder={t("page.plan.coupons_limit")}
                                onPress={(event) => onlyNumber(event)}
                                name="coupon"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                limit={formik.values?.coupon.limit}
                                setFieldValue={(bool: boolean) =>
                                    formik.setFieldValue("coupon.status", bool)
                                }
                                className={
                                    formik.touched.coupon?.limit
                                        ? formik.errors.coupon?.limit
                                            ? "invalid-input"
                                            : "valid-input"
                                        : ""
                                }
                            />
                            {formik.errors.coupon?.limit && formik.touched.coupon?.limit ? (
                                <small className="err-text">{formik.errors.coupon.limit}</small>
                            ) : (
                                <small style={{ visibility: "hidden" }}>errField</small>
                            )}
                        </InputField>
                        <InputField>
                            <ShareSwitchField
                                defaultChecked={formik.values?.benefit.status}
                                label={t("page.plan.create_benefits")}
                                defaultValue={formik.values?.benefit.limit}
                                placeHolder={t("page.plan.benefits_limit")}
                                onPress={(event) => onlyNumber(event)}
                                name="benefit"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                limit={formik.values?.benefit.limit}
                                setFieldValue={(bool: boolean) =>
                                    formik.setFieldValue("benefit.status", bool)
                                }
                                className={
                                    formik.touched.benefit?.limit
                                        ? formik.errors.benefit?.limit
                                            ? "invalid-input"
                                            : "valid-input"
                                        : ""
                                }
                            />
                            {formik.errors.benefit?.limit && formik.touched.benefit?.limit ? (
                                <small className="err-text">{formik.errors.benefit.limit}</small>
                            ) : (
                                <small style={{ visibility: "hidden" }}>errField</small>
                            )}
                        </InputField>
                        <InputField>
                            <div className="switch">
                                <Switch
                                    defaultChecked={formik.values?.freePoint}
                                    onChange={(e) => formik.setFieldValue("freePoint", e)}
                                ></Switch>
                                <div className="label">{t("page.plan.create_free_points")}</div>
                            </div>
                        </InputField>
                        <InputField>
                            <div className="switch">
                                <Switch
                                    defaultChecked={formik.values?.memberShipTier}
                                    onChange={(e) => formik.setFieldValue("memberShipTier", e)}
                                ></Switch>
                                <div className="label">{t("page.plan.membership_tiering")}</div>
                            </div>
                        </InputField>
                        <InputField>
                            <div className="switch">
                                <Switch
                                    defaultChecked={formik.values?.multiSignUp}
                                    onChange={(e) => formik.setFieldValue("multiSignUp", e)}
                                ></Switch>
                                <div className="label">{t("page.plan.multi_user_signup")}</div>
                            </div>
                        </InputField>
                    </div>
                    <div>
                        <h6 className="feature-title">{t("page.plan.contact")}</h6>
                        <InputField>
                            <ShareSwitchField
                                defaultChecked={formik.values?.contact.status}
                                label={t("page.plan.contact_limit_onoff")}
                                defaultValue={formik.values?.contact.limit}
                                placeHolder={t("page.plan.contacts_limit")}
                                onPress={(event) => onlyNumber(event)}
                                name="contact"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                limit={formik.values?.contact.limit}
                                setFieldValue={(bool: boolean) =>
                                    formik.setFieldValue("contact.status", bool)
                                }
                                className={
                                    formik.touched.contact?.limit
                                        ? formik.errors.contact?.limit
                                            ? "invalid-input"
                                            : "valid-input"
                                        : ""
                                }
                            />
                            {formik.errors.contact?.limit && formik.touched.contact?.limit ? (
                                <small className="err-text">{formik.errors.contact.limit}</small>
                            ) : (
                                <small style={{ visibility: "hidden" }}>errField</small>
                            )}
                        </InputField>
                        <InputField>
                            <ShareSwitchField
                                defaultChecked={formik.values?.membership.status || false}
                                label={t("page.plan.membership_limit_onoff")}
                                defaultValue={formik.values?.membership.limit}
                                placeHolder={t("page.plan.membership_limit")}
                                onPress={(event) => onlyNumber(event)}
                                name="membership"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                limit={formik.values?.membership.limit}
                                setFieldValue={(bool: boolean) =>
                                    formik.setFieldValue("membership.status", bool)
                                }
                                className={
                                    formik.touched.membership?.limit
                                        ? formik.errors.membership?.limit
                                            ? "invalid-input"
                                            : "valid-input"
                                        : ""
                                }
                            />
                            {formik.errors.membership?.limit && formik.touched.membership?.limit ? (
                                <small className="err-text">{formik.errors.membership.limit}</small>
                            ) : (
                                <small style={{ visibility: "hidden" }}>errField</small>
                            )}
                        </InputField>
                        <InputField>
                            <div className="switch">
                                <Switch
                                    defaultChecked={formik.values?.segmentation}
                                    onChange={(e) => formik.setFieldValue("segmentation", e)}
                                ></Switch>
                                <div className="label">{t("page.plan.segmentation")}</div>
                            </div>
                        </InputField>
                    </div>
                    <div>
                        <h6 className="feature-title">{t("page.plan.others")}</h6>
                        <InputField>
                            <div className="switch">
                                <Switch
                                    defaultChecked={formik.values?.customSignUp}
                                    onChange={(e) => formik.setFieldValue("customSignUp", e)}
                                ></Switch>
                                <div className="label">
                                    {t("page.plan.custom_sign_up_form_fields")}
                                </div>
                            </div>
                        </InputField>
                        <InputField>
                            <div className="switch">
                                <Switch
                                    defaultChecked={formik.values?.welcomeScreen}
                                    onChange={(e) => formik.setFieldValue("welcomeScreen", e)}
                                ></Switch>
                                <div className="label">{t("page.plan.welcome_screen")}</div>
                            </div>
                        </InputField>
                        <InputField>
                            <div className="switch">
                                <Switch
                                    defaultChecked={formik.values?.trafficSource}
                                    onChange={(e) => formik.setFieldValue("trafficSource", e)}
                                ></Switch>
                                <div className="label">{t("page.plan.create_traffic_source")}</div>
                            </div>
                        </InputField>
                        <InputField>
                            <ShareSwitchField
                                defaultChecked={formik.values?.multiRole.status || false}
                                label={t("page.plan.multi_roles")}
                                defaultValue={formik.values?.multiRole.limit}
                                placeHolder={t("page.plan.admin_limit")}
                                onPress={(event) => onlyNumber(event)}
                                name="multiRole"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                limit={formik.values?.multiRole.limit}
                                setFieldValue={(bool: boolean) =>
                                    formik.setFieldValue("multiRole.status", bool)
                                }
                                className={
                                    formik.touched.multiRole?.limit
                                        ? formik.errors.multiRole?.limit
                                            ? "invalid-input"
                                            : "valid-input"
                                        : ""
                                }
                            />
                            {formik.errors.multiRole?.limit && formik.touched.multiRole?.limit ? (
                                <small className="err-text">{formik.errors.multiRole.limit}</small>
                            ) : (
                                <small style={{ visibility: "hidden" }}>errField</small>
                            )}
                        </InputField>
                        <InputField>
                            <ShareSwitchField
                                defaultChecked={formik.values?.limitTransaction.status || false}
                                label={t("page.plan.transaction_limit")}
                                defaultValue={formik.values?.limitTransaction.limit}
                                // placeHolder={t("page.plan.admin_limit")}
                                placeHolder={
                                    formik.values?.limitTransaction.limit?.toString() || "10000"
                                }
                                onPress={(event) => onlyNumber(event)}
                                name="limitTransaction"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                limit={formik.values?.limitTransaction.limit}
                                setFieldValue={(bool: boolean) => {
                                    formik.setFieldValue("limitTransaction.status", bool);
                                    if (bool === false) {
                                        formik.setFieldValue("limitTransaction.limit", 10000);
                                    }
                                }}
                                className={
                                    formik.touched.limitTransaction?.limit
                                        ? formik.errors.limitTransaction?.limit
                                            ? "invalid-input"
                                            : "valid-input"
                                        : ""
                                }
                            />
                            {formik.errors.multiRole?.limit && formik.touched.multiRole?.limit ? (
                                <small className="err-text">{formik.errors.multiRole.limit}</small>
                            ) : (
                                <small style={{ visibility: "hidden" }}>errField</small>
                            )}
                        </InputField>
                        <InputField>
                            <ShareSwitchField
                                defaultChecked={formik.values?.multiLine.status || false}
                                label={t("page.plan.multiple_LINEOA_connect")}
                                defaultValue={formik.values?.multiLine.limit}
                                placeHolder={t("page.plan.LINEOA_account_limit")}
                                onPress={(event) => onlyNumber(event)}
                                name="multiLine"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                limit={formik.values?.multiLine.limit}
                                setFieldValue={(bool: boolean) =>
                                    formik.setFieldValue("multiLine.status", bool)
                                }
                                className={
                                    formik.touched.multiLine?.limit
                                        ? formik.errors.multiLine?.limit
                                            ? "invalid-input"
                                            : "valid-input"
                                        : ""
                                }
                            />
                            {formik.errors.multiLine?.limit && formik.touched.multiLine?.limit ? (
                                <small className="err-text">{formik.errors.multiLine.limit}</small>
                            ) : (
                                <small style={{ visibility: "hidden" }}>errField</small>
                            )}
                        </InputField>

                        <InputField>
                            <div className="switch">
                                <Switch
                                    defaultChecked={formik.values?.posFeature}
                                    onChange={(e) => formik.setFieldValue("posFeature", e)}
                                ></Switch>
                                <div className="label">
                                    {t("page.posFeature")}
                                </div>
                            </div>
                        </InputField>
                        <InputField>
                            <h6>{t("page.numberOfStore")}</h6>
                            <div className="hide-arrow">
                                <Input
                                    placeholder={t("page.numberOfStore")}
                                    size="large"
                                    maxLength={MAX_LENGTH_INPUT}
                                    onKeyPress={(event) => onlyNumber(event, true)}
                                    id="numberOfStore"
                                    name="numberOfStore"
                                    type="number"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values?.numberOfStore}
                                    onFocus={() => setIsFocus(true)}
                                    className={
                                        formik.touched.numberOfStore
                                            ? formik.errors.numberOfStore
                                                ? "invalid-input"
                                                : "valid-input"
                                            : ""
                                    }
                                    style={{width: 280}}
                                />
                                {formik.errors.numberOfStore && formik.touched.numberOfStore ? (
                                    <small className="err-text">{formik.errors.numberOfStore}</small>
                                ) : (
                                    <small style={{ visibility: "hidden" }}>errField</small>
                                )}
                            </div>
                        </InputField>
                        <InputField>
                            <div className="switch">
                                <Switch
                                    defaultChecked={formik.values?.marketingAutomationFeature}
                                    onChange={(e) => formik.setFieldValue("marketingAutomationFeature", e)}
                                ></Switch>
                                <div className="label">
                                    {t("page.marketingAutomationFeature")}
                                </div>
                            </div>
                        </InputField>
                        <InputField>
                            <div className="switch">
                                <Switch
                                    defaultChecked={formik.values?.inbox}
                                    onChange={(e) => formik.setFieldValue("inbox", e)}
                                ></Switch>
                                <div className="label">
                                    {t("page.inbox")}
                                </div>
                            </div>
                        </InputField>
                        <InputField>
                            <div className="switch">
                                <Switch
                                    defaultChecked={formik.values?.lineOaMessaging}
                                    onChange={(e) => formik.setFieldValue("lineOaMessaging", e)}
                                ></Switch>
                                <div className="label">
                                    {t("page.lineOaMessaging")}
                                </div>
                            </div>
                        </InputField>
                        <InputField>
                            <div className="switch">
                                <Switch
                                    defaultChecked={formik.values?.reportFeature}
                                    onChange={(e) => formik.setFieldValue("reportFeature", e)}
                                ></Switch>
                                <div className="label">
                                    {t("page.reportFeature")}
                                </div>
                            </div>
                        </InputField>
                        <InputField>
                            <div className="switch">
                                <Switch
                                    defaultChecked={formik.values?.overviewLoyalty}
                                    onChange={(e) => formik.setFieldValue("overviewLoyalty", e)}
                                ></Switch>
                                <div className="label">
                                    {t("page.overviewLoyalty")}
                                </div>
                            </div>
                        </InputField>
                        <InputField>
                            <div className="switch">
                                <Switch
                                    defaultChecked={formik.values?.summaryDashboard}
                                    onChange={(e) => formik.setFieldValue("summaryDashboard", e)}
                                ></Switch>
                                <div className="label">
                                    {t("page.summaryDashboard")}
                                </div>
                            </div>
                        </InputField>
                        <InputField>
                            <div className="switch">
                                <Switch
                                    defaultChecked={formik.values?.analyticsPOS}
                                    onChange={(e) => formik.setFieldValue("analyticsPOS", e)}
                                ></Switch>
                                <div className="label">
                                    {t("page.analyticsPOS")}
                                </div>
                            </div>
                        </InputField>
                        <InputField>
                            <div className="switch">
                                <Switch
                                    defaultChecked={formik.values?.customerAnalytics}
                                    onChange={(e) => formik.setFieldValue("customerAnalytics", e)}
                                ></Switch>
                                <div className="label">
                                    {t("page.customerAnalytics")}
                                </div>
                            </div>
                        </InputField>
                        <InputField>
                            <ShareSwitchField
                                defaultChecked={formik?.values?.createService?.status || false}
                                label={t("page.createService")}
                                defaultValue={formik?.values?.createService?.limit}
                                placeHolder={t("page.createService")}
                                onPress={(event) => onlyNumber(event)}
                                name="createService"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                limit={formik?.values?.createService?.limit}
                                setFieldValue={(bool: boolean) =>
                                    formik.setFieldValue("createService.status", bool)
                                }
                                className={
                                    formik.touched.createService?.limit
                                        ? formik.errors.createService?.limit
                                            ? "invalid-input"
                                            : "valid-input"
                                        : ""
                                }
                            />
                            {formik.errors.createService?.limit && formik.touched.createService?.limit ? (
                                <small className="err-text">{formik.errors.createService?.limit}</small>
                            ) : (
                                <small style={{ visibility: "hidden" }}>errField</small>
                            )}
                        </InputField>
                        <InputField>
                            <ShareSwitchField
                                defaultChecked={formik.values?.createStaff?.status || false}
                                label={t("page.createStaff")}
                                defaultValue={formik.values?.createStaff?.limit}
                                placeHolder={t("page.createStaff")}
                                onPress={(event) => onlyNumber(event)}
                                name="createStaff?"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                limit={formik.values?.createStaff?.limit}
                                setFieldValue={(bool: boolean) =>
                                    formik.setFieldValue("createStaff.status", bool)
                                }
                                className={
                                    formik.touched.createStaff?.limit
                                        ? formik.errors.createStaff?.limit
                                            ? "invalid-input"
                                            : "valid-input"
                                        : ""
                                }
                            />
                            {formik.errors.createStaff?.limit && formik.touched.createStaff?.limit ? (
                                <small className="err-text">{formik.errors.createStaff?.limit}</small>
                            ) : (
                                <small style={{ visibility: "hidden" }}>errField</small>
                            )}
                        </InputField>
                        <InputField>
                            <ShareSwitchField
                                defaultChecked={formik.values?.createCard?.status || false}
                                label={t("page.createCard")}
                                defaultValue={formik.values?.createCard?.limit}
                                placeHolder={t("page.createCard")}
                                onPress={(event) => onlyNumber(event)}
                                name="createCard"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                limit={formik.values?.createCard?.limit}
                                setFieldValue={(bool: boolean) =>
                                    formik.setFieldValue("createCard.status", bool)
                                }
                                className={
                                    formik.touched.createCard?.limit
                                        ? formik.errors.createCard?.limit
                                            ? "invalid-input"
                                            : "valid-input"
                                        : ""
                                }
                            />
                            {formik.errors.createCard?.limit && formik.touched.createCard?.limit ? (
                                <small className="err-text">{formik.errors.createCard.limit}</small>
                            ) : (
                                <small style={{ visibility: "hidden" }}>errField</small>
                            )}
                        </InputField>
                        <InputField>
                            <ShareSwitchField
                                defaultChecked={formik.values?.appointment?.status || false}
                                label={t("page.appointment")}
                                defaultValue={formik.values?.appointment?.limit}
                                placeHolder={t("page.appointment")}
                                onPress={(event) => onlyNumber(event)}
                                name="appointment"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                limit={formik.values?.appointment?.limit}
                                setFieldValue={(bool: boolean) =>
                                    formik.setFieldValue("appointment.status", bool)
                                }
                                className={
                                    formik.touched.appointment?.limit
                                        ? formik.errors.appointment?.limit
                                            ? "invalid-input"
                                            : "valid-input"
                                        : ""
                                }
                            />
                            {formik.errors.appointment?.limit && formik.touched.appointment?.limit ? (
                                <small className="err-text">{formik.errors.appointment.limit}</small>
                            ) : (
                                <small style={{ visibility: "hidden" }}>errField</small>
                            )}
                        </InputField>
                        <InputField>
                            <ShareSwitchField
                                defaultChecked={formik.values?.journeyBuilder?.status || false}
                                label={t("page.journeyBuilder")}
                                defaultValue={formik.values?.journeyBuilder?.limit}
                                placeHolder={t("page.journeyBuilder")}
                                onPress={(event) => onlyNumber(event)}
                                name="journeyBuilder"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                limit={formik.values?.journeyBuilder?.limit}
                                setFieldValue={(bool: boolean) =>
                                    formik.setFieldValue("journeyBuilder.status", bool)
                                }
                                className={
                                    formik.touched.journeyBuilder?.limit
                                        ? formik.errors.journeyBuilder?.limit
                                            ? "invalid-input"
                                            : "valid-input"
                                        : ""
                                }
                            />
                            {formik.errors.journeyBuilder?.limit && formik.touched.journeyBuilder?.limit ? (
                                <small className="err-text">{formik.errors.journeyBuilder.limit}</small>
                            ) : (
                                <small style={{ visibility: "hidden" }}>errField</small>
                            )}
                        </InputField>
                       
                        {typePage !== PLAN_TYPE_CREATE && !formik.values?.planMasterId ? (
                            <InputField>
                                <div className="switch">
                                    <Switch
                                        defaultChecked={formik.values?.default}
                                        onChange={(e) => formik.setFieldValue("default", e)}
                                    ></Switch>
                                    <div className="label">{t("page.plan.default")}</div>
                                </div>
                            </InputField>
                        ) : (
                            <></>
                        )}
                    </div>
                </StyledCard>
                {/* </fieldset> */}
            </form>
            <InputField margin={"0px"}>
                <div className="switch">
                    <div className="label2">{t("page.active")}</div>
                    <Switch
                        onChange={(e) => formik.setFieldValue("status", e ? "ACTIVE" : "INACTIVE")}
                        defaultChecked={formik.values?.status === STATUS_ACTIVE ? true : false}
                    ></Switch>
                </div>
            </InputField>
            <div className="btn-field">
                <ModuleButtonAction
                    disable={isDisableBtn}
                    className="submit-btn"
                    onClick={formik.handleSubmit}
                    // onClick={() => console.log(formik.errors)}
                    type="primary"
                    text={t(`${textSubmit}`)}
                />
                <ModuleButtonAction
                    className="cancel-btn"
                    onClick={routeBack}
                    type="default"
                    text={t("page.action.cancel")}
                />
            </div>
            <div className="space-bottom"></div>
        </Container>
    );
};

const Container = styled.div`
    .form {
        flex: 1;

        overflow-y: auto;
        width: 100%;
        max-width: 647px;
        padding-right: 10px;

        @media screen and (max-width: ${(p) => p.theme.breakPoints.breakMobileMedium}) {
            ::-webkit-scrollbar {
                display: none;
            }
            padding-right: 0;
        }
        ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }

        ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb {
            background: ${(p) => p.theme.colors.fadedText};
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb:hover {
            cursor: grab;
        }
    }
    .feature-title {
        font-weight: 600;
        font-size: 16px;
        margin-top: 2.5rem;
        margin-bottom: 1.5rem;
    }
    .btn-field {
        display: flex;

        button {
            width: 100px;
            margin-right: 14px;
            font-size: 16px;
            font-weight: 600;
            padding: 6px 0px;
            height: auto;
            margin-bottom: 0;
        }
    }
    .space-bottom {
        padding-bottom: 3.5rem;
        @media screen and (max-width: ${(p) => p.theme.breakPoints.breakMobileMedium}) {
            padding-bottom: 1.5rem;
        }
    }
`;

const InputField = styled.div<{ margin?: string }>`
    margin: ${(p) => (p.margin ? p.margin : "24px 0px")};
    h6 {
        font-size: 16px;
        font-weight: 600;
    }
    .hide-arrow {
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }
    }
    .switch {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        .label {
            margin-left: 1rem;
            color: #646464;
            font-size: 16px;
        }
        .label2 {
            margin-right: 1rem;
            color: #646464;
            font-size: 16px;
        }
    }
    .half-W-input {
        max-width: 280px;
    }
    .invalid-input {
        border-color: ${(p) => p.theme.colors.danger};
        &:hover,
        &:focus-within {
            border-color: ${(p) => p.theme.colors.danger}!important;
        }
    }
    .err-text {
        color: ${(p) => p.theme.colors.danger};
        font-weight: 600;
        margin-left: 4px;
    }
`;
