export default {
    _copy: "copy",
    _copied: "copied",
    _total: "total",
    integrations: "Integrations",
    _merchant: "merchant",
    _plan: "plan",
    _user: "user",
    welcome: "Welcome to our page",
    welcome_to_Admin: "Welcome to Rocket CRM!",
    login: "Log in",
    forgot: "Forgot",
    password: "Password",
    remember: "Remember me",
    create_account: "Create an account",
    rocket_crm: "Welcome to Rocket CRM",
    signing: "You're signing in as",
    what_is_your_name: "What is your name",
    email: "Email",
    confirm_password: "Confirm password",
    continue: "Continue",
    instead: "instead",
    recover_password: "Recover Password",
    email_recover_link: "Email recover link",
    sign_up: "Sign up",
    log_in: "Log in",
    reset_password: "Reset your password",
    new_password: "New password",
    new_confirm_password: "Confirm new password",
    basic_info: "Basic Info",
    merchant_plan_settings: "Merchant plan settings",
    is_on: "is on",
    change_plan: "Change plan",
    plans: "Plans",
    detail_plan: "Detail plan",
    update_plan: "Update plan",
    create_new_pan: "Create new plan",
    basic_setting: "Basic setting",
    plan_feature: "Plan feature",
    plan_name: "Plan name",
    plan_pricing: "Plan pricing (฿ / month)",
    activate_trial: "Activate trial",
    trial_period: "Trial period",
    loyalty: "Loyalty",
    create_rewards: "Create rewards",
    reward_limit: "Reward limit",
    create_coupons: "Create coupons",
    coupon_limit: "Coupon limit",
    create_benefits: "Create benefits",
    benefit_limit: "Benefit limit",
    create_free_points: "Create free points",
    member_ship_tiering: "Membership tiering",
    multi_user_signup: "Multi user signup",
    contacts_limit_onoff: "Contacts limit on/off",
    contacts_limit: "Contacts limit",
    membership_limit_onoff: "Membership limit on/off",
    membership_limit: "Membership limit",
    segmentation: "Segmentation",
    other: "Other",
    others: "Others",
    custom_sign_up_form_fields: "Custom sign up form fields",
    welcome_screen: "Welcome screen",
    contact: "Contact",
    create_traffic_source: "Create traffic source",
    multiple_roles: "Multiple roles",
    admin_limit: "Admin limit",
    multi_LINEOA_connect: "Multi LINEOA connect",
    LINEOA_accout_limit: "LINEOA accout limit",
    active: "Active",
    text_verify_email: "Please verify your email address",
    desc_verify_email: "Check your mailbox for a verification email. Didn't receive an email?",
    resend_email: "Resend email",
    add_plan: "Add plan",
    enter_plan_name: "Enter plan name",
    enter_plan_pricing: "Enter plan pricing",
    create_new_plan: "Create new plan",
    edit_merchant_home: "Edit merchant homepage",
    add_box: "Add box",
    detail_navigation_box: "Detail navigation box",
    title: "Title",
    text: "Text",
    title_th: "Title(TH)",
    text_th: "Text(TH)",
    link: "Link",
    link_url: "Link URL",
    drop_down_option: "Dropdown options ",
    drop_down_name: "Dropdown name",
    drop_down_link: "Dropdown link",
    set_icon: "Set icon",
    save: "Save",
    cancel: "Cancel",
    manage_permissions: "Manage permissions",
    text_permission_sub_title: "Add new account members and manage their permissions here.",
    permission_types: "Permission types",
    add_member: "Add member",
    view_merchants: "View merchants",
    edit_merchant_homepage: "Edit merchant homepage",
    create_plans_and_edit_merchant_plans: "Create plans and edit merchant plans ",
    manage_roles: "Manage roles",
    grant_members_permissions_via_direct_URL_links:
        "Grant members permissions via direct URL links.",
    edit_members_permissions_via_direct_URL_links: "Edit members permissions via direct URL links.",
    delete_this_account_member: "Delete this account member",
    admin: "Admin",
    owner: "Owner",
    editor: "Editor",
    choose_permission: "Choose permission",
    edit_permissions_granted_to_this_account_member:
        "Edit permissions granted to this account member.",
    close: "Close",
    LINEOA_account_limit: "LINEOA account limit",
    data_not_found: "Data not found",
    null: "null",
    filed_required: "This field is required!",
    email_invalid: "Email is invalid",
    true: "True",
    false: "False",
    box_theme_color: "Box Theme Color",
    box_icon: "Box icon",
    poster_image: "Poster image",
    add_navigation_box: "Add Navigation Box",
    edit_navigation_box: "Edit Navigation Box",
    add_poster: "Add Poster",
    edit_poster: "Edit Poster",
    add_successfully: "Add Successfully",
    add_failed: "Add Failed",
    edit_successfully: "Edit Successfully",
    edit_failed: "Edit Failed",
    how_to_wan_delete: "Do you want to delete ?",
    failed: "Failed",
    success: "Success",
    resend: "Resend",
    theme_color: "Theme color",
    tip_loading: "Loading...",
    image_svg: "The image must be in the format .svg",
    image_png_jpg: "The image must be in the format .png, .jpeg, .jpg",
    return_login: "Return to log in",
    svg_50_50: "(Svg-50x50 pixels)",
    drop_name: "Dropdown name",
    drop_link: "Dropdown link",
    action: {
        create: "Create",
        edit: "Edit",
        delete: "Delete",
        cancel: "Cancel",
        _create: "create",
        _edit: "edit",
        _delete: "delete",
        duplicate: "Duplicate",
        save: "Save",
        close: "Close",
        yes: "Yes",
        add: "Add",
    },
    merchant: {
        merchants: "Merchants",
        segment_name: "Segment name",
        contacts: "Contacts",
        create_date: "Create date",
        update_date: "Update date",
        plan: "Plan",
        search_name: "Search name",
        edit_plan: "Edit plan",
        basic_info: "Basic info",
        integrations: "Integrations",
        members: "Members",
        merchant_plan_settings: "Merchant plan settings",
        is_on: "is on",
        change_plan: "Change plan",
        shop_name: "Shop name",
        total_sale: "Total sale",
        total_point_assign: "Total point assign",
        reward_active: "Reward active",
        coupon_active: "Coupon active",
        benefit_active: "Benefit active",
        reward_redeem: "Reward redeem",
        coupon_redeem: "Coupon redeem",
        benefit_redeem: "Benefit redeem",
        link_one: "Linked LINEOA account 1",
        link_two: "Linked LINEOA account 2",
        link_three: "Linked LINEOA account 3",
        last_active_date: "Last active date",
        integration: "Integration",
        form_information: "Form information",
        sale_notes: "Sales note",
        booking_field: "Booking fields",
        display_name: "Display Name",
        field_name: "Field Name",
        example_text_field: "Ex. Display name is Car Color. Field name should be is Car_color",
        number_of_employees: "Number of employees",
        experience_with_loyalty_software: "Experience with loyalty software?",
        admin_contact: "Admin contact",
        admin: "Admin",
        company_name: "Company Name",
        business_industry: "Business Industry",
        telephone_number: "Telephone Number",
        member_link: "Member link",
        merchant_global_setting: "Merchant global settings",
        loyalty_advanced_features: "Loyalty Advanced Features",
        collect_point_method: "Collect points method",
        upload: "Upload",
    },
    plan: {
        _plan: "plan",
        plans: "Plans",
        plan_name: "Plan name",
        merchants: "Merchants",
        create_date: "Create date",
        status: "status",
        add_plan: "Add plan",
        basic_setting: "Basic setting",
        enter_plan_name: "Enter plan name",
        plan_pricing: "Plan pricing (฿ / month)",
        enter_plan_pricing: "Enter plan pricing",
        activate_trial: "Active trial",
        create_rewards: "Create rewards",
        trial_period: "Trial period (days)",
        reward_limit: "Rewards limit",
        create_coupon: "Create coupon",
        coupons_limit: "Coupons limit",
        create_benefit: "Create benefit",
        benefits_limit: "Benefits limit",
        create_free_points: "Create free points",
        membership_tiering: "Membership tiering",
        multi_user_signup: "Multi-user sign-up",
        contact: "Contact",
        contact_limit_onoff: "Contact limit on/off",
        contacts_limit: "Contacts limit",
        membership_limit_onoff: "Membership limit on/off",
        membership_limit: "Membership limit",
        segmentation: "Segmentation",
        others: "Others",
        custom_sign_up_form_fields: "Custom sign-up form fields",
        welcome_screen: "Welcome screen",
        create_traffic_source: "Create traffic source",
        multi_roles: "Multiple roles",
        admin_limit: "Admin limit",
        multiple_LINEOA_connect: "Multiple LINEOA connect",
        LINEOA_account_limit: "LINEOA account limit",
        create_benefits: "Create benefits",
        create_plan: "Create plan",
        plan_detail: "Plan detail",
        update_plan: "Update plan",
        default: "Default",
        plan_feature: "Plan feature",
        loyalty: "Loyalty",
        active: "Active",
        inactive: "Inactive",
        transaction_limit: "Transaction Limit",
    },
    permission: {
        manage_permissions: "Manager permissions",
        text_permission_sub_title: "Add new account members and manage their permissions here.",
        permission_types: "Permission types",
        add_member: "Add member",
        choose_permission: "Choose permission",
        edit_permissions_granted_to_this_account_member:
            "Edit permissions granted to this account member.",
        delete_this_account_member: "Delete this account member",
        grant_members_permissions_via_direct_URL_links:
            "Grant members permissions via direct URL links.",
        choose_permission_and_copy_link: "Choose permission and copy link",
    },
    validation: {
        required: "{{name}} is required",
        min: "{{name}} is at least {{number}} ",
        max: "{{name}} maximum is {{number}} ",
        invalid_type: "Invalid data type",
        invalid_number: "{{name}} is required and acept only number",
    },
    auth: {
        login: "Login",
        email_password_correct: "Please check email and password are correct",
        login_successfully: "Login Successfully",
        login_failed: "Login Failed",
        reset_password_successfully: "Reset Password Successfully",
        reset_password_failed: "Reset Password Failed",
        password_must_match: "Passwords must match",
        you_are_signing: "You're signing",
        full_name: "Full name",
        password: "Password",
        name: "Name",
    },
    navigationBar: {
        super_admin_panel: "Super Admin Panel",
        home: "Home",
        report: "Report",
        logout: "Logout",
    },
    posFeature: "POS Feature",
    numberOfStore: "Number Of Store",
    serviceFeature: "Service Feature",
    createService: "Create Service",
    createStaff: "Create Staff",
    createCard: "Create Card",
    appointment: "Appointment",
    marketingAutomationFeature: "Marketing Automation Feature",
    inbox: "inbox",
    journeyBuilder: "Journey Builder",
    lineOaMessaging: "Line OA Messaging",
    reportFeature: "Report Feature",
    overviewLoyalty: "Overview Loyalty",
    summaryDashboard: "Summary Dashboard",
    analyticsPOS: "Analytics POS",
    customerAnalytics: "Customer Analytics",
};
