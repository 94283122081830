import styled from "styled-components";

export const StyledButton = styled.div<{ size: string }>`
    text-align: center;
    .ant-btn {
        width: ${(p) => (p.size === "large" ? "100%" : p.size === "small" ? "60%" : "")};
        color: #fff;
        padding: 16px 0;
        height : ${p => p.theme.heights.input};
        background-color: #f22f46;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: ${p => p.theme.breakPoints.breakMobilePhone}) {
            height: 50px;
        }
    }
    .ant-btn-primary {
          border-color: #d9d9d9 !important;
        box-shadow: none !important;
    }
`;
