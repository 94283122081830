
import axiosClient from "./axiosClient";

import {PATH_ROLES, PATH_INVITE_LINKS} from "@configs";



export const roleAPI = {
    getList: () => {
        const url = PATH_ROLES;
        return axiosClient.get(url);
    },
    validInviteLink: (id: string) => {
        const url = `${PATH_INVITE_LINKS}/${id}`;
        return axiosClient.get(url);
    },
    createInviteLink: (bodyData: {roleId: string}) => {
        const url = PATH_INVITE_LINKS;
        return axiosClient.post(url, bodyData);
    }

};
