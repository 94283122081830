import { Form, Input } from "antd";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { ShareButton, StyledCreatePassword } from "@components";
import { PATH_HOME, PATH_LOGIN, enumValidation } from "@configs";
import { authApi } from "@api";
import { setAuth, openToast, setLoading } from "@redux";
import { IAuth } from "@interfaces";

interface IPostData {
    fullName: string;
    password: string;
    resetPasswordId: string;
}

export const PageCreatePassword = () => {
    //page Hooks
    const { t } = useTranslation();
    const { id } = useParams<{ id: string; email: string }>();
    const history = useHistory();
    const dispatch = useDispatch();

    // page state
    const [confirmPass, setConfirmPass] = useState("");
    const [ErrPass, setErrPass] = useState(false);
    // validation
    dispatch(setLoading(true));
    const createPasswordSchema = Yup.object().shape({
        fullName: Yup.string()
            .required(t("validation.required", { returnObjects: true, name: t("page.auth.name") }))
            .min(
                6,
                t(`page.validation.min`, {
                    returnObjects: true,
                    name: t("page.auth.name"),
                    number: 6,
                })
            )
            .max(
                255,
                t(`page.validation.max`, {
                    returnObjects: true,
                    name: t("page.auth.full_name"),
                    number: 255,
                })
            ),
        password: Yup.string()
            .required(
                t("validation.required", { returnObjects: true, name: t("page.auth.password") })
            )
            .min(
                enumValidation.MIN_PASSWORD,
                t("validation.min", {
                    returnObjects: true,
                    name: t("page.auth.password"),
                    number: enumValidation.MIN_PASSWORD,
                })
            )
            .max(
                enumValidation.MAX_PASSWORD,
                t("validation.max_length_number", {
                    returnObjects: true,
                    name: t("page.auth.password"),
                    number: enumValidation.MAX_PASSWORD,
                })
            ),
    });
    const formik = useFormik({
        initialValues: {
            fullName: "",
            password: "",
            resetPasswordId: id,
        },
        validationSchema: createPasswordSchema,
        onSubmit: (values) => handleSubmit(values),
    });
    const href = window.location.href;
    const url = new URL(href);
    const email = url.searchParams.get("email");

    const handleMoveLogin = () => {
        history.push(PATH_LOGIN);
    };

    const putData = async (values: IPostData) => {
        try {
            const response = await authApi.createPassword(values);
            if (response.status === 200) {
                dispatch(
                    openToast({
                        message: t("message.create.success", { returnObjects: true, name: "user" }),
                        type: "success",
                        autoHideDuration: 2000,
                    })
                );
                dispatch(setAuth(response.data as IAuth));
                history.push(PATH_HOME);
            } else {
                dispatch(
                    openToast({
                        message: t("message.create.fail", { returnObjects: true, name: "user" }),
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
                history.push(PATH_LOGIN);
            }
        } catch (err) {
            dispatch(
                openToast({
                    message: t("message.create.fail", { returnObjects: true, name: "user" }),
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
            history.push(PATH_LOGIN);
        }
    };
    const handleSubmit = async (values: IPostData) => {
        if (confirmPass !== formik.values.password) {
            setErrPass(true);
        } else {
            dispatch(setLoading(true));
            await putData(values);
            setErrPass(false);
            dispatch(setLoading(false));
        }
    };
    const checkConfirm = (value: string) => {
        setConfirmPass(value);
        setErrPass(false);
    };
    return (
        <StyledContainer>
            <StyledCreatePassword>
                <div className="title_welcome main-color">{t("page.welcome_to_Admin")}</div>
                <div className="signing">
                    {t("page.auth.you_are_signing")} {email}
                </div>

                <Form
                    labelCol={{
                        span: 3,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    layout="horizontal"
                >
                    <Form.Item
                        colon={false}
                        label={t("page.what_is_your_name")}
                        className="main-color"
                        style={{ marginBottom: 0 }}
                    >
                        <Input
                            type="text"
                            style={{ width: "100%" }}
                            name="fullName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.fullName}
                            className={
                                formik.touched.fullName
                                    ? formik.errors.fullName
                                        ? "invalid-input"
                                        : "valid-input"
                                    : ""
                            }
                        />
                        {formik.errors.fullName && formik.touched.fullName ? (
                            <small className="err-text">{formik.errors.fullName}</small>
                        ) : (
                            <small style={{ visibility: "hidden" }}>errField</small>
                        )}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        label={t("page.password")}
                        className="main-color"
                        style={{ marginBottom: 0 }}
                    >
                        <Input
                            style={{ width: "100%" }}
                            type="password"
                            name="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            className={
                                formik.touched.password
                                    ? formik.errors.password
                                        ? "invalid-input"
                                        : "valid-input"
                                    : ""
                            }
                        />
                        {formik.errors.password && formik.touched.password ? (
                            <small className="err-text">{formik.errors.password}</small>
                        ) : (
                            <small style={{ visibility: "hidden" }}>errField</small>
                        )}
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        label={t("page.confirm_password")}
                        className="main-color"
                        style={{ marginBottom: 0 }}
                    >
                        <Input
                            style={{ width: "100%" }}
                            type="password"
                            className={ErrPass ? "invalid-input" : ""}
                            onChange={(e) => checkConfirm(e.target.value)}
                        />
                        {ErrPass ? (
                            <small className="err-text">
                                {t("validation.password_must_match")}
                            </small>
                        ) : (
                            <small style={{ visibility: "hidden" }}>errField</small>
                        )}
                    </Form.Item>
                    <ShareButton
                        type="primary"
                        size="large"
                        className="btn-continue"
                        text={t("page.continue")}
                        onClick={() => formik.handleSubmit()}
                    />
                    <div className="login_instead">
                        <span style={{ cursor: "pointer" }} onClick={handleMoveLogin}>
                            {t("page.auth.login")}
                        </span>{" "}
                        {t("instead")}
                    </div>
                </Form>
            </StyledCreatePassword>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    .err-input {
    }
    .err-text {
        font-weight: bold;
        color: ${(p) => p.theme.colors.danger};
        font-size: 12px;
    }
    .invalid-input {
        border-color: ${(p) => p.theme.colors.danger}!important;
        &:focus-within {
            border-color: ${(p) => p.theme.colors.danger}!important;
        }
    }
    .valid-input {
    }
    .ant-form-item-control {
        line-height: 1.5 !important;
    }
`;
