export default  {
    merchant: "ร้านค้า",
    _merchant: "ร้านค้า",
    plan: "Plan",
    _plan: "plan",
    user: "ผู้ใช้งาน",
    _user: "ผู้ใช้งาน",
    plan_pricing: "ราคา Plan",
    reward_limit: "จำกัดจำนวน Reward",
    coupons_limit: "จำกัดจำนวน Coupon",
    benefits_limit: "จำกัดจำนวน Benefits",
    membership_limit: "จำกัดจำนวน Membership",
    admin_limit: "จำกัดจำนวน Admin",
    LINEOA_account_limit: "จำกัดจำนวน LINEOA account",
    contacts_limit: "จำกัดจำนวน Contacts",

};