import axiosClient from "./axiosClient";
import { IPlanDetail, IPagination } from "@interfaces";
import { PATH_PLANS } from "@configs";

export const planAPI = {
    getList: (params: IPagination) => {
        const url = PATH_PLANS;
        return axiosClient.get(url, { params });
    },
    create: (bodyData: IPlanDetail) => {
        const url = PATH_PLANS;
        const body = bodyData;
        return axiosClient.post(url, body);
    },
    detail: (id: string) => {
        const url = PATH_PLANS + "/" + id;
        return axiosClient.get(url);
    },
    update: (bodyData: IPlanDetail, id: string) => {
        const url = PATH_PLANS + "/" + id + "/update-plan";
        const body = bodyData;
        return axiosClient.put(url, body);
    },
    delete: (id: string) => {
        const url = PATH_PLANS + "/" + id;
        return axiosClient.delete(url);
    },
    duplicate: (id: string) => {
        const url = PATH_PLANS + "/" + id + "/duplicate";
        return axiosClient.post(url);
    },
    cloneTrialPlan: (id: string, param: { status: boolean }) => {
        const url = `plans/${id}/clone-trail-plan`;
        return axiosClient.post(url, param);
    },
};
