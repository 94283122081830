import { ICon } from "@interfaces";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from ".";

interface IInitialState {
    mainColor: string;
    mainIcon:ICon;
}

const initialState: IInitialState = {
    mainColor:"#000000",
    mainIcon:{
        key:"",
        value:""
    }
};

const theme = createSlice({
    name: "theme",
    initialState,
    reducers: {
        setMainColor: (state, action: PayloadAction<string>) => {
          state.mainColor = action.payload;
        },
        setMainIcon:(state,action: PayloadAction<ICon>) => {
            state.mainIcon = action.payload;
        }
    }
});

export const selectTheme = (state: RootState) => state.theme;

export const { setMainColor, setMainIcon} = theme.actions;

export default theme.reducer;