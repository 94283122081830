import { ErrorMessage } from "formik";
import { Input } from "antd";
import { CSSProperties } from "styled-components";
import styled from "styled-components";

import { IHandleBlur, IHandleChange } from "@interfaces";
import { StyledError } from "@components";

interface IProps {
    name: string;
    style: CSSProperties | undefined;
    onChange: IHandleChange;
    value: string;
    onBlur: IHandleBlur;
    type?: string;
    errors?: string | undefined;
    touched?: boolean | undefined;
}

export const ShareInput = (props: IProps) => {
    //page props
    const { name, style, onChange, onBlur, value, type, errors, touched } = props;

    return (
        <StyledShareInput>
            <Input
                type={type}
                name={name}
                style={{ ...style }}
                onChange={onChange}
                value={value}
                onBlur={onBlur}
                className={errors && touched ? "invalid-input" : ""}
            />
            <ErrorMessage name={name} component={StyledError} />
        </StyledShareInput>
    );
};

const StyledShareInput = styled.div`
    .invalid-input {
        border-color: ${(p) => p.theme.colors.danger}!important;
        &:focus-within {
            border-color: ${(p) => p.theme.colors.danger}!important;
        }
    }
`;
