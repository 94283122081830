import { Button } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface IProps {
    type: "default" | "ghost" | "primary" | "dashed" | "danger" | undefined;
    text: string;
    onClick: () => void;
    className?: string;
    disable?: boolean;
}
export const ModuleButtonAction = (props: IProps) => {
    //page hook
    const { t } = useTranslation();
    return (
        <Container>
            <Button
                disabled={props.disable}
                className={props.className}
                onClick={props.onClick}
                type={props.type}
            >
                {t(props.text)}
            </Button>
        </Container>
    );
};

const Container = styled.div`
    .submit-btn {
        background-color: ${(p) => p.theme.colors.primary};
    }
    .cancel-btn {
        background-color: transparent;
        border: 1px solid black;
        &:hover {
            color: gray;
        }
    }
`;
