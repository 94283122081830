/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Avatar, Drawer, Form, Input, List, Switch, Button, Icon, Spin } from "antd";
import { Skeleton } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { ICon, IDropDownOption, IHomeNavigation, IHomePoster } from "@interfaces";
import { homePosterApi } from "@api";
import { openToast, selectTheme, setMainColor } from "@redux";
import {
    ComponentTheme,
    IconCamera,
    ComponentIcon,
    StyledError,
    IconLoadingDrawer,
} from "@components";
import { validations } from "@utils";

interface IProps {
    title: string;
    placement: "top" | "right" | "bottom" | "left" | undefined;
    width: number | string;
    drawer: boolean;
    setDrawer: Dispatch<SetStateAction<boolean>>;
    idData: string;
    setDataDad: Dispatch<SetStateAction<IHomePoster[] | null | undefined>>;
    dataDad: IHomePoster[] | null | undefined;
}

export const ModuleDrawerPoster = (props: IProps) => {
    //page Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const mainColor = useSelector(selectTheme).mainColor;
    //props
    const { title, placement, width, setDrawer, drawer, idData, setDataDad, dataDad } = props;
    //page variable
    const initialData = {
        imageUrl: "",
        link: "",

    };
    //page states
    const [dropDown, setDropDown] = useState<boolean>(false);
    const [data, setData] = useState<IHomePoster>(initialData);
    const [disableBtnSave, setDisableBtnSave] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
    const [toggle, setToggle] = useState<boolean>(false);
    const [toggleIcon, setToggleIcon] = useState<boolean>(false);
    const [errors, setErrors] = useState({
        link: "",
    });

    useEffect(() => {
        handleValidation();
    }, [errors, data, dropDown]);

    useEffect(() => {
        if (title === t("page.add_poster")) {
            setData(initialData);
            setDisableBtnSave(true);
        }
        return () => {
            dispatch(setMainColor("#fff"));
        };
    }, []);
    useEffect(() => {
        if (title === t("page.add_poster")) {
            setData(initialData);
            setDisableBtnSave(true);
            dispatch(setMainColor("#7a7a7a"));
        } else if (title === t("page.edit_poster") && idData) {
            (async () => {
                setShowSkeleton(true);
                const res = await homePosterApi.getItem(idData);
                // const dropDown =
                //     res.data.dropdownOptions &&
                //     res.data.dropdownOptions.map((data: any) => {
                //         return {
                //             ...data,
                //             error: [
                //                 {
                //                     key: "",
                //                     value: "",
                //                 },
                //                 {
                //                     key: "",
                //                     value: "",
                //                 },
                //             ],
                //         };
                //     });
                setData({ ...res.data });
                // dispatch(setMainColor(res.data.colorTheme));
                // setDropDown(res.data.dropdownStatus);
                setShowSkeleton(false);
            })();
        }
        setErrors({
            link: ""
        });
    }, [title, idData]);

    const handleValidation = () => {
        // const checkErrorsDropDown = data.dropdownOptions.some((item) => {
        //     if (item.error) {
        //         return item.error[0].value || item.error[1].value;
        //     } else {
        //         return false;
        //     }
        // });
        if (errors.link) {
            setDisableBtnSave(true);
            return true;
        } else {
            setDisableBtnSave(false);
            return false;
        }
    };

    const ErrorMessage = (errors: Array<string>, errorDefault: string) => {
        if (errors?.length !== 0) {
            dispatch(
                openToast({
                    message: errors[0],
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        } else {
            dispatch(
                openToast({
                    message: errorDefault,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        }
    };

    const addData = async () => {
        setIsLoading(true);
        const fetchData = dropDown ? data : { ...data};
        try {
            const res = await homePosterApi.addData({
                ...fetchData,
                // colorTheme: mainColor,
                // dropdownStatus: dropDown,
            });
            if (dataDad && res.data) {
                setDataDad([...dataDad, res.data]);
            }
            dispatch(
                openToast({
                    message: t("page.add_successfully"),
                    type: "success",
                    autoHideDuration: 2000,
                })
            );

            setData(initialData);
            closeDrawer();
        } catch (error: any) {
            const errors = error.response?.data.errors;
            ErrorMessage(errors, t("page.add_failed"));
        } finally {
            setIsLoading(false);
        }
    };

    const editData = async () => {
        setIsLoading(true);

        try {
            const res = await homePosterApi.editData(
                { ...data},
                idData
            );
            const index = dataDad?.findIndex((item) => item._id === idData) || 0;
            if (index >= 0 && dataDad) {
                dataDad[index] = res.data;
                setDataDad([...dataDad]);
            }

            dispatch(
                openToast({
                    message: t("page.edit_successfully"),
                    type: "success",
                    autoHideDuration: 2000,
                })
            );
            closeDrawer();
        } catch (error: any) {
            const errors = error.response.data.errors;
            ErrorMessage(errors, t("page.edit_failed"));
        } finally {
            setIsLoading(false);
        }
    };

    const handleSave = () => {
        let checkValidation = false;
        const errorsValidate = {
            link: ""
        };

        if (!data.link) {
            errorsValidate.link = t("validation.required", {
                returnObjects: true,
                name: t("page.link"),
            });
            checkValidation = true;
        }
        let checkError = false;

        if (checkValidation) {
            setErrors({ ...errorsValidate });
            return;
        }
        if (handleValidation()) return;
        if (disableBtnSave && checkError) return;
        switch (title) {
            case t("page.add_poster"):
                addData();
                break;
            case t("page.edit_poster"):
                editData();
                break;
            default:
                break;
        }
    };


    const handleChangeLink = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (validations("max-length", value)) {
            setErrors({
                ...errors,
                link: t("validation.max_length", {
                    returnObjects: true,
                    name: t("page.link"),
                }),
            });
            setDisableBtnSave(true);
            return;
        }
        if (validations("min-length", value)) {
            setErrors({
                ...errors,
                link: t("validation.required", {
                    returnObjects: true,
                    name: t("page.link"),
                }),
            });
            setData({ ...data, link: value });
            setDisableBtnSave(true);
            return;
        }
        const regexLink =
            // eslint-disable-next-line no-useless-escape
            /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        if (!regexLink.test(value)) {
            setErrors({
                ...errors,
                link: t("validation.link_must_url"),
            });
            setData({ ...data, link: value });
            setDisableBtnSave(true);
            return;
        }
        setErrors({
            ...errors,
            link: "",
        });
        setDisableBtnSave(false);
        setData({ ...data, link: value });
    };


    const handleChangeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        //@ts-ignore
        const values = e.target.files[0];
        if (!values.type.includes("image/png") && !values.type.includes("image/jpeg") && !values.type.includes("image/jpg")) {
            dispatch(
                openToast({
                    message: t("page.image_png_jpg"),
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
            return;
        }
        const formData = new FormData();
        formData.append("file", values);
        try {
            const res = await homePosterApi.uploadImage(formData);

            setData({
                ...data,
                imageUrl: res.data.publicUrl,
            });
        } catch (error) {
            // console.log(error);
        }
    };

    const closeDrawer = () => {
        setDrawer(!drawer);
        setTimeout(() => {
            setToggleIcon(false);
            setToggle(false);
        }, 300);
    };

    const loadingComponent = (
        <div className="loading-component">
            <Skeleton variant="text" height={20} width={"30%"} />
            <Skeleton variant="text" height={60} />
            <Skeleton variant="text" height={20} width={"30%"} />
            <Skeleton variant="text" height={60} />
            <Skeleton variant="text" height={20} width={"30%"} />
            <Skeleton variant="text" height={60} />
            <Skeleton variant="text" height={20} width={"20%"} />
            <Skeleton variant="rectangular" width={180} height={180} style={{ borderRadius: 6 }} />
            <Skeleton variant="text" height={60} />
            <Skeleton variant="text" height={60} />
            <Skeleton variant="text" height={60} />
        </div>
    );

    return (
        <StyledDrawer>
            <Drawer
                title={title}
                placement={placement}
                closable={true}
                width={width}
                onClose={closeDrawer}
                visible={drawer}
            >
                <Spin
                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    spinning={isLoading}
                    indicator={<IconLoadingDrawer />}
                >
                    {showSkeleton ? (
                        loadingComponent
                    ) : (
                        <Form
                            labelCol={{
                                span: 3,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            layout="horizontal"
                        >
                            <Form.Item colon={false} label={t("page.link")}>
                                <Input
                                    style={{ width: "100%" }}
                                    onChange={handleChangeLink}
                                    value={data.link}
                                    className={errors.link ? "invalid-input" : ""}
                                />
                                {errors.link && <StyledError>{errors.link}</StyledError>}
                            </Form.Item>
                            <div className="avatar">
                                <div className="box_title">
                                    {t("page.poster_image")}
                                    <span>{t("page.svg_50_50")}</span>
                                </div>
                                <Avatar
                                    size={110}
                                    src={data.imageUrl}
                                    shape="square"
                                    // style={{ backgroundColor: mainColor }}
                                />
                                <input
                                    id="file_update_img"
                                    type="file"
                                    hidden
                                    onChange={handleChangeImage}
                                />
                                <label className="camera" htmlFor="file_update_img">
                                    <IconCamera />
                                </label>
                            </div>
                            {/* <ComponentTheme
                                toggle={toggle}
                                handleToggle={() => {
                                    setToggle(!toggle);
                                }}
                            /> */}
                            <div className="group_button_navigation">
                                <Button
                                    type="primary"
                                    size="large"
                                    className="btn--save"
                                    onClick={handleSave}
                                    // disabled={disableBtnSave}
                                >
                                    {t("page.save")}
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={closeDrawer}
                                    size="large"
                                    className="btn--cancel"
                                >
                                    {t("page.cancel")}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Spin>
            </Drawer>
        </StyledDrawer>
    );
};

interface IPropsDropDown {
    index: number;
    handleChangeDropName: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
    handleChangeDropLink: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
    handleChangeIconUrlDropIndex: (value: ICon, index: number) => void;
    handleRemoveDropIndex: (index: number) => void;
    data: IDropDownOption;
    toggleIcon: boolean;
    handleToggle: () => void;
}

const DropDown = (props: IPropsDropDown) => {
    //page Hooks
    const { t } = useTranslation();
    //page props
    const {
        index,
        data,
        toggleIcon,
        handleChangeDropLink,
        handleChangeDropName,
        handleChangeIconUrlDropIndex,
        handleRemoveDropIndex,
        handleToggle,
    } = props;
    //page state
    return (
        <>
            <div className="box__dropDown" key={index}>
                <Input
                    style={{ width: "100%", marginBottom: "14px" }}
                    placeholder={t("page.drop_down_name")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChangeDropName(e, index);
                    }}
                    value={data.name}
                    className={
                        data.error && data.error[0].key && data.error[0].value
                            ? "invalid-input"
                            : ""
                    }
                />
                {data.error && data.error[0].key && data.error[0].value && (
                    <StyledError className="error_drop_down">{data.error[0].value}</StyledError>
                )}
                <Input
                    style={{ width: "100%", marginBottom: "16px" }}
                    placeholder={t("page.drop_down_link")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleChangeDropLink(e, index);
                    }}
                    value={data.link}
                    className={
                        data.error && data.error[1].key && data.error[1].value
                            ? "invalid-input"
                            : ""
                    }
                />
                {data.error && data.error[1].key && data.error[1].value && (
                    <StyledError className="error_drop_down">{data.error[1].value}</StyledError>
                )}
                <div className="group__icons">
                    <div className="share" onClick={handleToggle}>
                        <Icon
                            type={data.iconUrl}
                            theme="outlined"
                            style={{ marginRight: "8px", color: "#08c", fontSize: "22px" }}
                        />
                        <span>{data.iconUrl ? data.iconUrl : t("page.set_icon")}</span>
                    </div>
                    <div className="edit_menu">
                        <img
                            className="delete"
                            src="/images/delete.png"
                            onClick={() => {
                                handleRemoveDropIndex(index);
                            }}
                        />
                    </div>
                </div>
                <ComponentIcon
                    handleToggle={handleToggle}
                    toggle={toggleIcon}
                    index={index}
                    onClick={handleChangeIconUrlDropIndex}
                />
            </div>
        </>
    );
};

const StyledDrawer = styled.div`
    .avatar {
        .box_title {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
        }
    }
`;
