import { Card } from "antd";
import styled from "styled-components";
import { Edit3 } from "react-feather";
import { useTranslation } from "react-i18next";

import { IHomeNavigation, IHomePoster } from "@interfaces";
import { DeleteFilled } from "@ant-design/icons";

const { Meta } = Card;

interface IProps {
    data: IHomeNavigation;
    dataPoster: IHomePoster;
    onclick: (title: string, idData?: string) => void;
    onTogglePopup: (idItem: string | undefined) => void;
}

export const ModuleCart = (props: IProps) => {
    //page Hooks
    const { t } = useTranslation();
    //page variable
    const { data, onTogglePopup } = props;
    return (
        <StyledCart color={data.colorTheme}>
            <Card>
                <Meta
                    title={
                        <>
                            <div className="title">{data.title}</div>
                            <div className="desc">{data.text} </div>
                        </>
                    }
                    style={{ color: "#fff" }}
                />
            </Card>
            <div className="box">
                <div
                    className="overlay"
                    onClick={() => {
                        props.onclick(t("page.edit_navigation_box"), data._id);
                    }}
                ></div>
                <div className="top">
                    <Edit3
                        size={18}
                        strokeWidth={2}
                        style={{ marginRight: "8px", cursor: "pointer", color: "#fff" }}
                        onClick={() => {
                            props.onclick(t("page.edit_navigation_box"), data._id);
                        }}
                    />
                    <DeleteFilled
                        style={{ cursor: "pointer", color: "#fff" }}
                        onClick={() => {
                            onTogglePopup(data._id);
                        }}
                    />
                </div>
            </div>
            <img className="iconUrl" src={data.iconUrl} alt="Icon" />
        </StyledCart>
    );
};
export const ModuleCartPoster = (props: IProps) => {
    //page Hooks
    const { t } = useTranslation();
    //page variable
    const { dataPoster, onTogglePopup } = props;
    return (
        <StyledCartLower color='#d9d9d9'>
            <Card>
                <Meta
                    title={
                        <>
                            <div className="title">{dataPoster.link}</div>
                            {/* <div className="desc">{dataPoster.text} </div> */}
                        </>
                    }
                    style={{ color: "#fff" }}
                />
            </Card>
            <div className="box">
                <div
                    className="overlay"
                    onClick={() => {
                        props.onclick(t("page.edit_poster"), dataPoster._id);
                    }}
                ></div>
                <div className="top">
                    <Edit3
                        size={18}
                        strokeWidth={2}
                        style={{ marginRight: "8px", cursor: "pointer", color: "#fff" }}
                        onClick={() => {
                            props.onclick(t("page.edit_poster"), dataPoster._id);
                        }}
                    />
                    <DeleteFilled
                        style={{ cursor: "pointer", color: "#fff" }}
                        onClick={() => {
                            onTogglePopup(dataPoster._id);
                        }}
                    />
                </div>
            </div>
            <img className="iconUrl" src={dataPoster.imageUrl} alt="Icon" />
        </StyledCartLower>
    );
};

const StyledCart = styled.div<{ color: string }>`
    position: relative;
    height: 160px !important;
    background: transparent !important;
    color: #fff;
    .iconUrl {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 65%;
        z-index: 1;
        object-fit: cover;
        opacity: 0.25;
        max-width: 50%;
    }
    .ant-card-body {
        z-index: 10;
    }
    .title {
        color: #fff !important;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 2.5rem;
        margin-bottom: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            font-size: 22px;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobilePhone}) {
            font-size: 20px;
        }
    }
    .desc {
        line-height: 1.4rem;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        color: #fff !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            font-size: 14px;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobilePhone}) {
            font-size: 12px;
        }
    }
    &:hover .box {
        opacity: 1;
        visibility: visible;
    }
    .box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 12px;
        /* background-color: ${(p) => p.theme.colors.textSecondary}; */
        line-height: initial;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: space-between;
        font-size: 18px;
        opacity: 0;
        visibility: hidden;
        transition: all ease-in-out 0.3s;
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            font-size: 16px;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobilePhone}) {
            font-size: 14px;
        }
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: transparent;
            z-index: 2;
        }
    }
    .top {
        display: flex;
        align-items: flex-end;
        position: relative;
        top: -5px;
    }
    .bottom {
        cursor: pointer;
    }
    .top,
    .bottom {
        z-index: 3;
    }
    .icon-delete {
        width: 20px;
        height: 20px;
        object-fit: cover;
        cursor: pointer;
    }
    .icon_menu {
        width: 12px;
        height: 21px;
        object-fit: cover;
        cursor: pointer;
    }
    .ant-card {
        width: 100%;
        height: 100%;
        background-color: ${(p) => p.color};
        background-size: cover;
    }
    .icon-menu {
        cursor: pointer;
    }
`;


const StyledCartLower = styled.div<{ color: string }>`
    position: relative;
    height: 320px !important;
    background: transparent !important;
    color: #fff;
    .iconUrl {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        object-fit: cover;
    }
    .ant-card-body {
        z-index: 10;
    }
    .title {
        color: #fff !important;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 2.5rem;
        margin-bottom: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            font-size: 22px;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobilePhone}) {
            font-size: 20px;
        }
    }
    .desc {
        line-height: 1.4rem;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        color: #fff !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            font-size: 14px;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobilePhone}) {
            font-size: 12px;
        }
    }
    &:hover .box {
        opacity: 1;
        visibility: visible;
    }
    .box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 12px;
        /* background-color: ${(p) => p.theme.colors.textSecondary}; */
        line-height: initial;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: space-between;
        font-size: 18px;
        opacity: 0;
        visibility: hidden;
        transition: all ease-in-out 0.3s;
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            font-size: 16px;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobilePhone}) {
            font-size: 14px;
        }
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: transparent;
            z-index: 2;
        }
    }
    .top {
        display: flex;
        align-items: flex-end;
        position: relative;
        top: -5px;
    }
    .bottom {
        cursor: pointer;
    }
    .top,
    .bottom {
        z-index: 3;
    }
    .icon-delete {
        width: 20px;
        height: 20px;
        object-fit: cover;
        cursor: pointer;
    }
    .icon_menu {
        width: 12px;
        height: 21px;
        object-fit: cover;
        cursor: pointer;
    }
    .ant-card {
        width: 100%;
        height: 100%;
        background-color: ${(p) => p.color};
        background-size: cover;
    }
    .icon-menu {
        cursor: pointer;
    }
`;