import { useState, useEffect } from "react";
import { Col, Row, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ScrollContainer from "react-indiana-drag-scroll";
import { useSelector } from "react-redux";

import { ComponentEmpty, StyledTable, ComponentRowPlan, StyledTableButtonTop } from "@components";
import { PAGINATION, PATH_PLANS } from "@configs";
import { IPagination, IPlanGeneral } from "@interfaces";
import { planAPI } from "@api";
import { useAppDispatch, openToast, setLoading, selectApp } from "@redux";

export const PagePlanList = () => {
    //page hook
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useAppDispatch();
    //redux states
    const { loading } = useSelector(selectApp);
    // page state
    const [planList, setPlanList] = useState<IPlanGeneral[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPlan, setTotalPlan] = useState<number>(0);

    useEffect(() => {
        dispatch(setLoading(true));
        fetchListPlan(currentPage);
    }, []);

    const handlePagination = (page: number) => {
        dispatch(setLoading(true));
        setCurrentPage(page);
        fetchListPlan(page);
    };

    const fetchListPlan = async (page: number) => {
        //WHAT: setup list parameter
        const listMerchantParam: IPagination = {
            page,
            limit: PAGINATION,
        };

        //WHAT: set List plan
        const response = await planAPI.getList(listMerchantParam);
        if (response?.data?.docs) {
            setPlanList(response.data.docs);
            setTotalPlan(response.data?.totalDocs || totalPlan);
            setCurrentPage(response.data.page);
        } else {
            setPlanList([]);
        }

        dispatch(setLoading(false));
    };

    const createNew = () => {
        const pathname = `${PATH_PLANS}/new`;
        history.push({ pathname });
    };

    const deletePlan = async (id: string) => {
        try {
            dispatch(setLoading(true));
            await planAPI.delete(id);
            dispatch(
                openToast({
                    message: t("message.delete.success", {
                        returnObjects: true,
                        name: t("object._plan"),
                    }),
                    type: "success",
                    autoHideDuration: 2000,
                })
            );
            if (planList.length <= 1 && currentPage > 1) fetchListPlan(currentPage - 1);
            else fetchListPlan(currentPage);
        } catch (err: any) {
            if (err.response) {
                const mess = Array.isArray(err.response.data.errors)
                    ? err.response.data.errors[0]
                    : err.response.data.errors;
                dispatch(
                    openToast({
                        message: mess,
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            } else
                dispatch(
                    openToast({
                        message: t("message.delete.fail"),
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
        } finally {
            setLoading(false);
        }
    };

    const duplicatePlan = async (id: string) => {
        dispatch(setLoading(true));
        try {
            await planAPI.duplicate(id);
            dispatch(
                openToast({
                    message: t("message.duplicate.success", {
                        returnObjects: true,
                        name: t("object._plan"),
                    }),
                    type: "success",
                    autoHideDuration: 2000,
                })
            );
            fetchListPlan(currentPage);
        } catch (err: any) {
            if (err.response) {
                const mess = Array.isArray(err.response.data.errors)
                    ? err.response.data.errors[0]
                    : err.response.data.errors;
                dispatch(
                    openToast({
                        message: mess,
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            } else
                dispatch(
                    openToast({
                        message: t("message.duplicate.fail"),
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
        }
    };

    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header">
                    <div>
                        <h3>{t("page.plan.plans")}</h3>
                        <p style={{ marginBottom: 0 }}>
                            {totalPlan} {t("page._total")}
                        </p>
                    </div>
                    <div className="btn-add-plan">
                        <StyledTableButtonTop
                            type="danger"
                            size="default"
                            onClick={createNew}
                            className="add-btn "
                        >
                            {t("page.plan.add_plan")}
                        </StyledTableButtonTop>
                    </div>
                </div>
                <div className="table">
                    <ScrollContainer>
                        <div className="table_ground">
                            <div className="table-body">
                                <div className="table-header">
                                    <Row
                                        gutter={24}
                                        align="middle"
                                        type="flex"
                                        className="header-row"
                                    >
                                        <Col className="col-item" span={8}>
                                            <p>{t("page.plan.plan_name")}</p>
                                        </Col>
                                        <Col className="col-item" span={4}>
                                            <p>{t("page.plan.merchants")}</p>
                                        </Col>
                                        <Col className="col-item" span={4}>
                                            <p>{t("page.plan.create_date")}</p>
                                        </Col>
                                        <Col className="col-item" span={4}>
                                            <p>{t("page.plan.status")}</p>
                                        </Col>
                                        <Col span={4}></Col>
                                    </Row>
                                </div>
                                {planList?.length > 0 ? (
                                    <div className="data-table">
                                        {planList.map((item, index) => (
                                            <ComponentRowPlan
                                                key={index}
                                                data={item}
                                                index={index}
                                                handleDelete={deletePlan}
                                                handleDuplicate={duplicatePlan}
                                            />
                                        ))}

                                        <div style={{ width: "100%", height: 10 }}></div>
                                    </div>
                                ) : (
                                    !loading && <ComponentEmpty />
                                )}
                            </div>
                        </div>
                    </ScrollContainer>
                </div>
                <div className="page-bottom">
                    {totalPlan > 0 && (
                        <div className="pagination">
                            <Pagination
                                current={currentPage}
                                defaultCurrent={1}
                                pageSize={PAGINATION}
                                total={totalPlan}
                                onChange={handlePagination}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};
