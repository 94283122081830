import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { BarChart } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { UserOutlined } from "@ant-design/icons";

import { StyledHeader } from "@components";
// import notifications from "src/demos/mock/notifications";
import { logout, selectApp, selectAuth, setMobileDrawer, useAppSelector } from "@redux";

const { Header } = Layout;

export const ComponentHeader = () => {
    //hook
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //redux state
    const app = useAppSelector(selectApp);
    const email = useSelector(selectAuth)?.userInfo?.email;

    const handleLogout = () => {
        dispatch(logout());
    };

    return (
        <StyledHeader>
            <Header>
                {app.mobile && (
                    <a
                        onClick={() => dispatch(setMobileDrawer(!app.mobileDrawer))}
                        className="trigger"
                    >
                        <BarChart size={20} strokeWidth={1} />
                    </a>
                )}
                <Link to="/" className="brand">
                    {/* <a className="brand"> */}
                    {/* <Triangle size={24} strokeWidth={1} /> */}
                    <strong className="mx-1 ">{t("page.navigationBar.super_admin_panel")}</strong>
                    {/* </a> */}
                </Link>

                <Menu mode="horizontal" className="menu-divider">
                    {!app.mobile && (
                        <Menu.Item>
                            <Link to="/" className="menu-item active">
                                {/* <a className="menu-item active"> */}
                                {t("page.navigationBar.home")}
                                {/* </a> */}
                            </Link>
                        </Menu.Item>
                    )}
                </Menu>

                <span className="mr-auto" />

                <Menu mode="horizontal">
                    {/* {!app.mobile && (
                        <Menu.Item onClick={() => dispatch(setFullscreen())}>
                            {!app.fullscreen ? (
                                <Maximize size={20} strokeWidth={1} />
                            ) : (
                                <Minimize size={20} strokeWidth={1} />
                            )}
                        </Menu.Item>
                    )} */}

                    {/* <Menu.Item
                        onClick={() => {
                            // dispatch(setOptionDrawer(!app.optionDrawer));
                        }}
                    >
                        <Settings size={20} color="white" strokeWidth={1} />
                    </Menu.Item> */}
                    {/* <Menu.SubMenu
                        title={
                            <Badge count={5}>
                                <span className="submenu-title-wrapper">
                                    <Bell size={20} strokeWidth={1} />
                                </span>
                            </Badge>
                        }
                    >
                        <Menu.Item className="p-0 bg-transparent" style={{ height: "auto" }}>
                            <List
                                className="header-notifications"
                                itemLayout="horizontal"
                                dataSource={notifications}
                                footer={<div>5 Notifications</div>}
                                renderItem={(item) => (
                                    <StyledNotification>
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={item.avatar}
                                                title={<a href="javascript:;">{item.title}</a>}
                                                description={<small>{item.description}</small>}
                                            />
                                        </List.Item>
                                    </StyledNotification>
                                )}
                            />
                        </Menu.Item>
                    </Menu.SubMenu> */}

                    <Menu.SubMenu
                        title={
                            // <Avatar src="https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg" />
                            <UserOutlined style={{ color: "white", fontSize: 20 }} />
                        }
                    >
                        {/* <Menu.Item>Settings</Menu.Item>
                        <Menu.Item>Profile</Menu.Item>
                        <Menu.Item>Notifications</Menu.Item>
                        <Menu.Divider />
                        <Menu.Item>
                            <Link to="https://one-readme.fusepx.com">
                                <a>Help?</a>
                            </Link>
                        </Menu.Item> */}
                        <Menu.Item
                            style={{ fontWeight: 500, cursor: "inherit", color: "black" }}
                            key={0}
                        >
                            {email}
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item
                            key={1}
                            onClick={handleLogout}
                            style={{ fontWeight: 500, color: "#f4394f" }}
                        >
                            {t("page.navigationBar.logout")}
                        </Menu.Item>
                    </Menu.SubMenu>
                </Menu>
            </Header>
        </StyledHeader>
    );
};
