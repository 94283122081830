export default {
    _copy: "คัดลอก",
    _copied: "คัดลอกสำเร็จ",
    _total: "ทั้งหมด",
    integrations: "Integrations",
    _merchant: "ร้านค้า",
    _plan: "Plan",
    _user: "ผู้ใช้งาน",
    welcome: "ยินดีต้อนรับ",
    welcome_to_Admin: "ยินดีต้อนรับสู่ Rocket Loyalty Hub",
    login: "ลงชื่อเข้าใช้",
    forgot: "ลืมรหัสผ่าน",
    password: "รหัสผ่าน",
    remember: "จดจำฉัน",
    create_account: "สร้างบัญชีผู้ใช้งาน",
    rocket_crm: "ยินดีต้อนรับสู่ Rocket Loyalty Hub",
    signing: "คุณกำลังใช้งานภายใต้ชื่อ ",
    what_is_your_name: "กรุณากรอกชื่อของคุณ",
    email: "อีเมล",
    confirm_password: "ยืนยันรหัสผ่าน",
    continue: "ถัดไป",
    instead: "หรือ",
    recover_password: "รับรหัสผ่านใหม่",
    email_recover_link: "ลิงค์สำหรับรับรหัสผ่านใหม่",
    sign_up: "สมัครสมาชิก",
    log_in: "ลงชื่อเข้าใช้",
    reset_password: "รีเซ็ตรหัสผ่าน",
    new_password: "สร้างรหัสผ่านใหม่",
    new_confirm_password: "ยืนยันรหัสผ่านใหม่",
    basic_info: "ข้อมูลของร้านค้า",
    merchant_plan_settings: "ตั้งค่า Plan",
    is_on: "กำลังเปิดใช้งาน",
    change_plan: "เปลี่ยน Plan",
    plans: "Plans",
    detail_plan: "รายละเอียด Plan",
    update_plan: "อัปเดท Plan",
    create_new_pan: "สร้าง Plan",
    basic_setting: "ตั้งค่าทั่วไป",
    plan_feature: "ฟีเจอร์ Plan",
    plan_name: "ชื่อ Plan",
    plan_pricing: "ราคา Plan (฿ / month)",
    activate_trial: "เปิดระบบทดลองใช้งาน",
    trial_period: "ระยะเวลาทดลองใช้งาน",
    loyalty: "Loyalty",
    create_rewards: "สร้าง Rewards",
    reward_limit: "จำนวน Reward ไม่เกิน",
    create_coupons: "สร้าง Coupon",
    coupon_limit: "จำนวน Coupon ไม่เกิน",
    create_benefits: "สร้าง Benefits",
    benefit_limit: "จำนวน Benefit ไม่เกิน",
    create_free_points: "สร้าง Free Points",
    member_ship_tiering: "สร้าง Membership Tiers",
    multi_user_signup: "อนุญาตให้ผู้ใช้งานสมัครสมาชิกได้หลายช่องทาง",
    contacts_limit_onoff: "จำกัดจำนวน Contacts",
    contacts_limit: "จำนวน Contact ไม่เกิน",
    membership_limit_onoff: "จำกัดจำนวนสมาชิก",
    membership_limit: "จำนวนสมาชิกไม่เกิน",
    segmentation: "Segmentation",
    other: "อื่นๆ",
    others: "อื่นๆ",
    custom_sign_up_form_fields: "ปรับแต่ง Form สมัครสมาชิก",
    welcome_screen: "หน้า Welcome",
    contact: "Contact",
    create_traffic_source: "สร้าง Traffic Source",
    multiple_roles: "จำกัดจำนวน Roles",
    admin_limit: "จำกัดจำนวน Admin",
    multi_LINEOA_connect: "เชื่อมต่อ LINE OA ได้มากกว่า 1 Account",
    LINEOA_accout_limit: "จำนวน LINEOA Account ที่จำกัด",
    active: "เปิดใช้งาน",
    text_verify_email: "กรุณายืนยันอีเมลของคุณ",
    desc_verify_email:
        "กรุณาตรวจสอบกล่องข้อความอีเมลคุณ เพื่อรับอีเมลยืนยัน. ถ้าคุณยังไม่ได้รับอีเมลยืนยัน",
    resend_email: "กดเพื่อส่งอีเมลยืนยันอีกครั้ง",
    add_plan: "เพิ่ม Plan",
    enter_plan_name: "กรุณากรอกชื่อ Plan",
    enter_plan_pricing: "กรุณาใส่ราคา Plan",
    create_new_plan: "สร้าง Plan",
    edit_merchant_home: "แก้ไข Homepage ร้านค้า",
    add_box: "เพิ่ม Navgiation Box",
    detail_navigation_box: "รายละเอียด Navigation Box",
    title: "ชื่อ",
    text: "ข้อความ",
    title_th: "ชื่อ(ไทย)",
    text_th: "ข้อความ(ไทย)",
    link: "ลิงค์",
    link_url: "ลิงค์ปลายทาง",
    drop_down_option: "ตัวเลือกแบบ Dropdown ",
    drop_down_name: "ชื่อ Dropdown",
    drop_down_link: "ลิงค์ Dropdown",
    set_icon: "เลือก Icon",
    save: "บันทึก",
    cancel: "ยกเลิก",
    manage_permissions: "จัดการสิทธิ์การใช้งาน",
    text_permission_sub_title: "เพิ่มสมาชิกใหม่และจัดการสิทธิ์การใช้งานได้ที่นี่",
    permission_types: "ประเภทสิทธิ์การใช้งาน",
    add_member: "เพิ่มสมาชิก",
    view_merchants: "ดูร้านค้า",
    edit_merchant_homepage: "แก้ไข Homepage ร้านค้า",
    create_plans_and_edit_merchant_plans: "สร้าง Plans และ แก้ไข Plan ของร้านค้า ",
    manage_roles: "จัดการ Roles",
    grant_members_permissions_via_direct_URL_links: "ให้สิทธิ์สมาชิกผ่านลิงค์นี้",
    edit_members_permissions_via_direct_URL_links: "แก้ไขสิทธิ์ของสมาชิกผ่านลิงค์นี้",
    delete_this_account_member: "ลบสมาชิก",
    admin: "Admin",
    owner: "Owner",
    editor: "Editor",
    choose_permission: "เลือกสิทธิ์การใช้งาน",
    edit_permissions_granted_to_this_account_member: "แก้ไขสิทธิ์การใช้งานสมาชิกบัญชีนี้",
    close: "ปิด",
    LINEOA_account_limit: "จำกัดจำนวน LINEOA Account",
    data_not_found: "ไม่พบข้อมูล",
    null: "ว่าง",
    filed_required: "ช่องนี้จำเป็นต้องกรอก!",
    email_invalid: "อีเมลไม่ถูกต้อง",
    true: "ถูกต้อง",
    false: "ไม่ถูกต้อง",
    box_theme_color: "ธีมสีของ Box",
    box_icon: "ไอคอนของ Box",
    poster_image: "ภาพโปรเตอร์",
    add_navigation_box: "เพิ่ม Navigation Box",
    edit_navigation_box: "แก้ไข Navigation Box",
    add_poster: "เพิ่ม Poster",
    edit_poster: "แก้ไข Poster",
    add_successfully: "เพิ่มสำเร็จ",
    add_failed: "เพิ่มไม่สำเร็จ",
    edit_successfully: "แก้ไขสำเร็จ",
    edit_failed: "แก้ไขไม่สำเร็จ",
    how_to_wan_delete: "คุณต้องการจะลบหรือไม่?",
    failed: "ผิดพลาด",
    success: "สำเร็จ",
    resend: "ส่งอีกครั้ง",
    theme_color: "ธีมสี",
    tip_loading: "กำลังโหลด...",
    image_svg: "รูปต้องเป็นสกุลไฟล์ .svg เท่านั้น",
    image_png_jpg: "รูปต้องเป็นสกุลไฟล์ .png, .jpeg, .jpg เท่านั้น",
    return_login: "กลับไปยังหน้าลงชื่อเข้าใช้งาน",
    svg_50_50: "(Svg-50x50 pixels)",
    drop_name: "ชื่อ Dropdown",
    drop_link: "ลิงค์ Dropdown",
    action: {
        create: "สร้าง",
        edit: "แก้ไข",
        delete: "ลบ",
        cancel: "ยกเลิก",
        _create: "สร้าง",
        _edit: "แก้ไข",
        _delete: "ลบ",
        duplicate: "คัดลอก",
        save: "บันทึก",
        close: "ปิด",
        yes: "ใช่",
    },
    merchant: {
        merchants: "ร้านค้า",
        segment_name: "ชื่อ Segment",
        contacts: "Contacts",
        create_date: "วันที่สมัคร",
        update_date: "Update date",
        plan: "Plan",
        search_name: "ค้นหาชื่อ",
        edit_plan: "แก้ไข Plan",
        basic_info: "ข้อมูลทั่วไป",
        integrations: "Integrations",
        members: "สมาชิก",
        merchant_plan_settings: "ตั้งค่า Plan ร้านค้า",
        is_on: "เปิดใช้งาน",
        change_plan: "เปลี่ยน Plan",
        shop_name: "ชื่อร้านค้า",
        total_sale: "ยอดขายทั้งหมด",
        total_point_assign: "Point ที่ให้ทั้งหมด",
        reward_active: "เปิดใช้งาน Reward",
        coupon_active: "เปิดใช้งาน Coupon",
        benefit_active: "เปิดใช้งาน Benefit",
        reward_redeem: "แลก Reward",
        coupon_redeem: "แลก Coupon",
        benefit_redeem: "แลก Benefit",
        link_one: "เชื่อมต่อ LINEOA Account 1",
        link_two: "เชื่อมต่อ LINEOA Account 2",
        link_three: "เชื่อมต่อ LINEOA account 3",
        last_active_date: "วันที่ออนไลน์ล่าสุด",
        integration: "Integration",
        form_information: "Form information",
        sale_notes: "Sales note",
        booking_field: "Booking fields",
        display_name: "Display Name",
        field_name: "Field Name",
        example_text_field: "Ex. Display name is Car Color. Field name should be is Car_color",
        number_of_employees: "Number of employees",
        experience_with_loyalty_software: "Experience with loyalty software?",
        admin_contact: "Admin contact",
        admin: "Admin",
        company_name: "Company Name",
        business_industry: "Business Industry",
        telephone_number: "Telephone Number",
        member_link: "ลิงค์สมาชิก",
        merchant_global_setting: "Merchant global settings",
        loyalty_advanced_features: "Loyalty Advanced Features",
        collect_point_method: "Collect points method",
        upload: "Upload"
    },
    plan: {
        plans: "Plans",
        plan_name: "ชื่อ Plan",
        merchants: "ร้านค้า",
        create_date: "วันที่สมัคร",
        status: "สถานะ",
        add_plan: "เพิ่ม Plan",
        basic_setting: "ตั้งค่าทั่วไป",
        enter_plan_name: "กรอกชื่อ Plan",
        plan_pricing: "ราคา Plan (฿ / เดือน)",
        enter_plan_pricing: "กรอกราคา Plan",
        activate_trial: "เปิดใช้ทดลองการใช้งาน",
        create_rewards: "สร้าง Rewards",
        trial_period: "ระยะเวลาการทดลองใช้งาน (จำนวนวัน)",
        reward_limit: "จำนวน Rewards ที่จำกัด ",
        create_coupon: "สร้าง Coupon",
        coupons_limit: "จำนวน Coupons ที่จำกัด",
        create_benefit: "สร้าง Benefit",
        benefits_limit: "จำนวน Benefits ที่จำกัด",
        create_free_points: "สร้าง Free Points",
        membership_tiering: "สร้าง Membership Tiers",
        multi_user_signup: "อนุญาตให้ผู้ใช้งานสมัครสมาชิกได้หลายช่องทาง",
        contact: "Contact",
        contact_limit_onoff: "จำกัดจำนวน Contact",
        contacts_limit: "จำนวน Contacts ที่จำกัด",
        membership_limit_onoff: "จำกัดจำนวน Membership",
        membership_limit: "จำนวน Membership ที่จำกัด",
        segmentation: "Segmentation",
        others: "อื่นๆ",
        custom_sign_up_form_fields: "ปรับแต่งฟอร์มสมัครสมาชิก",
        welcome_screen: "หน้า Welcome",
        create_traffic_source: "สร้าง Traffic source",
        multi_roles: "จำกัดจำนวน Roles",
        admin_limit: "จำกัดจำนวน Admin",
        multiple_LINEOA_connect: "เชื่อมต่อ LINE OA ได้มากกว่า 1 Account",
        LINEOA_account_limit: "จำนวน Account ที่จำกัด",
        create_benefits: "สร้าง Benefits",
        create_plan: "สร้าง Plan",
        plan_detail: "รายละเอียด Plan",
        update_plan: "อัปเดท Plan",
        default: "ค่าเริ่มต้น",
        plan_feature: "ฟีเจอร์ Plan",
        loyalty: "Loyalty",
        active: "เปิดใช้งาน",
        inactive: "ปิดการใช้งาน",
        transaction_limit: "จำนวนธุรกรรมสูงสุด",
    },
    permission: {
        manage_permissions: "จัดการสิทธิ์การใช้งาน",
        text_permission_sub_title: "เพิ่มสมาชิกใหม่และจัดการสิทธิ์การใช้งานได้ที่นี่",
        permission_types: "ประเภทสิทธิ์การใช้งาน",
        add_member: "เพิ่มสมาชิก",
        choose_permission: "เลือกสิทธิ์การใช้งาน",
        edit_permissions_granted_to_this_account_member: "แก้ไขสิทธิ์ของสมาชิกผ่านลิงค์นี้",
        delete_this_account_member: "ลบสมาชิก",
        grant_members_permissions_via_direct_URL_links: "ให้สิทธิ์สมาชิกผ่านลิงค์นี้",
        choose_permission_and_copy_link: "เลือกสิทธิ์การใช้งานและคัดลอกลิงค์",
    },
    validation: {
        required: "{{name}} กรุณากรอกข้อมูลดังนี้",
        min: "{{name}} จำเป็นต้องมีจำนวนอย่างน้อย {{number}} ",
        max: "{{name}} จำเป็นต้องมีจำนวนไม่เกิน {{number}} ",
        invalid_type: "ประเภทข้อมูลไม่ถูกต้อง",
        invalid_number: "{{name}} จำเป็นต้องกรอกและต้องเป็นตัวเลขเท่านั้น",
    },
    auth: {
        login: "ลงชื่อเข้าใช้งาน",
        email_password_correct: "อีเมลและรหัสผ่านไม่ถูกต้อง",
        login_successfully: "ลงชื่อเข้าใช้งานสำเร็จ",
        login_failed: "ลงชื่อเข้าใช้งานไม่สำเร็จ",
        reset_password_successfully: "รีเซ็ทรหัสผ่านสำเร็จ",
        reset_password_failed: "รีเซ็ทรหัสผ่านไม่สำเร็จ",
        password_must_match: "รหัสผ่านไม่ตรงกัน กรุณากรอกใหม่",
        you_are_signing: "คุณกำลังลงชื่อเข้าใช้งาน",
        full_name: "ชื่อจริง-นามสกุล",
        password: "รหัสผ่าน",
        name: "ชื่อ",
    },
    navigationBar: {
        super_admin_panel: "Super Admin Panel",
        home: "หน้าหลัก",
        report: "รายงาน",
        logout: "ลงชื่อออก",
    },
    posFeature: "POS Feature",
    numberOfStore: "Number Of Store",
    serviceFeature: "Service Feature",
    createService: "Create Service",
    createStaff: "Create Staff",
    createCard: "Create Card",
    appointment: "Appointment",
    marketingAutomationFeature: "Marketing Automation Feature",
    inbox: "inbox",
    journeyBuilder: "Journey Builder",
    lineOaMessaging: "Line OA Messaging",
    reportFeature: "Report Feature",
    overviewLoyalty: "Overview Loyalty",
    summaryDashboard: "Summary Dashboard",
    analyticsPOS: "Analytics POS",
    customerAnalytics: "Customer Analytics",
};
