interface Iprops {
    color?: string;
}
export const IconDrag = (props: Iprops) => {
    return (
        <svg
            width="12"
            height="21"
            viewBox="0 0 12 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 0H1C0.447715 0 0 0.447715 0 1V4C0 4.55228 0.447715 5 1 5H4C4.55228 5 5 4.55228 5 4V1C5 0.447715 4.55228 0 4 0Z"
                fill={props.color || "rgba(0, 0, 0, 0.65)"}
            />
            <path
                d="M11 0H8C7.44772 0 7 0.447715 7 1V4C7 4.55228 7.44772 5 8 5H11C11.5523 5 12 4.55228 12 4V1C12 0.447715 11.5523 0 11 0Z"
                fill={props.color || "rgba(0, 0, 0, 0.65)"}
            />
            <path
                d="M4 8H1C0.447715 8 0 8.44772 0 9V12C0 12.5523 0.447715 13 1 13H4C4.55228 13 5 12.5523 5 12V9C5 8.44772 4.55228 8 4 8Z"
                fill={props.color || "rgba(0, 0, 0, 0.65)"}
            />
            <path
                d="M11 8H8C7.44772 8 7 8.44772 7 9V12C7 12.5523 7.44772 13 8 13H11C11.5523 13 12 12.5523 12 12V9C12 8.44772 11.5523 8 11 8Z"
                fill={props.color || "rgba(0, 0, 0, 0.65)"}
            />
            <path
                d="M11 16H8C7.44772 16 7 16.4477 7 17V20C7 20.5523 7.44772 21 8 21H11C11.5523 21 12 20.5523 12 20V17C12 16.4477 11.5523 16 11 16Z"
                fill={props.color || "rgba(0, 0, 0, 0.65)"}
            />
            <path
                d="M4 16H1C0.447715 16 0 16.4477 0 17V20C0 20.5523 0.447715 21 1 21H4C4.55228 21 5 20.5523 5 20V17C5 16.4477 4.55228 16 4 16Z"
                fill={props.color || "rgba(0, 0, 0, 0.65)"}
            />
        </svg>
    );
};
