interface IProps {
    color?: string;
}
export const IconDelete = (props: IProps) => {
    const { color = "white" } = props;
    return (
        <>
            <svg
                width="17"
                height="18"
                viewBox="0 0 21 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M19.626 3.90906H14.384V1.28806C14.384 1.11434 14.315 0.947738 14.1922 0.824902C14.0693 0.702065 13.9027 0.633057 13.729 0.633057H7.177C7.09099 0.633057 7.00581 0.649999 6.92634 0.682916C6.84687 0.715832 6.77467 0.764079 6.71384 0.824902C6.65302 0.885724 6.60477 0.957931 6.57186 1.0374C6.53894 1.11687 6.522 1.20204 6.522 1.28806V3.90906H1.28C1.10628 3.90906 0.939681 3.97807 0.816845 4.1009C0.694009 4.22374 0.625 4.39034 0.625 4.56406C0.625 4.73777 0.694009 4.90438 0.816845 5.02721C0.939681 5.15005 1.10628 5.21906 1.28 5.21906H19.626C19.7997 5.21906 19.9663 5.15005 20.0892 5.02721C20.212 4.90438 20.281 4.73777 20.281 4.56406C20.281 4.39034 20.212 4.22374 20.0892 4.1009C19.9663 3.97807 19.7997 3.90906 19.626 3.90906ZM7.832 1.94306H13.074V3.90906H7.832V1.94306Z"
                    fill={color}
                />
                <path
                    d="M2.59082 6.53003V18.979C2.59082 19.6742 2.86696 20.3408 3.35849 20.8324C3.85002 21.3239 4.51669 21.6 5.21182 21.6H15.6948C16.3899 21.6 17.0566 21.3239 17.5481 20.8324C18.0397 20.3408 18.3158 19.6742 18.3158 18.979V6.53003H2.59082ZM14.0008 16.686L13.0748 17.613L10.4538 14.992L7.83282 17.613L6.90682 16.686L9.52782 14.065L6.90682 11.444L7.83282 10.518L10.4538 13.139L13.0748 10.518L14.0008 11.444L11.3798 14.065L14.0008 16.686Z"
                    fill={color}
                />
            </svg>
        </>
    );
};
