import { useLocation } from "react-router";

import { StyledContentAuth, StyledAuth } from "@components";
import { PATH_VERIFY_EMAIL } from "@configs";

interface IAppModuleLayout {
    children?: string | JSX.Element | JSX.Element[];
}

export const LayoutAuth = (props: IAppModuleLayout) => {
    //hook
    const { pathname } = useLocation();
    return (
        <StyledAuth>
            <StyledContentAuth isVerifyEmail={pathname.includes(PATH_VERIFY_EMAIL)}>
                {!pathname.includes(PATH_VERIFY_EMAIL) && (
                    <img className="bg-image__logo" src="/images/auth/logo.png" />
                )}
                {props.children}
            </StyledContentAuth>
        </StyledAuth>
    );
};
