import { IPagination } from "./pagination";
export interface IMerchantGeneral {
    _id: string;
    name: string;
    contacts: number;
    members: number;
    planName: string;
    createdAt: string;
    updatedAt: string;
    lastActiveDate?: string;
}

export interface IMerchantDetail {
    _id: string;
    basicInfo: IBasicInfo;
    businessInfo: IBusinessInfo;
    lineIntegration?: ILineIntegration;
    data: {
        activeBenefits: number;
        activeCoupons: number;
        activeRewards: number;
    };
    additionalFields: [];
    welcomeDescription: string;
    welcomeImageUrl: string;

    fbPixel: string;
    googleAnalytics: string;
    logoUrl: string;

    name: string;
    planName: string;
    planId: string;
    contacts: number;
    members: number;
    lastActivityDate: string;

    totalSales?: number;
    totalPoints?: number;
    activeRewards?: number;
    activeCoupons?: number;
    activeBenefits?: number;
    redeemedBenefits?: number;
    redeemedCoupons?: number;
    redeemedRewards?: number;

    linkedAccount?: { name: string; lineNumber: string }[];
    createdBy: string;
    createdAt: string;
    updatedAt: string;

    expLoyaltyHub: string;
    adminUsers: IAdminInfo[];
    businessName: string;
    businessTel: string;
    businessTypeName: string;
    staffAmount: string;
    superAdminNote?: string;
    superAdminTier?: {
                         isPointBalance:  boolean;
                         isPointEarn:  boolean;
                         isTier: boolean ;
                         isSellerTier: boolean ;
                         uploadEvidenceDisplay: string;
                         uploadUnitDisplay:string;
                         isFullName: boolean;
                         isComponentRound: boolean;
                         isComponentSharp: boolean;
                         isTierDisplay: boolean;
                        _id:  string; 
                        isTierByPoint:boolean
                        isTierBySale:boolean
                    };
   superAdminAdvanceFeature?: {
                        isCard: boolean,
                        isCorporateUi: boolean, 
                        isFriendInviteFriend: boolean, 
                        isMemberType: boolean,
                        isSurvey: boolean,
                        isSuspiciousActivity: boolean,
                        isUploadReceipt:boolean,
                       _id?:  string; 
                   };
    superAdminCollectPointFeature?: {
                        isEcommerceClaimPoints: boolean,
                        isUploadReceiptPoint: boolean,
                        _id?:  string;      
                    };
                    membershipTiers: IMembershipTiers[]
}
export interface IAdminInfo {
    email: string;
    fullName: string;
    _id: string;
}
export interface ILineIntegration {
    lineLoginApi: ILineLoginApi[];
    lineMessaging: ILineMessaging[];
}

export interface ILineMessaging {
    accessToken: string;
}
export interface ILineLoginApi {
    channelID: string;
    channelSecret: string;
    getEmail: boolean;
    liffUrl: string;
}

export interface IBusinessInfo {
    activeBenefits: number;
    activeCoupons: number;
    activeRewards: number;
    benefitsRedeemed: number;
    couponsRedeemed: number;
    rewardsRedeemed: number;
    totalPointsAssigned: number;
    totalSales: number;
}
export interface IBasicInfo {
    contacts: number;
    lastActiveDate: string;
    members: number;
    planName: string;
    shopName: number;
}
export interface IMerchantList extends IPagination {
    name?: string;
    sortBy?: enumSortBy;
    sortField?: string;
}
export interface IMerchantInfo extends IPagination {
    merchantId?: string;
}
export enum enumNotification {
    NEW_SIGNUP = "NEW_SIGNUP",
    POINT_COLLECTION = "POINT_COLLECTION",
    REWARD_UPDATE = "REWARD_UPDATE",
    REWARD_REDEEM = "REWARD_REDEEM",
    BENEFIT_REDEEM = "BENEFIT_REDEEM",
    COUPON_UPDATE = "COUPON_UPDATE",
    COUPON_COLLECT = "COUPON_COLLECT",
    COUPON_USE = "COUPON_USE",
    MEMBERSHIP_CHANGE = "MEMBERSHIP_CHANGE",
    SALE = "SALE",
    TEST = "TEST",
}
export enum IStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
}
export interface IMerchantAdmin {
    _id: string;
    roleId: string;
    email: string;
    notifSetting: {
        channels: {
            lineChat: false;
            email: false;
            adminPanel: true;
        };
        events: enumNotification[];
        _id: string;
    };
    userType: string;
    status: IStatus;
    verifyEmail: null;
    trafficSourceIds: [];
    isVerified: true;
    isSocial: false;
    phoneVerified: false;
    emailVerified: false;
    surveyQuestions: [];
    createdAt: string;
    updatedAt: string;
    __v: number;
    fullName: string;
    hashPassword: string;
    merchantId: string;
    lastActive: string;
    id: string;
    roleName: string;
}
export interface IMerchantNote {
    merchantId: string;
    note: string;
}
export enum enumSortBy {
    DESC = "DESC",
    ASC = "ASC",
}
export enum enumSortFields {
    CREATEDAT = "createdAt",
    NAME = "name",
    MEMBERS = "members",
    CONTACTS = "contacts",
    PLANNAME = "planName",
    UPDATEDAT = "updatedAt",
}
export interface IDefaultSortIcon {
    searchParams: IMerchantList;
    sortField: enumSortFields;
    sortBy: enumSortBy;
    handleSort: (sortField: enumSortFields, sortBy: enumSortBy) => void;
}

export interface IMembershipTiers {
        pointThreshold: number;
        membershipName: string;
        discountInBaht: number;
        discountUsePoints: number;
        bahtSpent: number;
        tierSpent: [
            {
                Attaintier: number;
                Maintaintier: number;
                AttaintierMonth: number;
                MaintaintierMonth: number;
            }
        ];
        points: number;
        status: boolean;
        color: string;
        icon: string;
        isDefault: boolean;
        benefits: [
            {
                benefitTitle: string;
                benefitDescription: string;
                benefitIcon: string;
            }
        ];
        benefitCardImage: string;
        benefitBackCardImage:string ;
        _id: string;
}
