import styled from "styled-components";
import { Button, Radio, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { useEffect, useState } from "react";

import { StyledCard, StyledPageDetail } from "@components";
import { ModuleButtonAction } from "@modules";
import { merchantAPI, planAPI } from "@api";
import { IMerchantDetail } from "@interfaces";
import { PATH_MERCHANTS } from "@configs";
import { openToast, setLoading, useAppDispatch } from "@redux";

interface IDataPlan {
    _id: string;
    name: string;
    status: string;
}

export const PageMerchantUpdate = () => {
    //page hook
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();
    //page state
    const [listPlan, setListPlan] = useState<IDataPlan[]>([{ _id: "", name: "", status: "" }]);
    const [merchant, setMerchant] = useState<IMerchantDetail>();
    const [planId, setPlanId] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchData();
    }, []);

    const getListPlan = async () => {
        const response = await planAPI.getList({ page: 1, limit: 9999 });
        setListPlan(response.data.docs);
    };
    const getMerchant = async () => {
        try {
            const response = await merchantAPI.detail(id);
            setMerchant(response.data);
            setPlanId(response.data.planId);
            // setPlanName(response.data.planName);
        } catch (err: any) {
            if (err.response) {
                const mess = Array.isArray(err.response.data.errors)
                    ? err.response.data.errors[0]
                    : err.response.data.errors;
                dispatch(
                    openToast({
                        message: mess,
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            } else
                dispatch(
                    openToast({
                        message: t("message.not_found", {
                            returnObjects: true,
                            name: t("object._merchant"),
                        }),
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            history.push(PATH_MERCHANTS);
        }
    };
    const handleSubmit = async () => {
        dispatch(setLoading(true));
        await saveUpdate();
        dispatch(setLoading(false));
        history.push(PATH_MERCHANTS);
    };
    const saveUpdate = async () => {
        if (merchant?._id)
            try {
                const response = await merchantAPI.updatePlan({ planId: planId }, merchant?._id);
                if (response.status === 200 || response.statusText === "OK") {
                    dispatch(
                        openToast({
                            message: t("message.update.success", {
                                returnObjects: true,
                                name: t("object._plan"),
                            }),
                            type: "success",
                            autoHideDuration: 2000,
                        })
                    );
                } else {
                    dispatch(
                        openToast({
                            message: t("message.update.fail", {
                                returnObjects: true,
                                name: t("object._plan"),
                            }),
                            type: "error",
                            autoHideDuration: 2000,
                        })
                    );
                }
            } catch (err: any) {
                if (err.response) {
                    const mess = Array.isArray(err.response.data.errors)
                        ? err.response.data.errors[0]
                        : err.response.data.errors;
                    dispatch(
                        openToast({
                            message: mess,
                            type: "error",
                            autoHideDuration: 2000,
                        })
                    );
                } else
                    dispatch(
                        openToast({
                            message: t("message.update.fail"),
                            type: "error",
                            autoHideDuration: 2000,
                        })
                    );
            }
    };
    const fetchData = async () => {
        setIsLoading(true);
        await getMerchant();
        await getListPlan();
        setIsLoading(false);
    };

    return (
        <StyledPageDetail>
            <Container>
                <div className="page-header">
                    <h3>{t("page.merchant.merchant_plan_settings")}</h3>
                </div>

                <StyledCard>
                    <Skeleton loading={isLoading} active paragraph={{ rows: 8 }}>
                        <div className="container">
                            <p className="title">
                                {merchant?.name} {t("page.merchant.is_on")}
                            </p>
                            <Button className="recentMerchant" disabled>
                                {merchant?.basicInfo.planName || "---"}
                            </Button>
                            <p className="title">{t("page.merchant.change_plan")}</p>
                            <div>
                                <Radio.Group
                                    value={planId}
                                    onChange={(e) => setPlanId(e.target.value)}
                                >
                                    {listPlan.map((item, index) => {
                                        if (item.status === "ACTIVE")
                                            return (
                                                <Radio
                                                    style={radioStyle}
                                                    value={item._id}
                                                    key={index}
                                                >
                                                    {item.name}
                                                </Radio>
                                            );
                                    })}
                                </Radio.Group>
                            </div>
                        </div>
                    </Skeleton>
                </StyledCard>

                <ActionBtn>
                    <div className="btn-field">
                        <ModuleButtonAction
                            onClick={() => handleSubmit()}
                            className="submit-btn"
                            type="primary"
                            text={t("page.action.save")}
                        />
                        <ModuleButtonAction
                            className="cancel-btn"
                            onClick={() => history.goBack()}
                            type="default"
                            text={t("page.action.cancel")}
                        />
                    </div>
                </ActionBtn>
            </Container>
        </StyledPageDetail>
    );
};
const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
    color: "#6C7084",
    fontSize: "16px",
    fontWeigth: "600",
    padding: "0 0 10px 0px",
};
const Container = styled.div`
    .recentMerchant {
        margin-bottom: 2rem;
        padding: 6px 12px;
        height: auto;
        min-width: 80px;
        background-color: #f7f7f8;
    }
    .container {
        overflow: hidden;
    }
    .title {
        color: black;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 0.5rem;
    }
`;

const ActionBtn = styled.div`
    .btn-field {
        display: flex;
        button {
            width: 100px;
            margin-right: 14px;
            font-size: 16px;
            font-weight: 600;
            padding: 6px 0px;
            height: auto;
        }
    }
`;
