interface Iprops {
    color?: string;
}
export const IconArrowRight = (props: Iprops) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="73"
            height="74"
            viewBox="0 0 73 74"
            fill="none"
        >
            <path
                d="M67 51L57 45.2265V56.7735L67 51ZM7 52H58V50H7V52Z"
                fill={props.color || "#000"}
            />
        </svg>
    );
};
