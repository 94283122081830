import {
    PageForgotPassword,
    PageMerchantUpdate,
    PageCreatePassword,
    PageMerchantDetail,
    PageLogin,
    PageError404,
    PageMerchantList,
    PagePlanList,
    PagePlanDetail,
    PageSignUp,
    PageResetPassword,
    PageVerifyEmail,
    PageHomePageNavigation,
    PagePermissionList,
} from "@pages";
import { LayoutAuth, LayoutError } from "@layouts";
import { IRoute } from "@interfaces";

//WHAT: normal path
export const PATH_HOME = "/";
export const PATH_MERCHANTS = "/merchants";
export const PATH_HOME_NAVIGATION = "/homepage-navigation";
export const PATH_PERMISSIONS = "/permissions";
export const PATH_PLANS = "/plans";
//WHAT: auth path
export const PATH_LOGIN = "/login";
export const PATH_CREATE_PASSWORD = "/create-password";
export const PATH_FORGOT_PASSWORD = "/forgot-password";
export const PATH_SIGN_UP = "/sign-up";
export const PATH_RESET_PASSWORD = "/reset-password";
export const PATH_VERIFY_EMAIL = "/verify-email";
// WHAT: hidden path
export const PATH_ROLES = "/roles";
export const PATH_INVITE_LINKS = "invite-links";

// WHAT: permission
export const ADMIN_VIEW_MERCHANT = "admin_view_merchant";
export const ADMIN_EDIT_MERCHANT = "admin_edit_merchant";
export const ADMIN_UPDATE_PLAN = "admin_update_plan";
export const ADMIN_MANAGE_ROLE = "admin_manage_role";

export const routes: Array<IRoute> = [
    { path: PATH_HOME, component: PageMerchantList, exact: true },
    {
        path: PATH_HOME_NAVIGATION,
        component: PageHomePageNavigation,
        exact: true,
        permission: ADMIN_EDIT_MERCHANT,
    },
    {
        path: PATH_MERCHANTS,
        component: PageMerchantList,
        exact: true,
        permission: ADMIN_VIEW_MERCHANT,
    },
    { path: PATH_MERCHANTS + "/:id", component: PageMerchantDetail, exact: true },
    { path: PATH_MERCHANTS + "/:id/update", component: PageMerchantUpdate, exact: true },
    { path: PATH_PLANS, component: PagePlanList, exact: true, permission: ADMIN_UPDATE_PLAN },
    { path: PATH_PLANS + "/new", component: PagePlanDetail, exact: true },
    { path: PATH_PLANS + "/:id", component: PagePlanDetail, exact: true },
    { path: PATH_PLANS + "/:id/update", component: PagePlanDetail, exact: true },
    {
        path: PATH_PERMISSIONS,
        component: PagePermissionList,
        exact: true,
        permission: ADMIN_MANAGE_ROLE,
    },
    { path: PATH_LOGIN, component: PageLogin, exact: true, layout: LayoutAuth },
    {
        path: PATH_CREATE_PASSWORD + "/:id",
        component: PageCreatePassword,
        exact: true,
        layout: LayoutAuth,
    },
    { path: PATH_FORGOT_PASSWORD, component: PageForgotPassword, exact: true, layout: LayoutAuth },
    { path: PATH_SIGN_UP, component: PageSignUp, exact: true, layout: LayoutAuth },
    { path: PATH_SIGN_UP + "/:id", component: PageSignUp, exact: true, layout: LayoutAuth },
    {
        path: PATH_RESET_PASSWORD + "/:id",
        component: PageResetPassword,
        exact: true,
        layout: LayoutAuth,
    },
    {
        path: PATH_VERIFY_EMAIL + "/:email",
        component: PageVerifyEmail,
        exact: true,
        layout: LayoutAuth,
    },
    { component: PageError404, exact: false, layout: LayoutError },
];

export const normalRoutes = [
    PATH_HOME,
    PATH_MERCHANTS,
    PATH_HOME_NAVIGATION,
    PATH_PERMISSIONS,
    PATH_PLANS,
];

export const authRoutes = [
    PATH_LOGIN,
    PATH_CREATE_PASSWORD,
    PATH_FORGOT_PASSWORD,
    PATH_SIGN_UP,
    PATH_RESET_PASSWORD,
    PATH_VERIFY_EMAIL,
];
