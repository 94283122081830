import { Layout, Spin } from "antd";
import { useSelector } from "react-redux";

import {
    StyledContainer,
    ComponentHeader,
    ComponentSidebar,
    StyledPageInner,
    IconLoadingPage,
} from "@components";
import { selectApp } from "@redux";
import { ILayout } from "@interfaces";

export const LayoutApp = (props: ILayout) => {
    //hook

    //redux variable
    const loading = useSelector(selectApp).loading;

    // useEffect(() => {
    //     dispatch(setLoading(false));
    // }, []);

    return (
        <StyledContainer>
            {/* {!isNotDashboard && <ComponentHeader />} */}
            <ComponentHeader />
            <Layout className="workspace">
                {/* {!isNotDashboard && (
                        <ComponentSidebar
                            sidebarTheme={state.darkSidebar ? "dark" : "light"}
                            sidebarMode={state.sidebarPopup ? "vertical" : "inline"}
                            sidebarIcons={state.sidebarIcons}
                            collapsed={state.collapsed}
                        />
                    )} */}
                <ComponentSidebar
                    sidebarTheme={"light"}
                    sidebarMode={"inline"}
                    sidebarIcons={false}
                    collapsed={false}
                />

                <Layout className="app-layout">
                    <Layout.Content style={{ maxHeight: "90vh" /*overflowX: "auto" */ }}>
                        {/* {!isNotDashboard ? <Inner>{props.children}</Inner> : props.children} */}
                        <Spin
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                            }}
                            spinning={loading}
                            size="large"
                            indicator={<IconLoadingPage />}
                        >
                            <StyledPageInner>{props.children}</StyledPageInner>
                        </Spin>
                    </Layout.Content>
                </Layout>
            </Layout>
        </StyledContainer>
    );
};
