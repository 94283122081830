import styled from "styled-components";

export const StyledHomeNavigation = styled.div`
    width: 100%;

    [class~="ant-col"] {
        background: transparent;
        border: 0;
    }
    [class~="ant-col"] > div {
        height: 120px;
        font-size: 14px;
        line-height: 120px;
        background: #0092ff;
        border-radius: 4px;
    }
    pre {
        padding: 8px 16px;
        font-size: 13px;
        background: #f9f9f9;
        border-radius: 6px;
    }
    pre.demo-code {
        direction: ltr;
    }
    .ant-col {
        padding: 12px;
    }
    [data-theme="dark"] [class~="ant-col"] > div {
        background: #028ac8;
    }
    .header {
        display: flex;
        justify-content: space-between;
        padding: 1.5rem 0 14px 0;

        .header__title {
            font-style: normal;
            font-weight: bold;
            font-size: 35px;
            line-height: normal;
            color: #000000;
            @media screen and (max-width: ${(p) => p.theme.breakPoints.breakMobileMedium}) {
                font-size: 20px;
            }
        }
        .btn-add {
            font-size: 16px;
            color: #ffffff;
            height: 42px;
        }
    }
    .directional {
        margin-bottom: 18px;
        font-size: 24px;
        text-align: end;
        .anticon {
            cursor: pointer;
        }
        .arrow_left {
            margin-right: 12px;
        }
        .arrow_left,
        .arrow_right {
            display: inline-flex;
            justify-content: center;
            width: 26px;
            height: 26px;
            align-items: center;
            background-color: #ffffff;
            cursor: pointer;
            img {
                width: 7px;
                height: 7px;
                object-fit: cover;
            }
        }
    }

    @media (max-width: 767px) {
        padding: 0;
    }

    @media (min-width: ${(p) => p.theme.breakPoints.breakLarge}) {
        .ant-col-5 {
            width: 20% !important;
        }
    }
`;
