import styled from "styled-components";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ComponentColor } from "@components";
import { theme } from "@utils";
import { selectTheme, setMainColor } from "@redux";

interface IProps {
    toggle: boolean;
    handleToggle: () => void;
}

export const ComponentTheme = (props: IProps) => {
    //props
    const { toggle, handleToggle } = props;
    //page Hooks
    const { t } = useTranslation();
    const mainColor = useSelector(selectTheme).mainColor;
    const dispatch = useDispatch();
    //page variable
    const colorThemes = theme.colors.colorThemes;

    const handleChangeMainColor = (color?: string) => {
        if (color) {
            dispatch(setMainColor(color));
        }
    };

    return (
        <StyledTheme>
            <div className="theme">
                <div className="title">{t("page.theme_color")}</div>
                <div className="main__color">
                    <ComponentColor className="marginLeft_0" color={mainColor} />
                    <Button
                        type="primary"
                        onClick={handleToggle}
                        size="large"
                        className="btn--edit"
                    >
                        {toggle ? t("page.action.close") : t("page.action.edit")}
                    </Button>
                </div>
            </div>

            {toggle && (
                <div className="colorTheme">
                    {colorThemes.map((color) => {
                        return (
                            <ComponentColor color={color.main} onClick={handleChangeMainColor} />
                        );
                    })}
                </div>
            )}
        </StyledTheme>
    );
};

const StyledTheme = styled.div`
    .theme {
        .title {
            color: rgba(0, 0, 0, 0.85);
            font-weight: 600;
            line-height: 1.5rem;
        }
        .main__color {
            display: flex;
            align-items: flex-end;
            margin-top: 12px;
        }
    }
    .colorTheme {
        display: flex;
        flex-wrap: wrap;
        padding: 27px 23px;
        border-radius: 4px;
        background: #f7f7f8;
        margin-top: 22px;
    }
    .btn--edit {
        height: 33px;
        padding: 0 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        background-color: #ffffff;
        position: relative;
        top: -3px;
        color: #000000;
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
    }
    .marginLeft_0 {
        margin-left: 0 !important;
    }
`;
