import { Form, Spin } from "antd";
import { Formik, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useState } from "react";
import { useHistory, useParams } from "react-router";

import { IconLoadingPage, ShareButton, ShareInput, StyledUpdatePassword } from "@components";
import { ICreatePassword, INitCreatePassword } from "@interfaces";
import { useAppDispatch, openToast } from "@redux";
import { authApi } from "@api";
import { PATH_LOGIN } from "@configs";

const initialValue: INitCreatePassword = {
    oldPassword: "",
    newPassword: "",
};

export const PageResetPassword = () => {
    //page Hooks
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();
    //page state
    const [loading, setToggleLoading] = useState<boolean>(false);

    // page variable
    const updateSchema = Yup.object().shape({
        newPassword: Yup.string().required(
            t("validation.required", { returnObjects: true, name: t("page.new_password") })
        ),
        oldPassword: Yup.string()
            .required(
                t("validation.required", {
                    returnObjects: true,
                    name: t("page.new_confirm_password"),
                })
            )
            .oneOf([Yup.ref("newPassword"), null], t("page.auth.password_must_match")),
    });

    const href = window.location.href;
    const url = new URL(href);
    const email = url.searchParams.get("email");

    const handleResetPassword = async (
        values: INitCreatePassword,
        { setSubmitting }: FormikHelpers<INitCreatePassword>
    ) => {
        try {
            const valuesCreate: ICreatePassword = {
                resetPasswordId: id,
                password: values.newPassword,
            };
            setToggleLoading(true);
            const res = await authApi.resetPassword(valuesCreate);

            if (res.status === 200) {
                dispatch(
                    openToast({
                        message: t("message.reset_pass.success"),
                        type: "success",
                        autoHideDuration: 2000,
                    })
                );
                history.push(PATH_LOGIN);
            }
        } catch (error: any) {
            const { status } = error.response;
            if (status === 400) {
                dispatch(
                    openToast({
                        message: t("message.try_again_text"),
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            } else {
                dispatch(
                    openToast({
                        message: t("message.reset_pass.fail"),
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            }
        } finally {
            setSubmitting(false);
            setToggleLoading(false);
        }
    };

    return (
        <Spin
            spinning={loading}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
            }}
            size="large"
            indicator={<IconLoadingPage />}
        >
            <StyledUpdatePassword>
                <div className="title_welcome main-color">{t("page.reset_password")}</div>
                <div className="signing">
                    {t("signing")} {email}{" "}
                </div>

                <Formik
                    initialValues={initialValue}
                    validationSchema={updateSchema}
                    onSubmit={handleResetPassword}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        errors,
                        touched,
                        /* and other goodies */
                    }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Form.Item
                                    colon={false}
                                    label={t("page.new_password")}
                                    className="main-color"
                                >
                                    <ShareInput
                                        name="newPassword"
                                        style={{ width: "100%" }}
                                        onChange={handleChange}
                                        value={values.newPassword}
                                        onBlur={handleBlur}
                                        type="password"
                                        errors={errors.newPassword}
                                        touched={touched.newPassword}
                                    />
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    label={t("page.new_confirm_password")}
                                    className="main-color"
                                >
                                    <ShareInput
                                        name="oldPassword"
                                        style={{ width: "100%" }}
                                        onChange={handleChange}
                                        value={values.oldPassword}
                                        onBlur={handleBlur}
                                        type="password"
                                        errors={errors.oldPassword}
                                        touched={touched.oldPassword}
                                    />
                                </Form.Item>
                                <ShareButton
                                    type="primary"
                                    size="large"
                                    text={t("page.continue")}
                                    htmlType="submit"
                                    disable={isSubmitting}
                                />
                            </form>
                        );
                    }}
                </Formik>
            </StyledUpdatePassword>
        </Spin>
    );
};
