import styled from "styled-components";
export const StyledPageDetail = styled.div`
    padding: 2rem 3.5rem;
    display: flex;
    flex-direction: column;
    height: calc(100vh - ${(p) => p.theme.header.height});
    overflow: auto;

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    ::-webkit-scrollbar-track {
        display: none;
    }

    ::-webkit-scrollbar-thumb {
        background: ${(p) => p.theme.colors.fadedText};
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        cursor: grab;
    }
    h3 {
        margin-right: 44px;
        font-weight: 700;
        font-size: 35px;
        margin-bottom: 0;
    }
    Button {
        font-weight: 600;
        margin: 1rem 0.5rem 1rem 0;
    }
    .primary-btn {
        background-color: ${(p) => p.theme.colors.primary};
    }
    .page-header {
        padding: 1.5rem 0 14px 0;
    }
    //
    @media screen and (max-width: ${(p) => p.theme.breakPoints.breakMobileMedium}) {
        padding: 1.5rem;
        h3 {
            margin-right: 44px;
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 0;
        }

        ::-webkit-scrollbar {
            display: none;
        }
    }
    .add-box {
        margin: 0;
    }

    .top-skeleton {
        background-color: white;
        padding: 2.5rem 4rem;
        border-radius: 6px;
        max-width: 550px;
    }
    .loading-component {
        .skeleton {
            border-radius: 6px;
            background-color: white;
            margin: 2rem 0;
            padding: 2.5rem 4rem;
            max-width: 647px;
        }
    }
`;
