import { IPagination } from "@interfaces";

export const PAGINATION = 10;

export const COLUM_HOME_NAVIGATION = 4;
export const DEFAULT_LANGUAGE = process.env.REACT_APP_LANGUAGE || "th";

// thay đổi row bảng theo loại
export const TABLE_MERCHANTS = 1;
export const TABLE_PLANTS = 2;
export const TABLE_PERMISSIONS = 3;

export const STATUS_ACTIVE = "ACTIVE";
export const STATUS_UNACTIVE = "UNACTIVE";

// module quản lý plan : 1 -> detail, 2 -> update, 3 -> create
export const PLAN_TYPE_DETAIL = 1;
export const PLAN_TYPE_UPDATE = 2;
export const PLAN_TYPE_CREATE = 3;

// module button routing : 1 -> tạo mới | 2 -> lưu | 3 -> chỉnh sửa | 4 -> update | 5 -> xoá | 6 -> back
export const BUTTON_ACTION_CREATE = 1;
export const BUTTON_ACTION_SAVE = 2;
export const BUTTON_ACTION_EDIT = 3;
export const BUTTON_ACTION_UPDATE = 4;
export const BUTTON_ACTION_DELETE = 5;
export const BUTTON_ACTION_BACK = 6;

// input setting
export const MAX_LENGTH_INPUT = 255;
export const MIN_NUMBER = 0;
export const MAX_NUMBER = 99999999;

// role permissions
export const ROLE_OWNER = 1;
export const ROLE_ADMIN = 2;
export const ROLE_EDITOR = 3;

// modal permission type
export const MODAL_PERMISSION_INFO = 1;
export const MODAL_PERMISSION_ADD = 2;
export const MODAL_PERMISSION_EDIT = 3;

// permission title
export const PERMISSION_TITLE = [
    { title: "View merchants", type: "admin_view_merchant" },
    { title: "Edit merchant homepage", type: "admin_edit_merchant" },
    { title: "Create plans and edit merchant plans", type: "admin_update_plan" },
    { title: "Manage roles", type: "admin_manage_role" },
];

export const ADMINS_SITE = "rewarding-admin-site";

export enum enumValidation {
    MAX_LENGTH_INPUT = 255,
    MIN_NUMBER = 0,
    MAX_NUMBER = 99999999,
    MAX_PASSWORD = 32,
    MIN_PASSWORD = 6,
    MIN_PHONE_NUMBER = 9,
    MAX_PHONE_NUMBER = 15,
    MAX_LENGTH = 255,
}

export const INIT_PAGE: IPagination = { page: 1, limit: PAGINATION };
