import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { CSSProperties } from "styled-components";
import styled from "styled-components";

import { StyledButton } from "@components";

interface IProps {
    type: "default" | "ghost" | "primary" | "dashed" | "danger" | undefined;
    size?: "default" | "small" | "large" | undefined;
    text: string;
    className?: string;
    style?: CSSProperties | undefined;
    htmlType?: "button" | "reset" | "submit" | undefined;
    disable?: boolean;
    onClick?: () => void;
    icon?: any;
}

export const ShareButton = (props: IProps) => {
    const { t } = useTranslation();
    return (
        <StyledButton size={props.size || ""}>
            <Button
                htmlType={props.htmlType}
                type={props.type}
                size={props.size}
                className={props.className}
                style={{ ...props.style, padding: "12px" }}
                disabled={props.disable}
                onClick={props.onClick}
            >
                {t(props.text)}
            </Button>
        </StyledButton>
    );
};

export const SharedButtonDefault = (props: IProps) => {
    const { text, type, size, className, style, disable, onClick, icon } = props;
    return (
        <StyledContainer>
            <Button
                style={style}
                className={className}
                type={type || "default"}
                size={size || "default"}
                disabled={disable}
                onClick={onClick}
                icon={icon}
            >
                {text}
            </Button>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    button {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ant-btn-primary[disabled],
    .ant-btn-primary {
        color: white !important;
    }
`;
