import styled from "styled-components";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
interface IHeaderTable {
    title: string[];
}
export const ComponentTableHeader = (props: IHeaderTable) => {
    //page hook
    const { t } = useTranslation();
    // page variable
    const spanCol = Math.floor(24 / (props.title.length - 1));

    return (
        <StyledHeader>
            {props.title.length > 0 && (
                <Row gutter={16} className={"table-header"}>
                    <Col span={6}>
                        <p>{t(props.title[0])}</p>
                    </Col>
                    <Col span={14}>
                        <Row style={{ justifyContent: "center" }} gutter={8}>
                            {props.title.map(
                                (item, index) =>
                                    index > 0 && (
                                        <Col key={index} span={spanCol}>
                                            <p>{t(item)}</p>
                                        </Col>
                                    )
                            )}
                        </Row>
                    </Col>
                    <Col span={4} className={"col-flex"}></Col>
                </Row>
            )}
        </StyledHeader>
    );
};

const StyledHeader = styled.div`
    /* min-width: 900px; */
    min-width: 680px;
    padding: 18px 0 12px 0;
    background-color: #f0f0f0;
    /* position: sticky; */
    top: 0;
    left: 0;
    z-index: 1;
    .table-header {
        p {
            text-transform: uppercase;
            font-weight: bolder;
            color: #a5a5a5;
        }
        button {
            background-color: transparent;
            border: none;
        }
        .col-flex {
            display: flex;
            justify-content: flex-end;
        }
    }
`;
