import { Route, useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import { selectAuth } from "@redux";
import { IRoute } from "@interfaces";
import { LayoutApp } from "@layouts";
import { authRoutes, normalRoutes, PATH_CREATE_PASSWORD, PATH_HOME, PATH_LOGIN } from "@configs";

export const ComponentAppRoute = (props: IRoute) => {
    //page hook
    const history = useHistory();
    const { pathname } = useLocation();
    //props
    const { path, exact } = props;
    //redux states
    const { auth, permissions } = useSelector(selectAuth);
    //page variable
    const Component = props.component;
    const Layout = props.layout || LayoutApp;

    useEffect(() => {
        //WHAT: check user login
        const token = auth?.accessToken;

        //WHAT: check normal path
        const isNormalRoute =
            pathname === PATH_HOME
                ? true
                : normalRoutes.some((item: string) => {
                      if (item === PATH_HOME) {
                          return false;
                      }
                      return pathname.includes(item);
                  });

        //WHAT: check a uth path
        const isAuthRoute = authRoutes.some((item: string) => pathname.includes(item));

        if (token) {
            if (isAuthRoute || (props.permission && !permissions?.includes(props.permission))) {
                history.push(PATH_HOME);
            }
        } else if (isNormalRoute && !pathname.includes(PATH_CREATE_PASSWORD)) {
            history.push(PATH_LOGIN);
        }
    }, [pathname, auth]);

    return (
        <Route
            path={path}
            exact={exact}
            render={() => (
                <Layout>
                    <Component />
                </Layout>
            )}
        />
    );
};
