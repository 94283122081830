import { Form, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { IconLoadingPage, ShareButton, ShareInput, StyledForgotPassword } from "@components";
import { PATH_LOGIN, PATH_VERIFY_EMAIL } from "@configs";
import { authApi } from "@api";
import { openToast } from "@redux";
import { Formik } from "formik";
// import { IMessageError } from "@interfaces";

export const PageForgotPassword = () => {
    //page Hooks
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    //page states
    const [loading, setToggleLoading] = useState<boolean>(false);
    //page variable
    const initialValue = {
        email: "",
    };
    const emailSchema = Yup.object().shape({
        email: Yup.string()
            .required(t("validation.required", { returnObjects: true, name: t("page.email") }))
            .email(t("page.email_invalid"))
            .max(
                255,
                t("validation.max_length", {
                    returnObjects: true,
                    name: t("page.email"),
                })
            ),
    });

    const routeLogin = () => {
        history.push(PATH_LOGIN);
    };
    return (
        <Spin
            spinning={loading}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
            }}
            size="large"
            indicator={<IconLoadingPage />}
        >
            <StyledForgotPassword>
                <div className="recover_password main-color">{t("page.recover_password")}</div>
                <Formik
                    initialValues={initialValue}
                    validationSchema={emailSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            setToggleLoading(true);
                            const email = values.email;

                            const res = await authApi.sendEmail(email);

                            if (res.data.success === true) {
                                history.push(`${PATH_VERIFY_EMAIL}/${email}`);
                            }
                        } catch (error: any) {
                            const data = error.response.data;
                            if (data.message) {
                                dispatch(
                                    openToast({
                                        message: data.message,
                                        type: "error",
                                        autoHideDuration: 2000,
                                    })
                                );
                            } else {
                                dispatch(
                                    openToast({
                                        message: t("message.email_error"),
                                        type: "error",
                                        autoHideDuration: 2000,
                                    })
                                );
                            }
                        } finally {
                            setSubmitting(false);
                            dispatch(setToggleLoading(false));
                        }
                    }}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        errors,
                        touched,
                        /* and other goodies */
                    }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Form.Item
                                    colon={false}
                                    label={t("page.email")}
                                    className="main-color"
                                >
                                    <ShareInput
                                        name="email"
                                        style={{ width: "100%" }}
                                        onChange={handleChange}
                                        value={values.email}
                                        onBlur={handleBlur}
                                        errors={errors.email}
                                        touched={touched.email}
                                    />
                                </Form.Item>
                                <ShareButton
                                    type="primary"
                                    size="large"
                                    text={t("page.email_recover_link")}
                                    className="custom_btn"
                                    htmlType="submit"
                                    disable={isSubmitting}
                                />
                                <div onClick={routeLogin} className="return_login">
                                    {t("page.return_login")}
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </StyledForgotPassword>
        </Spin>
    );
};
