import { useParams, useHistory } from "react-router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "antd";

import { ComponentCardPlan, StyledCard, StyledPageDetail } from "@components";
import { PATH_PLANS, PLAN_TYPE_CREATE, PLAN_TYPE_DETAIL } from "@configs";
import { IPlanDetail } from "@interfaces";
import { planAPI } from "@api";
import { useAppDispatch, openToast } from "@redux";

const initialData: IPlanDetail = {
    price: undefined,
    additionalFields: [],
    status: "INACTIVE",
    name: "",
    trial: false,
    reward: {
        status: false,
        limit: undefined,
    },
    coupon: {
        status: false,
        limit: undefined,
    },
    benefit: {
        status: false,
        limit: undefined,
    },
    freePoint: false,
    memberShipTier: false,
    multiSignUp: false,
    contact: {
        status: false,
        limit: undefined,
    },
    membership: {
        status: false,
        limit: undefined,
    },
    segmentation: false,
    welcomeScreen: false,
    trafficSource: false,
    customSignUp: false,
    multiRole: {
        status: false,
        limit: undefined,
    },
    limitTransaction: {
        status: false,
        limit: 10000,
    },
    multiLine: {
        status: false,
        limit: undefined,
    },
    default: false,
    planMasterId: undefined,
    posFeature: false,
    numberOfStore: undefined,
    serviceFeature: false,
    createservice: {
        status: false,
        limit: undefined,
    },
    createService: {
        status: false,
        limit: undefined,
    },
    createStaff: {
        status: false,
        limit: undefined,
    },
    createCard: {
        status: false,
        limit: undefined,
    },
    appointment: { status: false, limit: undefined },
    journeyBuilder: { status: false, limit: undefined },
    marketingAutomationFeature: false,
    inbox: false,
    lineOaMessaging: false,
    reportFeature: false,
    overviewLoyalty: false,
    summaryDashboard: false,
    analyticsPOS: false,
    customerAnalytics: false,
};
export const PagePlanDetail = () => {
    //page hook
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const dispatch = useAppDispatch();
    //page state
    const [data, setData] = useState<IPlanDetail>();
    const [typePage, setTypePage] = useState(0);

    useEffect(() => {
        if (id) {
            fetchPlan();
            setTypePage(PLAN_TYPE_DETAIL);
        } else {
            setTypePage(PLAN_TYPE_CREATE);

            setData(initialData);
        }
    }, []);

    const fetchPlan = async () => {
        try {
            const response = await planAPI.detail(id);
            setTypePage(PLAN_TYPE_DETAIL);
            setData(response.data);
        } catch (err: any) {
            if (err.response) {
                const mess = Array.isArray(err.response.data.errors)
                    ? err.response.data.errors[0]
                    : err.response.data.errors;
                dispatch(
                    openToast({
                        message: mess,
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            } else
                dispatch(
                    openToast({
                        message: t("message.not_found", {
                            returnObjects: true,
                            name: t("object._plan"),
                        }),
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            history.push(PATH_PLANS);
        }
    };

    const loadingComponent = (
        <div className="loading-component">
            <StyledCard>
                <Skeleton className="skeleton" active paragraph={{ width: 4 }} />
            </StyledCard>
            <StyledCard>
                <Skeleton className="skeleton" active paragraph={{ width: 10 }} />
            </StyledCard>
        </div>
    );

    return (
        <StyledPageDetail>
            <>
                <div className="page-header">
                    <h3>{id ? t("page.plan.plan_detail") : t("page.plan.create_plan")}</h3>
                </div>
                {data ? <ComponentCardPlan data={data} typePage={typePage} /> : loadingComponent}
            </>
        </StyledPageDetail>
    );
};
