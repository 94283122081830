export default  {
    merchant: "Merchant",
    _merchant: "merchant",
    plan: "Plan",
    _plan: "plan",
    user: "User",
    _user: "user",
    plan_pricing: "Plan pricing",
    reward_limit: "Reward limit",
    coupons_limit: "Coupon limit",
    benefits_limit: "Benefits limit",
    membership_limit: "Membership limit",
    admin_limit: "Admin limit",
    LINEOA_account_limit: "LINEOA account limit",
    contacts_limit: "Contacts limit",

};