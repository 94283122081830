import { Button, Skeleton } from "antd";
import { Link, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { ComponentCardMerchant, StyledPageDetail } from "@components";
import { IMerchantDetail, IMerchantAdmin } from "@interfaces";
import { PATH_HOME, PATH_MERCHANTS, INIT_PAGE } from "@configs";
import { merchantAPI } from "@api";
import { openToast, useAppDispatch } from "@redux";

export const PageMerchantDetail = () => {
    //page hook
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const dispatch = useAppDispatch();
    //page state
    const [data, setData] = useState<IMerchantDetail>();
    const [merchantAdmin, setMerchantAdmin] = useState<IMerchantAdmin[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
      if(id){
          getDetail();
          getMerchantInfo();
      }
    }, [id]);

    const getDetail = async () => {
        setIsLoading(true);
        try {
            const response = await merchantAPI.detail(id);
            setData(response.data);
        } catch (err: any) {
            if (err.response) {
                const mess = Array.isArray(err.response.data.errors)
                    ? err.response.data.errors[0]
                    : err.response.data.errors;
                dispatch(
                    openToast({
                        message: mess,
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            } else
                dispatch(
                    openToast({
                        message: t("message.error"),
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            history.push(PATH_HOME);
        } finally {
            setIsLoading(false);
        }
    };
    const getMerchantInfo = async () => {
        const listMerchantParam = { ...INIT_PAGE, merchantId: id };
        setIsLoading(true);
        try {
            const response = await merchantAPI.getAdminOfMerchant(listMerchantParam);
            setMerchantAdmin(response.data.docs);
        } catch (err: any) {
            if (err.response) {
                const mess = Array.isArray(err.response.data.errors)
                    ? err.response.data.errors[0]
                    : err.response.data.errors;
                dispatch(
                    openToast({
                        message: mess,
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            } else
                dispatch(
                    openToast({
                        message: t("message.error"),
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            history.push(PATH_HOME);
        } finally {
            setIsLoading(false);
        }
    };

    const loadingComponent = (
        <div className="loading-component">
            <Skeleton className="skeleton" active paragraph={{ rows: 5 }} />
            <Skeleton className="skeleton" active paragraph={{ rows: 6 }} />
            <Skeleton className="skeleton" active paragraph={{ rows: 4 }} />
        </div>
    );

    return (
        <StyledPageDetail>
            <Skeleton loading={isLoading} className="top-skeleton" active title={false}>
                <div>
                    <div className="page-header">
                        <h3>{data?.name}</h3>
                    </div>
                    <div>
                        <Button style={{ background: "#E1E1E1" }} disabled={true}>
                            {data?.basicInfo?.planName || "---"}
                        </Button>
                        <Button className="primary-btn" type={"primary"}>
                            <Link
                                to={{
                                    pathname: PATH_MERCHANTS + `/${data?._id}/update`,
                                }}
                            >
                                {t("page.merchant.edit_plan")}
                            </Link>
                        </Button>
                    </div>
                </div>
            </Skeleton>
            {isLoading ? (
                loadingComponent
            ) : (
                <ComponentCardMerchant data={data} merchantAdmin={merchantAdmin} />
            )}
        </StyledPageDetail>
    );
};
