import styled from "styled-components";
import { Button, Col, Row, Dropdown, Menu, Modal } from "antd";
import { MoreHorizontal } from "react-feather";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { IMerchantGeneral } from "@interfaces";
import { TABLE_PLANTS } from "@configs";

interface IProps {
    dataRow?: IMerchantGeneral;
    tableType?: number;
    children?: JSX.Element | JSX.Element[];
    handleDelete: () => void;
    handleDuplicate?: () => void;
    isUser?: boolean;
}

export const ComponentTableRow = (props: IProps) => {
    // page hook
    const { t } = useTranslation();
    //page props
    const { children } = props;
    // page state

    const showModal = () => {
        Modal.confirm({
            title: t("message.delete_title"),
            icon: <ExclamationCircleOutlined />,
            content: t("message.delete_confirm"),
            okText: t("page.action.delete"),
            cancelText: t("page.action.cancel"),
            centered: true,
            onOk: props.handleDelete,
        });
    };
    const menu = (
        <Menu style={{ width: "100px" }}>
            {props.tableType === TABLE_PLANTS && (
                <Menu.Item key="0">
                    <div
                        style={{ fontWeight: 600, color: "green", textAlign: "center" }}
                        onClick={props.handleDuplicate}
                    >
                        {t("page.action.duplicate")}
                    </div>
                </Menu.Item>
            )}
            <Menu.Item key="1">
                <div
                    onClick={showModal}
                    style={{ color: "red", fontWeight: 600, textAlign: "center" }}
                >
                    {t("page.action.delete")}
                </div>
            </Menu.Item>
        </Menu>
    );
    return (
        <StyledRow>
            <Row gutter={16} className={"table-row align-center"}>
                {children}
                {!props.isUser ? (
                    <Col span={2} className={"col-flex flex-end"} style={{ paddingRight: 28 }}>
                        <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
                            <Button>
                                <MoreHorizontal />
                            </Button>
                        </Dropdown>
                    </Col>
                ) : (
                    <Col
                        span={2}
                        className={"col-flex flex-end"}
                        style={{ paddingRight: 28 }}
                    ></Col>
                )}
            </Row>
        </StyledRow>
    );
};

const StyledRow = styled.div`
    background-color: white;
    min-width: 680px;
    padding: 24px 0;
    border-radius: 5px;
    margin: 1.5rem 0;
    .primary-btn {
        background-color: ${(p) => p.theme.colors.primary};
        font-size: 16px;
        color: white;
    }
    .default-btn {
        font-size: 16px;
        border: 1px #646464 solid !important;
        color: #646464;
    }
    .align-center {
        display: flex;
        align-items: center;
    }
    .table-row {
        p {
            color: #6c7084;
            font-weight: 600;
            margin: 0;
            font-size: 16px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        button {
            border: transparent;
            box-shadow: none;
        }
        .col-flex {
            display: flex;
        }
        .flex-end {
            justify-content: flex-end;
            align-items: center;
        }
        .flex-start {
            justify-content: flex-start;
            align-items: center;
            Button {
                margin-left: 16px;
            }
        }
    }
`;
