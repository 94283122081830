import { Switch, Input } from "antd";
import React, { useState } from "react";
import styled from "styled-components";

import { MAX_LENGTH_INPUT } from "@configs";
interface IProps {
    label: string;
    placeHolder: string;
    defaultChecked: boolean;
    defaultValue: number | undefined;
    onPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onChange: {
        (e: React.ChangeEvent<any>): void;
        <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
            ? void
            : (e: string | React.ChangeEvent<any>) => void;
    };
    onBlur: any;
    setFieldValue: (bool: boolean) => void;
    days?: number;
    limit?: number;
    name: string;
    className: string;
}

export const ShareSwitchField = (props: IProps) => {
    // page state
    const [disable, setDisable] = useState(!props.defaultChecked);

    const onSwitch = (bool: boolean) => {
        setDisable(!disable);
        props.setFieldValue(bool);
    };
    return (
        <StyledDiv>
            <div className="switch">
                <Switch defaultChecked={props.defaultChecked} onChange={(e) => onSwitch(e)} />
                <div className="label">{props.label}</div>
            </div>
            <div className="half-W-input">
                <div>
                    <Input
                        name={props.name === "trial" ? `${props.name}.days` : `${props.name}.limit`}
                        defaultValue={props.defaultValue || ""}
                        placeholder={props.placeHolder}
                        size="large"
                        maxLength={MAX_LENGTH_INPUT}
                        disabled={disable}
                        onKeyPress={(e) => props.onPress(e)}
                        onChange={props.onChange}
                        value={!disable ? props.days || props.limit : ""}
                        onBlur={props.onBlur}
                        className={disable ? "" : props.className}
                        type="number"
                    />
                </div>
            </div>
        </StyledDiv>
    );
};

const StyledDiv = styled.div`
    input[type="number"] {
        -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    .invalid-input {
        border-color: ${(p) => p.theme.colors.danger};
        &:hover,
        &:focus-within {
            border-color: ${(p) => p.theme.colors.danger}!important;
        }
    }
`;
