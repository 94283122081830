import styled from "styled-components";

export const StyledLogin = styled.div`
    .login {
        margin-bottom: 60px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            margin: 25px;
        }
    }
    .ant-form-item-label {
        width: 100% !important;
        text-align: left;
    }
    .ant-form-item {
        width: 450px;
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            width: 250px;
        }
        @media (max-width: 320px) {
            width: 200px;
        }
    }
    .ant-form-item-label > label {
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        color: #a5a5a5;
    }

    input {
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
    }
    .ant-input {
        height: ${(p) => p.theme.heights.input};
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #000;
        padding: 15px;
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            font-size: 18px;
            height: 45px;
            padding: 10px;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobilePhone}) {
            font-size: 16px;
        }
    }
    .main-color {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #a5a5a5;
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            font-size: 18px;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobilePhone}) {
            font-size: 16px;
        }
    }
    .forgot {
        margin: 13px 0;
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;
        color: #646464;
        span {
            color: #f22f46;
            cursor: pointer;
            margin-left: 4px;
        }
    }
  
    .lable__remember {
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;
        color: #646464;
        margin-bottom: 30px;
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            font-size: 14px;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobilePhone}) {
            font-size: 12px;
        }
    }
    .create {
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        color: #000;
        cursor: pointer;
        text-align: center;
        @media (max-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            font-size: 18px;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobilePhone}) {
            font-size: 16px;
        }
    }
`;
