import { memo } from "react";
import { SharedSortIcon } from "@components";
import { enumSortBy, IDefaultSortIcon } from "@interfaces";

export const ComponentDefaultSortIcon = memo((props: IDefaultSortIcon) => {
    //props
    const { sortField, sortBy, handleSort, searchParams } = props;
    //variable
    const active = searchParams.sortField === sortField && searchParams.sortBy === sortBy;
    const iconType = sortBy === enumSortBy.ASC ? "caret-up" : "caret-down";

    const handleClick = () => {
        handleSort(sortField, sortBy);
    };

    return <SharedSortIcon type={iconType} active={active} onClick={handleClick} />;
});
