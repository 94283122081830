export default {
    delete: {
        success: "ลบสำเร็จ",
        fail: "ลบไม่สำเร็จ",
    },
    duplicate: {
        success: "คัดลอกสำเร็จ",
        fail: "คัดลอกไม่สำเร็จ",
    },
    create: {
        success: "สร้างสำเร็จ",
        fail: "สร้างไม่สำเร็จ",
    },
    update: {
        success: "อัปเดทสำเร็จ",
        fail: "อัปเดทไม่สำเร็จ",
    },
    form_validate:{
        warning: "ข้อมูลในบางช่องไม่ถูกต้อง. กรุณาตรวจสอบอีกครั้ง!"
    },
    reset_pass: {
        fail: "รีเซ็ทรหัสผ่านไม่สำเร็จ!" ,
        success: "รีเซ็ทรหัสผ่านสำเร็จ!"
    },
    sign_up:{
        success: "สมัครใช้งานสำเร็จ!",
        fail: "สมัครใช้งานไม่สำเร็จ!"
    },
    delete_confirm: "คุณแน่ใจว่าลบใช่หรือไม่?",
    signout_confirm: "คุณแน่ใจว่าจะลงชื่อออกใช่หรือไม่?",
    exist_email: "อีเมลนี้ถูกใช้งานแล้ว!",
    not_found: "{{name}} ไม่สามารถค้นหาได้",
    delete_title: "คุณต้องการที่จะลบ {{name}}?",
    try_again_text: "มีข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่อีกครั้ง",
    invalid: "หน้าไม่ถูกต้อง",
    email_error:"รูปแบบอีเมลไม่ถูกต้อง",
    error: "มีข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่อีกครั้ง"
};
