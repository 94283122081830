import styled from "styled-components";
import { Button } from "antd";

export const StyledTable = styled.div`
    padding: 2rem 3.5rem 3.5rem 3.5rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 3.5rem;
    height: calc(100vh - ${(p) => p.theme.header.height});
    min-height: calc(100vh - ${(p) => p.theme.header.height});
    overflow: auto;
    ::-webkit-scrollbar {
        width: 6px;
        height: 0;
    }

    ::-webkit-scrollbar-track {
        display: none;
    }

    ::-webkit-scrollbar-thumb {
        background: ${(p) => p.theme.colors.fadedText};
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        cursor: grab;
    }
    @media screen and (max-width: ${(p) => p.theme.breakPoints.breakMobileMedium}) {
        ::-webkit-scrollbar {
            display: none;
        }
    }
    h3,
    p {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow-wrap: anywhere;
        line-height: normal;
    }
    .delete-btn {
        color: green;
        font-weight: 600;
        text-align: center;
    }
    .duplicate-btn {
        color: red;
        font-weight: 600;
        text-align: center;
    }
    .space {
        padding: 0 5px;
    }
    .page-header,
    .page-header > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .sort{
        display: flex;
    flex-direction: column;
    }
    .page-header {
        padding: 1.5rem 0 14px 0;
        .add-btn {
            background-color: ${(p) => p.theme.colors.danger};
            color: white;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobileMedium}) {
            flex-direction: column;
            align-items: flex-start;
            div {
                justify-content: flex-start;
            }
            div:nth-child(2) {
                margin-top: 12px;
            }
            h3 {
                font-size: 20px;
            }
        }
        h3 {
            margin-right: 44px;
            font-weight: 700;
            font-size: 35px;
            margin-bottom: 0;
        }
        p {
            color: ${(p) => p.theme.colors.fadedText};
            font-size: 16px;
            font-weight: 600;
        }
        .sub-title {
            color: ${(p) => p.theme.colors.fadedText};
            font-size: 16px;
            margin-bottom: 10px;
            font-weight: 400;
        }
        .text-danger {
            width: fit-content;
            color: ${(p) => p.theme.colors.danger};
            font-size: 16px;
            font-weight: 500;
            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
        }
    }
    .table {
        flex: 1;
        overflow: auto;
        display: flex;

        /* ::-webkit-scrollbar {
            display: none;
        } */
        ::-webkit-scrollbar {
            width: 6px;
            height: 0;
        }

        ::-webkit-scrollbar-track {
            display: none;
        }

        ::-webkit-scrollbar-thumb {
            background: ${(p) => p.theme.colors.fadedText};
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb:hover {
            cursor: grab;
        }
    }
    /* .table::after {
        content: "";
        white-space: pre;
        margin: 0px 5px;
        height: 100%;
    } */
    .page-bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 14px;
    }
    .indiana-scroll-container {
        width: 100%;
    }
    .table-row,
    .table-header {
        padding: 10px 18px;
        border-radius: 1px;
        margin: 0 0 18px 0;
        width: 100%;
        /* min-width: 1400px; */
        min-width: 680px;
    }
    .table-header {
        padding: 18px 18px 12px 18px;
        background-color: #f0f0f0;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 1;
        margin: 0;
        .header-row {
            height: 28px;
        }
    }
    .table-row {
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        border-radius: 6px;
        background-color: white;
        height: 68px;
        .col-item {
            display: flex;
            align-items: center;
            .edit-btn {
                padding: 4px 13px;
                height: 26px;
                margin-left: 17px;
                font-size: 12px;
                background-color: #0263e0;
                border-color: #0263e0;
            }
            p {
                margin-bottom: 0;
                font-size: 16px;
                color: #6c7084;
            }
            .user-name {
                font-weight: 600;
                margin-left: 20px;
            }
            .ml-0 {
                margin-left: 0;
            }
            .col-merge-metrics {
                .text-bolder {
                    font-weight: 700;
                }
            }
        }
    }
    .table-row:hover {
        box-shadow: 0 5px 7px rgba(0, 0, 0, 0.07), 0 5px 7px rgba(0, 0, 0, 0.07);
    }
    .table-row-large {
        height: 100px;
        margin-bottom: 28px;
        .avatar {
            height: 80px;
            width: 80px;
            min-width: 80px;
        }
    }
    .table-header {
        .col-item {
            display: flex;
            align-items: center;
            p {
                text-transform: uppercase;
                color: ${(p) => p.theme.colors.fadedText};
                font-size: 14px;
                font-weight: 700;
                margin-bottom: 0;
                margin-right: 10px;
            }
            .filter-button {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                & > i {
                    cursor: pointer;
                }
                & > i:hover svg {
                    color: ${(p) => p.theme.colors.fadedText};
                }
            }
        }
    }
    .short-text {
        width: 40%;
        p {
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
        }
    }
    .copy {
        svg:hover {
            cursor: pointer;
        }
    }
    .table_ground {
        flex: 1;
    }

    @media (max-width: ${(p) => p.theme.breakPoints.breakMobileMedium}) {
        padding: 1.5rem;
    }
    // update 12/ 8
    .page-layout {
        display: flex;
        flex-direction: column;
        flex: 1;
        .table {
            flex: 1;
        }
    }
    .btn-edit-plan {
        background-color: ${(p) => p.theme.colors.primary};
        color: white;
        border-color: transparent !important;
    }
    .top-bar {
        align-items: center;
        margin-top: 38px;
        .justify-end {
            justify-content: flex-end;
            @media (max-width: 769px) {
                justify-content: flex-start;
            }
        }
   
    }
    .btn-edit-permission {
        border-color: rgba(0, 0, 0, 0.15);
        }
        
    }
    .btn-edit-permission:hover {
            border-color: rgba(0, 0, 0, 0.55);
            color: rgba(0, 0, 0, 0.75);
        }
    .btn-delete-permission{
        border-color:transparent;
    }
    .text-center-w100{
        text-align: center;
        width: 100%;
    }
    .col-flex{
        display: flex;

    }
    .flex-end{
        justify-content:flex-end;
    }
    .header-permission{
        flex-direction: column;
        align-items: flex-start!important;
    }
    .btn-add-member{
        align-self: flex-start;
        /* margin-top: 0.5rem; */
    }
    .btn-add-plan{
        align-self: flex-start;
    }
    .field-more-btn{
        justify-content: flex-end;
        overflow: unset;
        display: flex;
        
    }
`;

export const StyledTableButtonTop = styled(Button)`
    height: 42px;
    background: ${(props) => props.theme.colors.main};
    min-width: fit-content;
    padding: 4px 18px;
    color: white;
    font-size: 16px;
`;
