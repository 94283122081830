export default {
    invalid_type: {
        email: "อีเมลของคุณไม่ถูกต้อง กรุณากรอกใหม่",
    },
    required: "{{name}} กรุณากรอกข้อมูลดังนี้",
    max_length: "{{name}} จำเป็นต้องมีความยาวไม่เกิน 255 ตัวอักษร",
    password_must_match: "รหัสผ่านของคุณไม่ตรงกัน กรุณากรอกใหม่",
    max_length_number: "{{name}} จำเป็นต้องมีความยาวไม่เกิน {{number}} ตัวอักษร",
    min: "{{name}} จำเป็นต้องมีความยาวอย่างน้อย {{number}} ตัวอักษร ",
    link_must_url:"Url ของคุณไม่ถูกต้อง กรุณากรอกใหม่",
    field_required: "กรุณากรอกข้อมูล",
    email_invalid: "อีเมลไม่ถูกต้อง กรุณากรอกใหม่",
    pass_not_confirm: "ยืนยันรหัสผ่านไม่ถูกต้อง กรุณากรอกใหม่",
    max: "{{name}} จำเป็นต้องมีความยาวที่น้อยกว่า {{number}} ตัวอักษร",
    max_number: "{{name}} จำเป็นต้องมีจำนวนที่น้อยกว่า {{number}}",
    min_number: "{{name}}จำเป็นต้องมีจำนวนที่มากกว่า {{number}}",
    must_number: "{{name}} จำเป็นต้องเป็นตัวเลขเท่านั้น",
};
