import { Form, Input, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";

import { IconLoadingPage, ShareButton, StyledSignUp } from "@components";
import { PATH_LOGIN, PATH_VERIFY_EMAIL } from "@configs";
import { authApi, roleAPI } from "@api";
import { useAppDispatch, openToast } from "@redux";

export const PageSignUp = () => {
    //page Hooks
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const dispatch = useAppDispatch();
    // page state
    const [isLoading, setIsLoading] = useState(false);
    // validation
    const registerSchema = Yup.object().shape({
        email: Yup.string()
            .required(t("validation.required", { returnObjects: true, name: t("page.email") }))
            .email(t("validation.invalid_type.email")),
    });
    const formik = useFormik({
        initialValues: {
            email: "",
            inviteId: id,
        },
        validationSchema: registerSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });
    const postRegister = async (data: { email: string; inviteId: string }) => {
        try {
            const response = await authApi.register(data);
            if (response.data.success) {
                dispatch(
                    openToast({
                        message: t("message.sign_up.success"),
                        type: "success",
                        autoHideDuration: 2000,
                    })
                );
                const email = data.email;
                history.push(`${PATH_VERIFY_EMAIL}/${email}`);
            }
        } catch (err: any) {
            const data = err.response.data;
            if (data.message) {
                dispatch(
                    openToast({
                        message: data.message,
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            } else {
                dispatch(
                    openToast({
                        message: t("message.try_again_text"),
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            }
        } finally {
            setIsLoading(true);
        }
    };
    const handleSubmit = async (values: { email: string; inviteId: string }) => {
        setIsLoading(true);
        await postRegister(values);
        setIsLoading(false);
    };
    const handleMoveLogin = () => {
        history.push(PATH_LOGIN);
    };
    const checkValid = async () => {
        try {
            const response = await roleAPI.validInviteLink(id);
            if (!response.data.valid) {
                dispatch(
                    openToast({
                        message: t("message.invalid"),
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
                // history.push(PATH_LOGIN);
            }
        } catch (err) {}
    };
    useEffect(() => {
        checkValid();
    }, []);

    return (
        <Spin
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
            }}
            size="large"
            indicator={<IconLoadingPage />}
            spinning={isLoading}
        >
            <StyledSignUp>
                <StyledContainer>
                    <div className="sign_up main-color">{t("page.sign_up")}</div>

                    <Form
                        labelCol={{
                            span: 3,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        layout="horizontal"
                    >
                        <Form.Item colon={false} label={t("page.email")} className="main-color">
                            <Input
                                name="email"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                style={{ width: "100%" }}
                                className={
                                    formik.touched.email
                                        ? formik.errors.email
                                            ? "invalid-input"
                                            : "valid-input"
                                        : ""
                                }
                            />
                            {formik.errors.email && formik.touched.email ? (
                                <small className="err-text">{formik.errors.email}</small>
                            ) : (
                                <small style={{ visibility: "hidden" }}>errField</small>
                            )}
                        </Form.Item>
                        <ShareButton
                            type="primary"
                            onClick={() => formik.handleSubmit()}
                            size="large"
                            text={t("page.sign_up")}
                        />
                        <div className="log_in" onClick={handleMoveLogin}>
                            {t("page.login")}
                        </div>
                    </Form>
                </StyledContainer>
            </StyledSignUp>
        </Spin>
    );
};

const StyledContainer = styled.div`
    .err-input {
    }
    .err-text {
        font-weight: bold;
        color: ${(p) => p.theme.colors.danger};
        font-size: 12px;
    }
    .invalid-input {
        border-color: ${(p) => p.theme.colors.danger}!important;
        &:focus-within {
            border-color: ${(p) => p.theme.colors.danger}!important;
        }
    }
    .valid-input {
    }
`;
