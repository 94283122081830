import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Col, Modal, Row, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";
import moment from "moment";

import { IMerchantGeneral } from "@interfaces";
import { PATH_MERCHANTS } from "@configs";

interface IProps {
    data: IMerchantGeneral;
    handleDelete: (id: string) => void;
    handleEdit: (id: string) => void;
    index: number;
}

export const ComponentRowMerchant = (props: IProps) => {
    //hooks
    const { t } = useTranslation();

    //props
    const { data, handleDelete, handleEdit } = props;

    const showModal = () => {
        Modal.confirm({
            title: t("message.delete_title"),
            icon: <ExclamationCircleOutlined />,
            content: t("message.delete_confirm", {
                returnObjects: true,
                name: t("object._contact"),
            }),
            okText: t("page.action.delete"),
            cancelText: t("page.action.cancel"),
            centered: true,
            onOk: () => handleDelete(data._id),
        });
    };

    const menu = (
        <Menu>
            <Menu.Item key={1}>
                <div className="duplicate-btn" onClick={showModal}>
                    {t("page.action.delete")}
                </div>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="table-row">
            <Row
                style={{ height: "100%" }}
                gutter={24}
                type="flex"
                align="middle"
                justify="space-between"
            >
                <Col className="col-item" span={6}>
                    <Link to={`${PATH_MERCHANTS}/${data._id}`}>
                        <p className="user-name">{data.name || "---"}</p>
                    </Link>
                </Col>
                <Col className="col-item" span={3}>
                    <p>{data.contacts || "---"}</p>
                </Col>
                <Col className="col-item" span={3}>
                    <p>{data.members || "---"}</p>
                </Col>

                <Col className="col-item" span={3}>
                    <p>{moment(new Date(data?.createdAt || "")).format("DD/MM/YYYY")}</p>
                </Col>
                <Col className="col-item" span={3}>
                    <p>{moment(new Date(data?.updatedAt || "")).format("DD/MM/YYYY")}</p>
                </Col>
                <Col className="col-item" span={2}>
                    <p>{data.planName}</p>
                </Col>

                <Col className="col-item" span={2}>
                    <Button onClick={() => handleEdit(data._id)} className="btn-edit-plan">
                        {t("page.action.edit")}
                    </Button>
                </Col>
                <Col span={2} className="field-more-btn">
                    <Dropdown
                        //@ts-ignore
                        getPopupContainer={(trigger) => trigger.parentNode}
                        overlay={menu}
                        trigger={["click"]}
                        placement="bottomRight"
                    >
                        <Button style={{ border: "unset" }}>
                            <MoreHorizontal />
                        </Button>
                    </Dropdown>
                </Col>
            </Row>
        </div>
    );
};
