import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from ".";

interface Istate{
    modalType: number;
    visible: boolean;
}
const initialState: Istate = {
    modalType: 0,
    visible: false,
};

const modalSlice = createSlice({
    name: "modal", 
    initialState: initialState,
    reducers: {
        setModalType: (state, action: PayloadAction<number>) => {state.modalType = action.payload;},
        setVisible: (state, action: PayloadAction<boolean>) => {state.visible = action.payload;},
    }
});

export const {setModalType, setVisible} = modalSlice.actions;
export const selectModal = (state: RootState) => state.modal;
export default modalSlice.reducer;