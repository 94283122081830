import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Col, Modal, Row, Menu, Dropdown, Button } from "antd";
import { MoreHorizontal } from "react-feather";

import { IStaffData } from "@interfaces";
import { numberFormatter } from "@utils";

interface IProps {
    data: IStaffData;
    handleDelete: (id: string) => void;
    handleEdit: (id: string, roleId: string, roleName: string) => void;
    index: number;
    isUser: boolean;
}

export const ComponentRowPermission = (props: IProps) => {
    //hooks
    const { t } = useTranslation();
    //props
    const { data, handleDelete, handleEdit, isUser } = props;

    const showModal = () => {
        Modal.confirm({
            title: t("message.delete_title"),
            icon: <ExclamationCircleOutlined />,
            content: t("message.delete_confirm", {
                returnObjects: true,
                name: t("object._segment"),
            }),
            okText: t("page.action.delete"),
            cancelText: t("page.action.cancel"),
            centered: true,
            onOk: () => handleDelete(data._id),
        });
    };

    const menu = (
        <Menu>
            <Menu.Item key={1}>
                <div className="duplicate-btn" onClick={showModal}>
                    {t("page.action.delete")}
                </div>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="table-row ">
            <Row style={{ height: "100%" }} gutter={24} type="flex" align="middle">
                <Col className="col-item" span={8}>
                    <p className="user-name">{data.fullName || data.email || "---"}</p>
                </Col>
                <Col className="col-item" span={8}>
                    <p className="text-center-w100">{numberFormatter(data?.roleName)}</p>
                </Col>
                <Col span={6}>
                    {!isUser ? (
                        <Button
                            className="default-btn btn-edit-permission"
                            onClick={() => handleEdit(data._id, data.roleId, data.roleName)}
                        >
                            <div>{t(`page.action.edit`)}</div>
                        </Button>
                    ) : (
                        ""
                    )}
                </Col>
                {!isUser ? (
                    <Col
                        span={2}
                        className={"col-flex flex-end field-more-btn"}
                        style={{ paddingRight: 28 }}
                    >
                        <Dropdown
                            //@ts-ignore
                            getPopupContainer={(trigger) => trigger.parentNode}
                            overlay={menu}
                            trigger={["click"]}
                            placement="bottomRight"
                        >
                            <Button className="btn-delete-permission">
                                <MoreHorizontal />
                            </Button>
                        </Dropdown>
                    </Col>
                ) : (
                    <Col
                        span={2}
                        className={"col-flex flex-end"}
                        style={{ paddingRight: 28 }}
                    ></Col>
                )}
            </Row>
        </div>
    );
};
