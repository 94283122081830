export default {
    delete: {
        success: "Delete successfully",
        fail: "Delete failed",
    },
    duplicate: {
        success: "Duplicate successfully",
        fail: "Duplicate failed",
    },
    create: {
        success: "Create successfully",
        fail: "Create failed",
    },
    update: {
        success: "Update successfully",
        fail: "Update failed",
    },
    form_validate: {
        warning: "Some fields are invalid. Please check!",
    },
    reset_pass: {
        fail: "Reset password not successfully!",
        success: "Reset password successfully!",
    },
    sign_up: {
        success: "Sign up successfully!",
        fail: "Sign up not successfully!",
    },
    delete_confirm: "Are you sure delete it?",
    signout_confirm: "Are you sure you want to sign out?",
    exist_email: "Email already exist!",
    not_found: "This {{name}} is not found",
    delete_title: "Do you want delete this {{name}}?",
    try_again_text: "Have an error. Please try again",
    invalid: "Invalid page",
    email_error: "Email must be an email",
    error: "Have an undefine error. Try again!",
};
