import { IHomeNavigation } from "@interfaces";
import axiosClient from "./axiosClient";

export const homeNavigationApi = {
    getList: () => {
        const url = "/navigation-boxes";
        return axiosClient.get(url);
    },
    addData: (values: IHomeNavigation) => {
        const url = "/navigation-boxes";
        return axiosClient.post(url, values);
    },
    editData: (values: IHomeNavigation, idItem: string) => {
        const url = `/navigation-boxes/${idItem}`;
        return axiosClient.put(url, values);
    },
    getItem: (idItem: string) => {
        const url = `/navigation-boxes/${idItem}`;
        return axiosClient.get(url);
    },
    deleteItem: (idItem:string) => {
        const url = `/navigation-boxes/${idItem}`;
        return axiosClient.delete(url);
    }, 
    uploadImage: (values:FormData) => {
         const url = "/upload";
        return axiosClient.post(url, values);
    }
};
