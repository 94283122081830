import { useEffect, useState, useRef } from "react";
import { Col, Input, Pagination, Row } from "antd";
import { Search } from "react-feather";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { ComponentEmpty, ComponentRowMerchant, StyledTable } from "@components";
import { INIT_PAGE, MAX_LENGTH_INPUT, PAGINATION, PATH_MERCHANTS } from "@configs";
import { merchantAPI } from "@api";
import { enumSortBy, enumSortFields, IMerchantGeneral, IMerchantList } from "@interfaces";
import { openToast, selectApp, setLoading, useAppDispatch } from "@redux";
import ScrollContainer from "react-indiana-drag-scroll";
import { ComponentDefaultSortIcon } from "src/Components/SortIcon";

const transText = "page.merchant";

export const PageMerchantList = () => {
    // page hook
    const { t } = useTranslation();
    const typingTimeoutRef = useRef(0);
    const dispatch = useAppDispatch();
    const history = useHistory();
    //redux state
    const { loading } = useSelector(selectApp);
    // page state
    const [merchantList, setMerchantList] = useState<IMerchantGeneral[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalMerchant, setTotalMerchant] = useState<number>(0);
    const [keyword, setKeyword] = useState<string | undefined>();
    const [params, setParams] = useState<IMerchantList>(INIT_PAGE);
    useEffect(() => {
        // fetchInitData(params);
        fetchListMerchant(params);
    }, [params]);

    // const fetchInitData = async () => {
    // dispatch(setLoading(true));
    // await fetchListMerchant(currentPage);
    // dispatch(setLoading(false));
    // };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }
        typingTimeoutRef.current = window.setTimeout(() => {
            // setKeyword(value);
            // fetchListMerchant(1, value);
            if (value) {
                setParams({ ...params, name: value });
            } else {
                setParams(INIT_PAGE);
            }
        }, 300);
    };

    const editMerchant = (id: string) => {
        history.push(PATH_MERCHANTS + "/" + id + "/update");
    };

    const deleteMerchant = async (id: string) => {
        try {
            const response = await merchantAPI.delete(id);
            if (response.data.success === true) {
                dispatch(
                    openToast({
                        message: t("message.delete.success", {
                            returnObjects: true,
                            name: t("object._merchant"),
                        }),
                        type: "success",
                        autoHideDuration: 2000,
                    })
                );
                if (merchantList.length <= 1 && currentPage > 1)
                    fetchListMerchant(currentPage - 1, keyword);
                else fetchListMerchant(currentPage, keyword);
            } else {
                dispatch(
                    openToast({
                        message: t("message.delete.fail", {
                            returnObjects: true,
                            name: t("object._merchant"),
                        }),
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            }
        } catch (err: any) {
            if (err.response) {
                const mess = Array.isArray(err.response.data.errors)
                    ? err.response.data.errors[0]
                    : err.response.data.errors;
                dispatch(
                    openToast({
                        message: mess,
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            } else
                dispatch(
                    openToast({
                        message: t("message.delete.fail"),
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
        }
    };

    const handlePagination = (page: number) => {
        setCurrentPage(page);
        setParams({ ...params, page: page });
        // fetchListMerchant(page);
    };

    const fetchListMerchant = async (params: IMerchantList) => {
        try {
            dispatch(setLoading(true));
            //WHAT: set List Merchant
            const response = await merchantAPI.getList(params);
            if (response?.data?.docs) {
                setMerchantList(response.data.docs);
                setTotalMerchant(response.data?.totalDocs || totalMerchant);
                setCurrentPage(response.data.page);
                dispatch(setLoading(false));
            }
        } catch (error: any) {
            setMerchantList([]);
            dispatch(setLoading(false));
        } finally {
            dispatch(setLoading(false));
        }
    };
    const handleSort = (sortField: enumSortFields, sortBy: enumSortBy) => {
        const newSortParams = { ...params, sortField, sortBy };
        setParams(newSortParams);
    };
    return (
        <StyledTable>
            <div className="page-layout">
                <div className="page-header">
                    <div>
                        <h3>{t("page.merchant.merchants")}</h3>
                        <p style={{ marginBottom: 0 }}>
                            {totalMerchant} {t("page._total")}
                        </p>
                    </div>
                    <div>
                        <div className="space"></div>
                        <Input
                            onChange={handleSearch}
                            placeholder={t(`${transText}.search_name`) + " ..."}
                            suffix={
                                <Search color={"#A5A5A5"} style={{ paddingLeft: 4 }} size={18} />
                            }
                            maxLength={MAX_LENGTH_INPUT}
                        />
                    </div>
                </div>
                <div className="table">
                    <ScrollContainer vertical={false}>
                        <div className="table_ground">
                            <div className="table-body">
                                <div className="table-header">
                                    <Row
                                        gutter={24}
                                        type="flex"
                                        justify="space-between"
                                        align="middle"
                                        className="header-row"
                                    >
                                        <Col className="col-item" span={6}>
                                            <p>{t("page.merchant.segment_name")}</p>
                                            <div className="sort">
                                                <ComponentDefaultSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.ASC}
                                                    sortField={enumSortFields.NAME}
                                                    searchParams={params}
                                                />
                                                <ComponentDefaultSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.DESC}
                                                    sortField={enumSortFields.NAME}
                                                    searchParams={params}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-item" span={3}>
                                            <p>{t("page.merchant.contacts")}</p>
                                            <div className="sort">
                                                <ComponentDefaultSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.ASC}
                                                    sortField={enumSortFields.CONTACTS}
                                                    searchParams={params}
                                                />
                                                <ComponentDefaultSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.DESC}
                                                    sortField={enumSortFields.CONTACTS}
                                                    searchParams={params}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-item" span={3}>
                                            <p>{t("page.merchant.members")}</p>
                                            <div className="sort">
                                                <ComponentDefaultSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.ASC}
                                                    sortField={enumSortFields.MEMBERS}
                                                    searchParams={params}
                                                />
                                                <ComponentDefaultSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.DESC}
                                                    sortField={enumSortFields.MEMBERS}
                                                    searchParams={params}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-item" span={3}>
                                            <p>{t("page.merchant.create_date")}</p>
                                            <div className="sort">
                                                <ComponentDefaultSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.ASC}
                                                    sortField={enumSortFields.CREATEDAT}
                                                    searchParams={params}
                                                />
                                                <ComponentDefaultSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.DESC}
                                                    sortField={enumSortFields.CREATEDAT}
                                                    searchParams={params}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-item" span={3}>
                                            <p>{t("page.merchant.update_date")}</p>
                                            <div className="sort">
                                                <ComponentDefaultSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.ASC}
                                                    sortField={enumSortFields.UPDATEDAT}
                                                    searchParams={params}
                                                />
                                                <ComponentDefaultSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.DESC}
                                                    sortField={enumSortFields.UPDATEDAT}
                                                    searchParams={params}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-item" span={4}>
                                            <p>{t("page.merchant.plan")}</p>
                                            <div className="sort">
                                                <ComponentDefaultSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.ASC}
                                                    sortField={enumSortFields.PLANNAME}
                                                    searchParams={params}
                                                />
                                                <ComponentDefaultSortIcon
                                                    handleSort={handleSort}
                                                    sortBy={enumSortBy.DESC}
                                                    sortField={enumSortFields.PLANNAME}
                                                    searchParams={params}
                                                />
                                            </div>
                                        </Col>

                                        <Col span={2}></Col>
                                    </Row>
                                </div>
                                {merchantList?.length > 0 ? (
                                    <div className="data-table">
                                        {merchantList.map((item, index) => (
                                            <ComponentRowMerchant
                                                handleDelete={deleteMerchant}
                                                key={index}
                                                data={item}
                                                handleEdit={editMerchant}
                                                index={index}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    !loading && <ComponentEmpty />
                                )}
                            </div>
                        </div>
                    </ScrollContainer>
                </div>

                <div className="page-bottom">
                    {totalMerchant > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={1}
                                pageSize={PAGINATION}
                                total={totalMerchant}
                                onChange={handlePagination}
                                current={currentPage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};
