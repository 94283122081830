export default {
    invalid_type: {
        email: "Not type of email",
    },
    required: "{{name}} is required",
    max_length: "{{name}} should not exceed 255 characters",
    password_must_match: "Passwords must match",
    max_length_number: "{{name}} should not exceed {{number}} characters",
    min: "{{name}} is at least {{number}} characters ",
    link_must_url: "Link must be a valid URL",
    field_required: "This field is required!",
    email_invalid: "Email is invalid",
    pass_not_confirm: "This password not confirm",
    max: "{{name}} must be shorter than {{number}} characters",
    max_number: "{{name}} must be less than or equal to {{number}}",
    min_number: "{{name}} must be greater than or equal to {{number}}",
    must_number: "{{name}} must be numbers",
};
