import { Badge, Divider, Drawer, Layout, Menu, Popconfirm, Row } from "antd";
import { LogOut } from "react-feather";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MenuMode, MenuTheme } from "antd/lib/menu";
import { useTranslation } from "react-i18next";

import { capitalize, lowercase } from "@utils";
import { StyledHeader, StyledSidebar } from "@components";
import { selectApp, setMobileDrawer, useAppSelector, selectAuth, logout } from "@redux";
import {
    ADMIN_EDIT_MERCHANT,
    ADMIN_MANAGE_ROLE,
    ADMIN_UPDATE_PLAN,
    ADMIN_VIEW_MERCHANT,
    PATH_HOME_NAVIGATION,
    PATH_MERCHANTS,
    PATH_PERMISSIONS,
    PATH_PLANS,
} from "@configs";

const { Header, Sider } = Layout;

const rootSubMenuKeys: any[] = [];

const getKey = (name: string, index: number) => {
    const string = `${name}-${index}`;
    const key = string.replace(" ", "-");
    return key.charAt(0).toLowerCase() + key.slice(1);
};

interface ISideBarRoute {
    path?: string;
    name: string;
    icon?: JSX.Element;
    children?: Array<{
        path: string;
        name: string;
        badge?: {
            value: string;
        };
    }>;
    badge?: {
        value: string;
    };
    permission?: string;
}

const routes: ISideBarRoute[] = [
    {
        path: PATH_MERCHANTS,
        name: "Manage Merchants",
        permission: ADMIN_VIEW_MERCHANT,
    },
    {
        path: PATH_PLANS,
        name: "Manage Plans",
        permission: ADMIN_UPDATE_PLAN,
    },
    {
        path: PATH_HOME_NAVIGATION,
        name: "Homepage navigation",
        permission: ADMIN_EDIT_MERCHANT,
    },
    {
        path: PATH_PERMISSIONS,
        name: "Permissions",
        permission: ADMIN_MANAGE_ROLE,
    },
];

interface IProps {
    sidebarTheme: MenuTheme;
    sidebarMode: MenuMode;
    sidebarIcons: boolean;
    collapsed: boolean;
}

export const ComponentSidebar = ({
    sidebarTheme,
    sidebarMode,
    sidebarIcons,
    collapsed,
}: IProps) => {
    //hook
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    //redux state
    const app = useAppSelector(selectApp);
    const { permissions } = useSelector(selectAuth);
    //page state
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const pathname = location.pathname;

    const badgeTemplate = (badge: { value: string }) => <Badge count={badge.value} />;

    useEffect(() => {
        routes.forEach((route, index) => {
            const isCurrentPath = pathname.indexOf(lowercase(route.name)) > -1;
            const key = getKey(route.name, index);
            rootSubMenuKeys.push(key);
            if (isCurrentPath) setOpenKeys([...openKeys, key]);
        });
    }, []);

    const onOpenChange = (openKeys: string[]) => {
        const latestOpenKey = openKeys.slice(-1);
        if (rootSubMenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys([...latestOpenKey]);
        } else {
            setOpenKeys(latestOpenKey ? [...latestOpenKey] : []);
        }
    };

    const handleSwitchRoute = (route: string) => {
        history.push(route);
    };

    const handleLogout = () => {
        dispatch(logout());
    };

    const menu = (
        <>
            <Menu
                theme={sidebarTheme}
                className="border-0 scroll-y"
                style={{ flex: 1, height: "100%" }}
                mode={sidebarMode}
                openKeys={openKeys}
                onOpenChange={onOpenChange}
            >
                {routes.map((route, index) => {
                    if (
                        !route.children &&
                        route.permission &&
                        permissions?.includes(route.permission)
                    ) {
                        const routePath = route.path as string;
                        return (
                            <Menu.Item
                                key={getKey(route.name, index)}
                                className={pathname.includes(routePath) ? "selected" : ""}
                                onClick={() => {
                                    setOpenKeys([getKey(route.name, index)]);
                                    if (app.mobile) dispatch(setMobileDrawer(!app.mobileDrawer));
                                }}
                            >
                                <div
                                    onClick={() => {
                                        handleSwitchRoute(route?.path || "");
                                    }}
                                >
                                    {sidebarIcons && <span className="anticon">{route.icon}</span>}
                                    <span className="mr-2">{capitalize(route.name)}</span>
                                    {route.badge && badgeTemplate(route.badge)}
                                </div>
                            </Menu.Item>
                        );
                    } else {
                        return (
                            <Menu.ItemGroup
                                key={getKey(route.name, index)}
                                title={
                                    <span>
                                        {sidebarIcons && (
                                            <span className="anticon">{route.icon}</span>
                                        )}
                                        <span>{capitalize(route.name)}</span>
                                        {route.badge && badgeTemplate(route.badge)}
                                    </span>
                                }
                            >
                                {route.children &&
                                    route.children.map((subitem, index) => (
                                        <Menu.Item
                                            key={getKey(subitem.name, index)}
                                            className={pathname === subitem.path ? "selected" : ""}
                                            onClick={() => {
                                                if (app.mobile)
                                                    dispatch(setMobileDrawer(!app.mobileDrawer));
                                            }}
                                        >
                                            <Link to={`${subitem.path || "/"}`}>
                                                <span className="mr-auto">
                                                    {capitalize(subitem.name)}
                                                </span>
                                                {subitem.badge && badgeTemplate(subitem.badge)}
                                            </Link>
                                        </Menu.Item>
                                    ))}
                            </Menu.ItemGroup>
                        );
                    }
                })}
            </Menu>

            <Divider
                className={`m-0`}
                style={{
                    display: `${sidebarTheme === "dark" ? "none" : ""}`,
                }}
            />
            <div className={`py-3 px-4 bg-${sidebarTheme}`}>
                <Row type="flex" align="middle" justify="space-around">
                    {!collapsed && (
                        <Popconfirm
                            placement="top"
                            title={t("message.signout_confirm")}
                            onConfirm={handleLogout}
                            okText={t("page.action.yes")}
                            cancelText={t("page.action.cancel")}
                        >
                            <a
                                className={`px-3 ${
                                    sidebarTheme === "dark" ? "text-white" : "text-body"
                                }`}
                            >
                                <LogOut size={20} strokeWidth={1} />
                            </a>
                        </Popconfirm>
                    )}
                </Row>
            </div>
        </>
    );

    return (
        <>
            <StyledSidebar>
                {!app.mobile && (
                    <Sider
                        width={240}
                        className={`bg-${sidebarTheme}`}
                        theme={sidebarTheme}
                        collapsed={collapsed}
                    >
                        {menu}
                    </Sider>
                )}

                <Drawer
                    closable={false}
                    width={290}
                    placement="left"
                    onClose={() => dispatch(setMobileDrawer(!app.mobileDrawer))}
                    visible={app.mobileDrawer}
                    className="chat-drawer"
                >
                    <StyledSidebar>
                        <div
                            style={{
                                overflow: "hidden",
                                flex: "1 1 auto",
                                flexDirection: "column",
                                display: "flex",
                                height: "100vh",
                            }}
                        >
                            <StyledHeader>
                                <Header>
                                    <Link to="/">
                                        <a className="brand">
                                            <strong
                                                className="mx-1"
                                                style={{
                                                    display: "inline",
                                                }}
                                            >
                                                {t("page.navigationBar.super_admin_panel")}
                                            </strong>
                                        </a>
                                    </Link>
                                </Header>
                            </StyledHeader>
                            {menu}
                        </div>
                    </StyledSidebar>
                </Drawer>
            </StyledSidebar>
        </>
    );
};
