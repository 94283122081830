import { useTranslation } from "react-i18next";
import { Form, Spin } from "antd";
import { useHistory } from "react-router";
import { Formik, FormikHelpers } from "formik";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useState } from "react";

import { IconLoadingPage, StyledLogin } from "@components";
import { ShareButton, ShareInput } from "@components";
import { enumValidation, PATH_FORGOT_PASSWORD } from "@configs";
import { ILogin, IMessageError } from "@interfaces";
import { login, logout, openToast, selectAuth, setRemember } from "@redux";

export const PageLogin = () => {
    //page Hooks
    const { t } = useTranslation();
    // page variable
    const data: ILogin = {
        email: process.env.EMAIL_USER || "",
        password: process.env.PASSWORD || "",
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const remember = useSelector(selectAuth).isRemember;
    //page state
    const [loading, setToggleLoading] = useState<boolean>(false);
    // page variable

    const loginSchema = Yup.object().shape({
        email: Yup.string()
            .required(t("validation.required", { returnObjects: true, name: t("page.email") }))
            .email(t("page.email_invalid"))
            .max(
                255,
                t("validation.max_length", {
                    returnObjects: true,
                    name: t("page.email"),
                })
            ),
        password: Yup.string()
            .required(
                t("validation.required", { returnObjects: true, name: t("page.auth.password") })
            )
            .min(
                enumValidation.MIN_PASSWORD,
                t("validation.min", {
                    returnObjects: true,
                    name: t("page.auth.password"),
                    number: enumValidation.MIN_PASSWORD,
                })
            )
            .max(
                enumValidation.MAX_PASSWORD,
                t("validation.max_length_number", {
                    returnObjects: true,
                    name: t("page.auth.password"),
                    number: enumValidation.MAX_PASSWORD,
                })
            ),
    });

    const handleMoveForgotPassword = () => {
        history.push(PATH_FORGOT_PASSWORD);
    };

    const handleChangeRemember = () => {
        dispatch(setRemember(!remember));
    };

    const handleLogin = async (
        values: ILogin,
        { setSubmitting, setErrors }: FormikHelpers<ILogin>
    ) => {
        try {
            //WHAT: CALL API LOGIN
            setToggleLoading(true);

            const res = await dispatch(login(values));

            //WHAT: wrap function in create async thunk
            //@ts-ignore
            unwrapResult(res);

            //@ts-ignore
            if (!res.payload.userPermissions) {
                dispatch(logout());
                dispatch(
                    openToast({
                        message: t("page.auth.login_failed"),
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            } else {
                dispatch(
                    openToast({
                        message: t("page.auth.login_successfully"),
                        type: "success",
                        autoHideDuration: 2000,
                    })
                );
            }
        } catch (error: any) {
            if (error.response) {
                const { status, data } = error.response;
                if (status === 400) {
                    let errorsEmail = [];
                    let errorsPassword = [];
                    const message: IMessageError[] = data?.message;
                    message.forEach((item) => {
                        if (item.property === "email") {
                            errorsEmail = Object.keys(item.constraints);
                            if (errorsEmail.length >= 1) {
                                setErrors({
                                    email: item.constraints[errorsEmail[0]],
                                });
                                dispatch(
                                    openToast({
                                        message: item.constraints[errorsEmail[0]],
                                        type: "error",
                                        autoHideDuration: 2000,
                                    })
                                );
                            }
                        }
                        if (item.property === "password") {
                            errorsPassword = Object.keys(item.constraints);
                            if (errorsPassword.length >= 1) {
                                setErrors({
                                    email: item.constraints[errorsPassword[0]],
                                });
                                dispatch(
                                    openToast({
                                        message: item.constraints[errorsPassword[0]],
                                        type: "error",
                                        autoHideDuration: 2000,
                                    })
                                );
                            }
                        }
                    });
                    setErrors({ email: data?.message });
                } else if (status === 401) {
                    dispatch(
                        openToast({
                            message: data?.message,
                            type: "error",
                            autoHideDuration: 2000,
                        })
                    );
                } else if (status === 500) {
                    dispatch(
                        openToast({
                            message: data?.message,
                            type: "error",
                            autoHideDuration: 2000,
                        })
                    );
                } else if (data?.message) {
                    dispatch(
                        openToast({
                            message: data?.message,
                            type: "error",
                            autoHideDuration: 2000,
                        })
                    );
                } else {
                    dispatch(
                        openToast({
                            message: t("page.auth.login_failed"),
                            type: "error",
                            autoHideDuration: 2000,
                        })
                    );
                }
            } else {
                dispatch(
                    openToast({
                        message: t("page.auth.login_failed"),
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            }
        } finally {
            setSubmitting(false);
            setToggleLoading(false);
        }
    };
    return (
        <StyledLogin>
            <div className="login main-color">{t("page.auth.login")}</div>
            <Spin
                spinning={loading}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                }}
                size="large"
                indicator={<IconLoadingPage />}
            >
                <Formik initialValues={data} validationSchema={loginSchema} onSubmit={handleLogin}>
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        errors,
                        touched,
                        /* and other goodies */
                    }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Form.Item
                                    colon={false}
                                    label={t("page.email")}
                                    className="main-color"
                                >
                                    <ShareInput
                                        name="email"
                                        style={{ width: "100%" }}
                                        onChange={handleChange}
                                        value={values.email}
                                        onBlur={handleBlur}
                                        errors={errors.email}
                                        touched={touched.email}
                                    />
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    label={t("page.password")}
                                    className="main-color"
                                >
                                    <ShareInput
                                        name="password"
                                        style={{ width: "100%" }}
                                        type="password"
                                        onChange={handleChange}
                                        value={values.password}
                                        onBlur={handleBlur}
                                        errors={errors.password}
                                        touched={touched.password}
                                    />
                                </Form.Item>
                                <ShareButton
                                    type="primary"
                                    size="large"
                                    text={t("page.auth.login")}
                                    htmlType="submit"
                                    disable={isSubmitting}
                                />
                                <div className="forgot">
                                    {t("page.forgot")}
                                    <span onClick={handleMoveForgotPassword}>
                                        {t("page.password")}?
                                    </span>
                                </div>
                                <div className="remember">
                                    <input
                                        style={{
                                            height: "initial",
                                            marginRight: "8px",
                                            position: "relative",
                                            top: "2px",
                                        }}
                                        checked={remember}
                                        onClick={handleChangeRemember}
                                        type="checkbox"
                                        id="remember"
                                    />
                                    <label className="label__remember" htmlFor="remember">
                                        {t("page.remember")}
                                    </label>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </Spin>
        </StyledLogin>
    );
};
