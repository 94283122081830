import { Pagination } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { useSelector } from "react-redux";

import {
    useAppDispatch,
    useAppSelector,
    setModalType,
    setVisible,
    openToast,
    setLoading,
    selectApp,
} from "@redux";
import { permissionAPI } from "@api";
import {
    MODAL_PERMISSION_ADD,
    MODAL_PERMISSION_INFO,
    PERMISSION_TITLE,
    PAGINATION,
    MODAL_PERMISSION_EDIT,
} from "@configs";
import {
    ModalPermissionComponent,
    ComponentEmpty,
    StyledTable,
    ComponentRowPermission,
    StyledTableButtonTop,
} from "@components";
import { IStaffData } from "@interfaces";

export const PagePermissionList = () => {
    //page hook
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    //redux state
    const modalType = useAppSelector((state) => state.modal.modalType);
    const visible = useAppSelector((state) => state.modal.visible);
    const userInfo = useAppSelector((state) => state.auth.userInfo);
    const { loading } = useSelector(selectApp);
    //page state
    const [totalCount, setTotalCount] = useState<number>(0);
    const [params, setParams] = useState({ page: 1, limit: PAGINATION });
    const [staffList, setStaffList] = useState<IStaffData[]>([]);
    const [userId, setUserId] = useState("");
    const [userRoleId, setUserRoleId] = useState("");
    const [userRoleName, setUserRoleName] = useState("");

    useEffect(() => {
        getListStaff();
    }, [params]);

    const showModalInfo = () => {
        dispatch(setModalType(MODAL_PERMISSION_INFO));
        dispatch(setVisible(true));
    };
    const showModalAdd = () => {
        dispatch(setModalType(MODAL_PERMISSION_ADD));
        dispatch(setVisible(true));
    };
    const showModalEdit = (id: string, roleId: string, roleName: string) => {
        dispatch(setModalType(MODAL_PERMISSION_EDIT));
        dispatch(setVisible(true));
        setUserId(id);
        setUserRoleId(roleId);
        setUserRoleName(roleName);
    };
    const closeModal = (callBack?: boolean) => {
        if (callBack) {
            getListStaff();
        }
        dispatch(setVisible(false));
        dispatch(setModalType(0));
    };
    const getListStaff = async () => {
        dispatch(setLoading(true));
        try {
            const response = await permissionAPI.getList(params);
            if (response.status === 200) {
                setStaffList(response.data.docs);
                setTotalCount(response.data.totalDocs);
            }
        } catch (err: any) {
            if (err.response) {
                const mess = Array.isArray(err.response.data.errors)
                    ? err.response.data.errors[0]
                    : err.response.data.errors;
                dispatch(
                    openToast({
                        message: mess,
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
            } else
                dispatch(
                    openToast({
                        message: t("message.error"),
                        type: "error",
                        autoHideDuration: 2000,
                    })
                );
        } finally {
            dispatch(setLoading(false));
        }
    };
    const handlePagination = (page: number) => {
        setParams({ page: page, limit: PAGINATION });
    };
    const deleteStaff = async (id: string) => {
        if (id !== userInfo?._id) {
            try {
                await permissionAPI.delete(id);
                dispatch(
                    openToast({
                        message: t("message.delete.success", {
                            returnObjects: true,
                            name: t("object._user"),
                        }),
                        type: "success",
                        autoHideDuration: 2000,
                    })
                );
                getListStaff();
            } catch (err: any) {
                if (err.response) {
                    const mess = Array.isArray(err.response.data.errors)
                        ? err.response.data.errors[0]
                        : err.response.data.errors;
                    dispatch(
                        openToast({
                            message: mess,
                            type: "error",
                            autoHideDuration: 2000,
                        })
                    );
                } else
                    dispatch(
                        openToast({
                            message: t("message.delete.fail"),
                            type: "error",
                            autoHideDuration: 2000,
                        })
                    );
            }
        } else {
            dispatch(
                openToast({
                    message: t("message.not_found", {
                        returnObjects: true,
                        name: t("object._user"),
                    }),
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
        }
    };
    const deleteFromModal = async (userId: string | undefined) => {
        if (userId) await deleteStaff(userId);
        closeModal();
    };

    return (
        <StyledTable>
            <ModalPermissionComponent
                handleDelete={(useId) => deleteFromModal(useId)}
                typeModal={modalType}
                handleClose={closeModal}
                visible={visible}
                permissionTitle={PERMISSION_TITLE}
                userId={userId}
                currentRole={{ roleId: userRoleId, name: userRoleName }}
            />
            <div className="page-layout">
                <div className="page-header">
                    <div className="header-permission">
                        <h3>{t("page.permission.manage_permissions")}</h3>
                        <p className="sub-title">
                            {t("page.permission.text_permission_sub_title")}
                        </p>
                        <p onClick={showModalInfo} className="text-danger">
                            {t("page.permission.permission_types")}
                        </p>
                    </div>
                    <div className="btn-add-member">
                        <StyledTableButtonTop
                            type="danger"
                            size="default"
                            onClick={showModalAdd}
                            className="add-btn"
                            icon="plus"
                        >
                            {t("page.permission.add_member")}
                        </StyledTableButtonTop>
                    </div>
                </div>
                <div className="table">
                    <ScrollContainer vertical={false}>
                        <div className="table_ground">
                            <div className="table-body">
                                {staffList?.length > 0 ? (
                                    <div className="data-table">
                                        {staffList.map((item, index) => (
                                            <ComponentRowPermission
                                                key={index}
                                                data={item}
                                                index={index}
                                                handleDelete={deleteStaff}
                                                handleEdit={showModalEdit}
                                                isUser={userInfo?._id === item._id}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    !loading && <ComponentEmpty />
                                )}
                            </div>
                        </div>
                    </ScrollContainer>
                </div>

                <div className="page-bottom">
                    {totalCount > 0 && (
                        <div className="pagination">
                            <Pagination
                                defaultCurrent={1}
                                pageSize={PAGINATION}
                                total={totalCount}
                                onChange={handlePagination}
                                current={params.page}
                            />
                        </div>
                    )}
                </div>
            </div>
        </StyledTable>
    );
};
