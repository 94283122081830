export const validations = (key: "max-length" | "min-length", value: string) => {
    if (key === "max-length") {
        if (value.length >= 255) {
            return true;
        }
    }
    if (key === "min-length") {
        if (value.length <= 0) {
            return true;
        }
    }
};
