import styled from "styled-components";
import { useDispatch } from "react-redux";
import {
    AimOutlined,
    AppstoreOutlined,
    AreaChartOutlined,
    AudioOutlined,
    BankOutlined,
    BarChartOutlined,
    BarsOutlined,
    BellOutlined,
    BlockOutlined,
    CheckCircleOutlined,
    CheckOutlined,
    ChromeOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    CloseSquareOutlined,
    CloudUploadOutlined,
    ContactsOutlined,
    CopyOutlined,
    DashboardOutlined,
    DisconnectOutlined,
    DollarCircleOutlined,
    DragOutlined,
    EditOutlined,
    EnvironmentOutlined,
    EuroOutlined,
    ExceptionOutlined,
    ExclamationCircleOutlined,
    FileDoneOutlined,
    FileOutlined,
    FormOutlined,
    GiftOutlined,
    GitlabOutlined,
    HeartOutlined,
    HeatMapOutlined,
    HomeOutlined,
    IssuesCloseOutlined,
    LineChartOutlined,
    LoginOutlined,
    MailOutlined,
    OrderedListOutlined,
    PaperClipOutlined,
    PercentageOutlined,
    PlusCircleOutlined,
    PlusOutlined,
    PlusSquareOutlined,
    QqOutlined,
    QuestionCircleOutlined,
    QuestionOutlined,
    RadarChartOutlined,
    RedditOutlined,
    RedoOutlined,
    SettingOutlined,
    ShakeOutlined,
    ShareAltOutlined,
    SketchOutlined,
    SlidersOutlined,
    SoundOutlined,
    StarOutlined,
    StopOutlined,
    TagOutlined,
    UnorderedListOutlined,
    UserAddOutlined,
    UsergroupAddOutlined,
    VideoCameraOutlined,
    WarningOutlined,
    WechatOutlined,
    ZoomInOutlined,
} from "@ant-design/icons";

import { ICon } from "@interfaces";
import { setMainIcon } from "@redux";

interface IProps {
    onClick?: (value: ICon, index: number) => void;
    index?: number;
    toggle: boolean;
    handleToggle: () => void;
}

export const icons: ICon[] = [
    {
        key: "StarOutlined",
        value: StarOutlined,
        name: "star",
    },
    {
        key: "HomeOutlined",
        value: HomeOutlined,
        name: "home",
    },
    {
        key: "GiftOutlined",
        value: GiftOutlined,
        name: "gift",
    },
    {
        key: "HeartOutlined",
        value: HeartOutlined,
        name: "heart",
    },
    {
        key: "SettingOutlined",
        value: SettingOutlined,
        name: "setting",
    },
    {
        key: "LoginOutlined",
        value: LoginOutlined,
        name: "login",
    },
    {
        key: "ChromeOutlined",
        value: ChromeOutlined,
        name: "chrome",
    },
    {
        key: "PlusCircleOutlined",
        value: PlusCircleOutlined,
        name: "plus-circle",
    },
    {
        key: "PlusSquareOutlined",
        value: PlusSquareOutlined,
        name: "plus-square",
    },
    {
        key: "PlusOutlined",
        value: PlusOutlined,
        name: "plus",
    },
    {
        key: "QuestionCircleOutlined",
        value: QuestionCircleOutlined,
        name: "question-circle",
    },
    {
        key: "CheckOutlined",
        value: CheckOutlined,
        name: "check-out",
    },
    {
        key: "CheckCircleOutlined",
        value: CheckCircleOutlined,
        name: "check-circle",
    },
    {
        key: "ExclamationCircleOutlined",
        value: ExclamationCircleOutlined,
        name: "exclamation-circle",
    },
    {
        key: "QuestionOutlined",
        value: QuestionOutlined,
        name: "question",
    },
    {
        key: "CloseCircleOutlined",
        value: CloseCircleOutlined,
        name: "close-circle",
    },
    {
        key: "CloseSquareOutlined",
        value: CloseSquareOutlined,
        name: "close-square",
    },
    {
        key: "StopOutlined",
        value: StopOutlined,
        name: "stop",
    },
    {
        key: "IssuesCloseOutlined",
        value: IssuesCloseOutlined,
        name: "issues-close",
    },
    {
        key: "ClockCircleOutlined",
        value: ClockCircleOutlined,
        name: "clock-circle",
    },
    {
        key: "WarningOutlined",
        value: WarningOutlined,
        name: "warning",
    },
    {
        key: "EditOutlined",
        value: EditOutlined,
        name: "edit",
    },
    {
        key: "FormOutlined",
        value: FormOutlined,
        name: "form",
    },
    {
        key: "CopyOutlined",
        value: CopyOutlined,
        name: "copy",
    },
    {
        key: "RedoOutlined",
        value: RedoOutlined,
        name: "red",
    },
    {
        key: "ZoomInOutlined",
        value: ZoomInOutlined,
        name: "zoom",
    },
    {
        key: "DragOutlined",
        value: DragOutlined,
        name: "drag",
    },
    {
        key: "FormOutlined",
        value: FormOutlined,
        name: "form",
    },
    {
        key: "UnorderedListOutlined",
        value: UnorderedListOutlined,
        name: "unordered",
    },
    {
        key: "OrderedListOutlined",
        value: OrderedListOutlined,
        name: "ordered",
    },
    {
        key: "AreaChartOutlined",
        value: AreaChartOutlined,
        name: "area-chart",
    },
    {
        key: "BarChartOutlined",
        value: BarChartOutlined,
        name: "bar-chart",
    },
    {
        key: "LineChartOutlined",
        value: LineChartOutlined,
        name: "line-chart",
    },
    {
        key: "RadarChartOutlined",
        value: RadarChartOutlined,
        name: "radar-chart",
    },
    {
        key: "HeatMapOutlined",
        value: HeatMapOutlined,
        name: "heat-map",
    },
    {
        key: "SlidersOutlined",
        value: SlidersOutlined,
        name: "slider",
    },
    {
        key: "QqOutlined",
        value: QqOutlined,
        name: "qq",
    },
    {
        key: "WechatOutlined",
        value: WechatOutlined,
        name: "wechat",
    },
    {
        key: "GitlabOutlined",
        value: GitlabOutlined,
        name: "gitlab",
    },
    {
        key: "RedditOutlined",
        value: RedditOutlined,
        name: "reddit",
    },
    {
        key: "SketchOutlined",
        value: SketchOutlined,
        name: "sketch",
    },
    {
        key: "AimOutlined",
        value: AimOutlined,
        name: "aim",
    },
    {
        key: "BlockOutlined",
        value: BlockOutlined,
        name: "block",
    },
    {
        key: "BankOutlined",
        value: BankOutlined,
        name: "bank",
    },
    {
        key: "BellOutlined",
        value: BellOutlined,
        name: "bell",
    },
    {
        key: "BarsOutlined",
        value: BarsOutlined,
        name: "bars",
    },
    {
        key: "AppstoreOutlined",
        value: AppstoreOutlined,
        name: "appstore",
    },
    {
        key: "AudioOutlined",
        value: AudioOutlined,
        name: "audio",
    },
    {
        key: "CloudUploadOutlined",
        value: CloudUploadOutlined,
        name: "cloud-upload",
    },
    {
        key: "ContactsOutlined",
        value: ContactsOutlined,
        name: "contacts",
    },
    {
        key: "DashboardOutlined",
        value: DashboardOutlined,
        name: "dashboard",
    },
    {
        key: "DisconnectOutlined",
        value: DisconnectOutlined,
        name: "disconnect",
    },
    {
        key: "EnvironmentOutlined",
        value: EnvironmentOutlined,
        name: "environment",
    },
    {
        key: "ExceptionOutlined",
        value: ExceptionOutlined,
        name: "exception",
    },
    {
        key: "EuroOutlined",
        value: EuroOutlined,
        name: "euro",
    },
    {
        key: "DollarCircleOutlined",
        value: DollarCircleOutlined,
        name: "dollar",
    },
    {
        key: "FileOutlined",
        value: FileOutlined,
        name: "file",
    },
    {
        key: "FileDoneOutlined",
        value: FileDoneOutlined,
        name: "file-done",
    },
    {
        key: "MailOutlined",
        value: MailOutlined,
        name: "mail",
    },
    {
        key: "PaperClipOutlined",
        value: PaperClipOutlined,
        name: "paper-clip",
    },
    {
        key: "PercentageOutlined",
        value: PercentageOutlined,
        name: "percentage",
    },
    {
        key: "ShakeOutlined",
        value: ShakeOutlined,
        name: "shake",
    },
    {
        key: "ShareAltOutlined",
        value: ShareAltOutlined,
        name: "share",
    },
    {
        key: "SoundOutlined",
        value: SoundOutlined,
        name: "sound",
    },
    {
        key: "TagOutlined",
        value: TagOutlined,
        name: "tag",
    },
    {
        key: "UsergroupAddOutlined",
        value: UsergroupAddOutlined,
        name: "usergroup-add",
    },
    {
        key: "UserAddOutlined",
        value: UserAddOutlined,
        name: "user-add",
    },
    {
        key: "VideoCameraOutlined",
        value: VideoCameraOutlined,
        name: "video-camera",
    },
];
const style = { fontSize: "22px", margin: "12px", cursor: "pointer", color: "#08c" };

export const ComponentIcon = (props: IProps) => {
    const dispatch = useDispatch();
    //page props
    const { toggle, onClick, index } = props;
    //page variable

    const handleChangeIcon = (icon: ICon) => {
        dispatch(setMainIcon(icon));
    };

    return (
        <>
            {toggle && (
                <StyledIcon>
                    <div className="colorTheme">
                        {icons.map((icon) => {
                            const Component = icon.value;
                            return (
                                <Component
                                    style={style}
                                    onClick={() => {
                                        handleChangeIcon(icon);
                                        const customIndex = index || 0;
                                        if (onClick && customIndex === index) {
                                            onClick(icon, customIndex);
                                        }
                                    }}
                                />
                            );
                        })}
                    </div>
                </StyledIcon>
            )}
        </>
    );
};

// interface IPropsSwitchCase {
//     value: string;
//     className?: string;
// }

// export const ComponentIconSwitchCase = (props: IPropsSwitchCase) => {
//     const render = () => {
//         switch (props.value) {
//             case "StarFilled":
//                 return <StarFilled style={style} className={props.className} />;
//             case "SettingFilled":
//                 return <SettingFilled style={style} className={props.className} />;
//             case "AppleOutlined":
//                 return <AppleOutlined style={style} className={props.className} />;
//             case "HomeFilled":
//                 return <HomeFilled style={style} className={props.className} />;
//             case "DeleteFilled":
//                 return <DeleteFilled style={style} className={props.className} />;
//             case "IeOutlined":
//                 return <IeOutlined style={style} className={props.className} />;
//             case "ChromeOutlined":
//                 return <ChromeOutlined style={style} className={props.className} />;
//             case "GithubOutlined":
//                 return <GithubOutlined style={style} className={props.className} />;
//             case "YoutubeOutlined":
//                 return <YoutubeOutlined style={style} className={props.className} />;
//             case "AntCloudOutlined":
//                 return <AntCloudOutlined style={style} className={props.className} />;
//             case "Html5Outlined":
//                 return <Html5Outlined style={style} className={props.className} />;
//             case "FacebookOutlined":
//                 return <FacebookOutlined style={style} className={props.className} />;
//             default:
//                 return <></>;
//         }
//     };

//     return <>{render()}</>;
// };

const StyledIcon = styled.div`
    .theme {
        .title {
            color: rgba(0, 0, 0, 0.85);
            font-weight: 600;
            line-height: 1.5rem;
        }
        .main__color {
            display: flex;
            align-items: flex-end;
            margin-top: 12px;
        }
    }
    .colorTheme {
        display: flex;
        flex-wrap: wrap;
        padding: 27px 23px;
        border-radius: 4px;
        background: #f7f7f8;
        margin-top: 22px;
    }
    .btn--edit {
        height: 33px;
        padding: 0 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        background-color: #ffffff;
        position: relative;
        top: -3px;
        color: #000000;
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
    }
`;
